@import "https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700,400italic";
@keyframes spinAround {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.breadcrumb, .button, .delete, .file, .is-unselectable, .modal-close, .pagination-ellipsis, .pagination-link, .pagination-next, .pagination-previous, .tabs {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.navbar-link:not(.is-arrowless):after, .select:not(.is-multiple):not(.is-loading):after {
  content: " ";
  height: .625em;
  pointer-events: none;
  transform-origin: center;
  width: .625em;
  border-top: 0;
  border-bottom: 3px solid #0000;
  border-left: 3px solid #0000;
  border-right: 0;
  border-radius: 2px;
  margin-top: -.4375em;
  display: block;
  position: absolute;
  top: 50%;
  transform: rotate(-45deg);
}

.block:not(:last-child), .box:not(:last-child), .breadcrumb:not(:last-child), .content:not(:last-child), .highlight:not(:last-child), .level:not(:last-child), .list:not(:last-child), .message:not(:last-child), .notification:not(:last-child), .progress:not(:last-child), .subtitle:not(:last-child), .table-container:not(:last-child), .table:not(:last-child), .tabs:not(:last-child), .title:not(:last-child) {
  margin-bottom: 1.5rem;
}

.delete, .modal-close {
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  pointer-events: auto;
  height: 20px;
  max-height: 20px;
  max-width: 20px;
  min-height: 20px;
  min-width: 20px;
  vertical-align: top;
  width: 20px;
  background-color: #0a0a0a33;
  border: 0;
  border-radius: 290486px;
  outline: 0;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0;
  display: inline-block;
  position: relative;
}

.delete:after, .delete:before, .modal-close:after, .modal-close:before {
  content: "";
  transform-origin: center;
  background-color: #fff;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.delete:before, .modal-close:before {
  height: 2px;
  width: 50%;
}

.delete:after, .modal-close:after {
  height: 50%;
  width: 2px;
}

.delete:focus, .delete:hover, .modal-close:focus, .modal-close:hover {
  background-color: #0a0a0a4d;
}

.delete:active, .modal-close:active {
  background-color: #0a0a0a66;
}

.is-small.delete, .is-small.modal-close {
  height: 16px;
  max-height: 16px;
  max-width: 16px;
  min-height: 16px;
  min-width: 16px;
  width: 16px;
}

.is-medium.delete, .is-medium.modal-close {
  height: 24px;
  max-height: 24px;
  max-width: 24px;
  min-height: 24px;
  min-width: 24px;
  width: 24px;
}

.is-large.delete, .is-large.modal-close {
  height: 32px;
  max-height: 32px;
  max-width: 32px;
  min-height: 32px;
  min-width: 32px;
  width: 32px;
}

.button.is-loading:after, .control.is-loading:after, .loader, .select.is-loading:after {
  content: "";
  height: 1em;
  width: 1em;
  border: 2px solid #0000;
  border-color: #0000 #0000 #dbdbdb #dbdbdb;
  border-radius: 290486px;
  animation: spinAround .5s linear infinite;
  display: block;
  position: relative;
}

.hero-video, .image.is-16by9 .has-ratio, .image.is-16by9 img, .image.is-1by1 .has-ratio, .image.is-1by1 img, .image.is-1by2 .has-ratio, .image.is-1by2 img, .image.is-1by3 .has-ratio, .image.is-1by3 img, .image.is-2by1 .has-ratio, .image.is-2by1 img, .image.is-2by3 .has-ratio, .image.is-2by3 img, .image.is-3by1 .has-ratio, .image.is-3by1 img, .image.is-3by2 .has-ratio, .image.is-3by2 img, .image.is-3by4 .has-ratio, .image.is-3by4 img, .image.is-3by5 .has-ratio, .image.is-3by5 img, .image.is-4by3 .has-ratio, .image.is-4by3 img, .image.is-4by5 .has-ratio, .image.is-4by5 img, .image.is-5by3 .has-ratio, .image.is-5by3 img, .image.is-5by4 .has-ratio, .image.is-5by4 img, .image.is-9by16 .has-ratio, .image.is-9by16 img, .image.is-square .has-ratio, .image.is-square img, .is-overlay, .modal, .modal-background {
  position: absolute;
  inset: 0;
}

.button, .file-cta, .file-name, .input, .pagination-ellipsis, .pagination-link, .pagination-next, .pagination-previous, .select select, .textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none;
  height: 2.25em;
  vertical-align: top;
  border: 1px solid #0000;
  border-radius: 4px;
  justify-content: flex-start;
  align-items: center;
  padding: calc(.375em - 1px) calc(.625em - 1px);
  font-size: 1rem;
  line-height: 1.5;
  display: inline-flex;
  position: relative;
}

.button:active, .button:focus, .file-cta:active, .file-cta:focus, .file-name:active, .file-name:focus, .input:active, .input:focus, .is-active.button, .is-active.file-cta, .is-active.file-name, .is-active.input, .is-active.pagination-ellipsis, .is-active.pagination-link, .is-active.pagination-next, .is-active.pagination-previous, .is-active.textarea, .is-focused.button, .is-focused.file-cta, .is-focused.file-name, .is-focused.input, .is-focused.pagination-ellipsis, .is-focused.pagination-link, .is-focused.pagination-next, .is-focused.pagination-previous, .is-focused.textarea, .pagination-ellipsis:active, .pagination-ellipsis:focus, .pagination-link:active, .pagination-link:focus, .pagination-next:active, .pagination-next:focus, .pagination-previous:active, .pagination-previous:focus, .select select.is-active, .select select.is-focused, .select select:active, .select select:focus, .textarea:active, .textarea:focus {
  outline: 0;
}

.button[disabled], .file-cta[disabled], .file-name[disabled], .input[disabled], .pagination-ellipsis[disabled], .pagination-link[disabled], .pagination-next[disabled], .pagination-previous[disabled], .select fieldset[disabled] select, .select select[disabled], .textarea[disabled], fieldset[disabled] .button, fieldset[disabled] .file-cta, fieldset[disabled] .file-name, fieldset[disabled] .input, fieldset[disabled] .pagination-ellipsis, fieldset[disabled] .pagination-link, fieldset[disabled] .pagination-next, fieldset[disabled] .pagination-previous, fieldset[disabled] .select select, fieldset[disabled] .textarea {
  cursor: not-allowed;
}

@keyframes moveIndeterminate {
  0% {
    background-position: 200% 0;
  }

  to {
    background-position: -200% 0;
  }
}

blockquote, body, dd, dl, dt, fieldset, figure, h1, h2, h3, h4, h5, h6, hr, html, iframe, legend, li, ol, p, pre, ul {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: 400;
}

ul {
  list-style: none;
}

button, input, select, textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  text-rendering: optimizelegibility;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background-color: #fff;
  font-size: 16px;
  overflow-x: hidden;
  overflow-y: scroll;
}

*, :after, :before {
  box-sizing: inherit;
}

embed, iframe, img, object, video {
  height: auto;
  max-width: 100%;
}

audio {
  max-width: 100%;
}

fieldset, iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, textarea, th {
  padding: 0;
}

.content table th:not([align]), table td:not([align]), table th:not([align]), td:not([align]), th:not([align]) {
  text-align: left;
}

article, aside, figure, footer, header, hgroup, section {
  display: block;
}

body, button, input, select, textarea {
  font-family: Source Sans Pro, Helvetica Neue, Helvetica, Arial, sans-serif;
}

code, pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: monospace;
}

body, code {
  font-weight: 400;
}

body {
  color: #4a4a4a;
  font-size: 1em;
  line-height: 1.5;
}

a {
  color: #5bb7db;
  cursor: pointer;
  text-decoration: none;
}

a strong, pre code {
  color: currentColor;
}

code {
  color: #ff4136;
  padding: .25em .5em;
}

code, hr, pre {
  background-color: #f5f5f5;
}

hr {
  height: 2px;
  border: 0;
  margin: 1.5rem 0;
  display: block;
}

input[type="checkbox"], input[type="radio"] {
  vertical-align: baseline;
}

code, pre, small {
  font-size: .875em;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

strong {
  font-weight: 700;
}

pre {
  -webkit-overflow-scrolling: touch;
  color: #4a4a4a;
  white-space: pre;
  word-wrap: normal;
  padding: 1.25rem 1.5rem;
  overflow-x: auto;
}

pre code {
  background-color: #0000;
  padding: 0;
  font-size: 1em;
}

table td, table th {
  vertical-align: top;
}

a:hover, strong, table th {
  color: #363636;
}

.is-clearfix:after {
  clear: both;
  content: " ";
  display: table;
}

.is-pulled-left {
  float: left !important;
}

.is-pulled-right {
  float: right !important;
}

.is-clipped {
  overflow: hidden !important;
}

.is-size-1 {
  font-size: 3rem !important;
}

.is-size-2 {
  font-size: 2.5rem !important;
}

.is-size-3 {
  font-size: 2rem !important;
}

.is-size-4 {
  font-size: 1.5rem !important;
}

.is-size-5 {
  font-size: 1.25rem !important;
}

.is-size-6 {
  font-size: 1rem !important;
}

.is-size-7 {
  font-size: .75rem !important;
}

@media screen and (max-width: 768px) {
  .is-size-1-mobile {
    font-size: 3rem !important;
  }

  .is-size-2-mobile {
    font-size: 2.5rem !important;
  }

  .is-size-3-mobile {
    font-size: 2rem !important;
  }

  .is-size-4-mobile {
    font-size: 1.5rem !important;
  }

  .is-size-5-mobile {
    font-size: 1.25rem !important;
  }

  .is-size-6-mobile {
    font-size: 1rem !important;
  }

  .is-size-7-mobile {
    font-size: .75rem !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-size-1-tablet {
    font-size: 3rem !important;
  }

  .is-size-2-tablet {
    font-size: 2.5rem !important;
  }

  .is-size-3-tablet {
    font-size: 2rem !important;
  }

  .is-size-4-tablet {
    font-size: 1.5rem !important;
  }

  .is-size-5-tablet {
    font-size: 1.25rem !important;
  }

  .is-size-6-tablet {
    font-size: 1rem !important;
  }

  .is-size-7-tablet {
    font-size: .75rem !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-size-1-touch {
    font-size: 3rem !important;
  }

  .is-size-2-touch {
    font-size: 2.5rem !important;
  }

  .is-size-3-touch {
    font-size: 2rem !important;
  }

  .is-size-4-touch {
    font-size: 1.5rem !important;
  }

  .is-size-5-touch {
    font-size: 1.25rem !important;
  }

  .is-size-6-touch {
    font-size: 1rem !important;
  }

  .is-size-7-touch {
    font-size: .75rem !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-size-1-desktop {
    font-size: 3rem !important;
  }

  .is-size-2-desktop {
    font-size: 2.5rem !important;
  }

  .is-size-3-desktop {
    font-size: 2rem !important;
  }

  .is-size-4-desktop {
    font-size: 1.5rem !important;
  }

  .is-size-5-desktop {
    font-size: 1.25rem !important;
  }

  .is-size-6-desktop {
    font-size: 1rem !important;
  }

  .is-size-7-desktop {
    font-size: .75rem !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-size-1-widescreen {
    font-size: 3rem !important;
  }

  .is-size-2-widescreen {
    font-size: 2.5rem !important;
  }

  .is-size-3-widescreen {
    font-size: 2rem !important;
  }

  .is-size-4-widescreen {
    font-size: 1.5rem !important;
  }

  .is-size-5-widescreen {
    font-size: 1.25rem !important;
  }

  .is-size-6-widescreen {
    font-size: 1rem !important;
  }

  .is-size-7-widescreen {
    font-size: .75rem !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-size-1-fullhd {
    font-size: 3rem !important;
  }

  .is-size-2-fullhd {
    font-size: 2.5rem !important;
  }

  .is-size-3-fullhd {
    font-size: 2rem !important;
  }

  .is-size-4-fullhd {
    font-size: 1.5rem !important;
  }

  .is-size-5-fullhd {
    font-size: 1.25rem !important;
  }

  .is-size-6-fullhd {
    font-size: 1rem !important;
  }

  .is-size-7-fullhd {
    font-size: .75rem !important;
  }
}

.has-text-centered {
  text-align: center !important;
}

.has-text-justified {
  text-align: justify !important;
}

.has-text-left {
  text-align: left !important;
}

.has-text-right {
  text-align: right !important;
}

@media screen and (max-width: 768px) {
  .has-text-centered-mobile {
    text-align: center !important;
  }
}

@media screen and (min-width: 769px), print {
  .has-text-centered-tablet {
    text-align: center !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-centered-tablet-only {
    text-align: center !important;
  }
}

@media screen and (max-width: 1023px) {
  .has-text-centered-touch {
    text-align: center !important;
  }
}

@media screen and (min-width: 1024px) {
  .has-text-centered-desktop {
    text-align: center !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-centered-desktop-only {
    text-align: center !important;
  }
}

@media screen and (min-width: 1216px) {
  .has-text-centered-widescreen {
    text-align: center !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-centered-widescreen-only {
    text-align: center !important;
  }
}

@media screen and (min-width: 1408px) {
  .has-text-centered-fullhd {
    text-align: center !important;
  }
}

@media screen and (max-width: 768px) {
  .has-text-justified-mobile {
    text-align: justify !important;
  }
}

@media screen and (min-width: 769px), print {
  .has-text-justified-tablet {
    text-align: justify !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-justified-tablet-only {
    text-align: justify !important;
  }
}

@media screen and (max-width: 1023px) {
  .has-text-justified-touch {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1024px) {
  .has-text-justified-desktop {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-justified-desktop-only {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1216px) {
  .has-text-justified-widescreen {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-justified-widescreen-only {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1408px) {
  .has-text-justified-fullhd {
    text-align: justify !important;
  }
}

@media screen and (max-width: 768px) {
  .has-text-left-mobile {
    text-align: left !important;
  }
}

@media screen and (min-width: 769px), print {
  .has-text-left-tablet {
    text-align: left !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-left-tablet-only {
    text-align: left !important;
  }
}

@media screen and (max-width: 1023px) {
  .has-text-left-touch {
    text-align: left !important;
  }
}

@media screen and (min-width: 1024px) {
  .has-text-left-desktop {
    text-align: left !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-left-desktop-only {
    text-align: left !important;
  }
}

@media screen and (min-width: 1216px) {
  .has-text-left-widescreen {
    text-align: left !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-left-widescreen-only {
    text-align: left !important;
  }
}

@media screen and (min-width: 1408px) {
  .has-text-left-fullhd {
    text-align: left !important;
  }
}

@media screen and (max-width: 768px) {
  .has-text-right-mobile {
    text-align: right !important;
  }
}

@media screen and (min-width: 769px), print {
  .has-text-right-tablet {
    text-align: right !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-right-tablet-only {
    text-align: right !important;
  }
}

@media screen and (max-width: 1023px) {
  .has-text-right-touch {
    text-align: right !important;
  }
}

@media screen and (min-width: 1024px) {
  .has-text-right-desktop {
    text-align: right !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-right-desktop-only {
    text-align: right !important;
  }
}

@media screen and (min-width: 1216px) {
  .has-text-right-widescreen {
    text-align: right !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-right-widescreen-only {
    text-align: right !important;
  }
}

@media screen and (min-width: 1408px) {
  .has-text-right-fullhd {
    text-align: right !important;
  }
}

.is-capitalized {
  text-transform: capitalize !important;
}

.is-lowercase {
  text-transform: lowercase !important;
}

.is-uppercase {
  text-transform: uppercase !important;
}

.is-italic {
  font-style: italic !important;
}

.has-text-white {
  color: #fff !important;
}

a.has-text-white:focus, a.has-text-white:hover {
  color: #e6e6e6 !important;
}

.has-background-white {
  background-color: #fff !important;
}

.has-text-black {
  color: #0a0a0a !important;
}

a.has-text-black:focus, a.has-text-black:hover {
  color: #000 !important;
}

.has-background-black {
  background-color: #0a0a0a !important;
}

.has-text-light {
  color: #f5f5f5 !important;
}

a.has-text-light:focus, a.has-text-light:hover {
  color: #dbdbdb !important;
}

.has-background-light {
  background-color: #f5f5f5 !important;
}

.has-text-dark {
  color: #363636 !important;
}

a.has-text-dark:focus, a.has-text-dark:hover {
  color: #1c1c1c !important;
}

.has-background-dark {
  background-color: #363636 !important;
}

.has-text-primary {
  color: #158cba !important;
}

a.has-text-primary:focus, a.has-text-primary:hover {
  color: #106a8c !important;
}

.has-background-primary {
  background-color: #158cba !important;
}

.has-text-link {
  color: #5bb7db !important;
}

a.has-text-link:focus, a.has-text-link:hover {
  color: #31a5d2 !important;
}

.has-background-link {
  background-color: #5bb7db !important;
}

.has-text-info {
  color: #209cee !important;
}

a.has-text-info:focus, a.has-text-info:hover {
  color: #0f81cc !important;
}

.has-background-info {
  background-color: #209cee !important;
}

.has-text-success {
  color: #28b62c !important;
}

a.has-text-success:focus, a.has-text-success:hover {
  color: #1f8c22 !important;
}

.has-background-success {
  background-color: #28b62c !important;
}

.has-text-warning {
  color: #ffdd57 !important;
}

a.has-text-warning:focus, a.has-text-warning:hover {
  color: #ffd324 !important;
}

.has-background-warning {
  background-color: #ffdd57 !important;
}

.has-text-danger {
  color: #ff4136 !important;
}

a.has-text-danger:focus, a.has-text-danger:hover {
  color: #ff1103 !important;
}

.has-background-danger {
  background-color: #ff4136 !important;
}

.has-text-black-bis {
  color: #121212 !important;
}

.has-background-black-bis {
  background-color: #121212 !important;
}

.has-text-black-ter {
  color: #242424 !important;
}

.has-background-black-ter {
  background-color: #242424 !important;
}

.has-text-grey-darker {
  color: #363636 !important;
}

.has-background-grey-darker {
  background-color: #363636 !important;
}

.has-text-grey-dark {
  color: #4a4a4a !important;
}

.has-background-grey-dark {
  background-color: #4a4a4a !important;
}

.has-text-grey {
  color: #7a7a7a !important;
}

.has-background-grey {
  background-color: #7a7a7a !important;
}

.has-text-grey-light {
  color: #b5b5b5 !important;
}

.has-background-grey-light {
  background-color: #b5b5b5 !important;
}

.has-text-grey-lighter {
  color: #dbdbdb !important;
}

.has-background-grey-lighter {
  background-color: #dbdbdb !important;
}

.has-text-white-ter {
  color: #f5f5f5 !important;
}

.has-background-white-ter {
  background-color: #f5f5f5 !important;
}

.has-text-white-bis {
  color: #fafafa !important;
}

.has-background-white-bis {
  background-color: #fafafa !important;
}

.has-text-weight-light {
  font-weight: 300 !important;
}

.has-text-weight-normal {
  font-weight: 400 !important;
}

.has-text-weight-medium {
  font-weight: 500 !important;
}

.has-text-weight-semibold {
  font-weight: 600 !important;
}

.has-text-weight-bold {
  font-weight: 700 !important;
}

.is-family-primary, .is-family-sans-serif, .is-family-secondary {
  font-family: Source Sans Pro, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}

.is-family-code, .is-family-monospace {
  font-family: monospace !important;
}

.is-block {
  display: block !important;
}

@media screen and (max-width: 768px) {
  .is-block-mobile {
    display: block !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-block-tablet {
    display: block !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-block-tablet-only {
    display: block !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-block-touch {
    display: block !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-block-desktop {
    display: block !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-block-desktop-only {
    display: block !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-block-widescreen {
    display: block !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-block-widescreen-only {
    display: block !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-block-fullhd {
    display: block !important;
  }
}

.is-flex {
  display: flex !important;
}

@media screen and (max-width: 768px) {
  .is-flex-mobile {
    display: flex !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-flex-tablet {
    display: flex !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-flex-tablet-only {
    display: flex !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-flex-touch {
    display: flex !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-flex-desktop {
    display: flex !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-flex-desktop-only {
    display: flex !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-flex-widescreen {
    display: flex !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-flex-widescreen-only {
    display: flex !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-flex-fullhd {
    display: flex !important;
  }
}

.is-inline {
  display: inline !important;
}

@media screen and (max-width: 768px) {
  .is-inline-mobile {
    display: inline !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-inline-tablet {
    display: inline !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-inline-tablet-only {
    display: inline !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-inline-touch {
    display: inline !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-inline-desktop {
    display: inline !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-inline-desktop-only {
    display: inline !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-inline-widescreen {
    display: inline !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-inline-widescreen-only {
    display: inline !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-inline-fullhd {
    display: inline !important;
  }
}

.is-inline-block {
  display: inline-block !important;
}

@media screen and (max-width: 768px) {
  .is-inline-block-mobile {
    display: inline-block !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-inline-block-tablet {
    display: inline-block !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-inline-block-tablet-only {
    display: inline-block !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-inline-block-touch {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-inline-block-desktop {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-inline-block-desktop-only {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-inline-block-widescreen {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-inline-block-widescreen-only {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-inline-block-fullhd {
    display: inline-block !important;
  }
}

.is-inline-flex {
  display: inline-flex !important;
}

@media screen and (max-width: 768px) {
  .is-inline-flex-mobile {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-inline-flex-tablet {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-inline-flex-tablet-only {
    display: inline-flex !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-inline-flex-touch {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-inline-flex-desktop {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-inline-flex-desktop-only {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-inline-flex-widescreen {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-inline-flex-widescreen-only {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-inline-flex-fullhd {
    display: inline-flex !important;
  }
}

.is-hidden {
  display: none !important;
}

.is-sr-only {
  clip: rect(0, 0, 0, 0) !important;
  height: .01em !important;
  white-space: nowrap !important;
  width: .01em !important;
  border: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

@media screen and (max-width: 768px) {
  .is-hidden-mobile {
    display: none !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-hidden-tablet {
    display: none !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-hidden-tablet-only {
    display: none !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-hidden-touch {
    display: none !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-hidden-desktop {
    display: none !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-hidden-desktop-only {
    display: none !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-hidden-widescreen {
    display: none !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-hidden-widescreen-only {
    display: none !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-hidden-fullhd {
    display: none !important;
  }
}

.is-invisible {
  visibility: hidden !important;
}

@media screen and (max-width: 768px) {
  .is-invisible-mobile {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-invisible-tablet {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-invisible-tablet-only {
    visibility: hidden !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-invisible-touch {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-invisible-desktop {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-invisible-desktop-only {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-invisible-widescreen {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-invisible-widescreen-only {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-invisible-fullhd {
    visibility: hidden !important;
  }
}

.is-marginless {
  margin: 0 !important;
}

.is-paddingless {
  padding: 0 !important;
}

.is-radiusless {
  border-radius: 0 !important;
}

.is-shadowless {
  box-shadow: none !important;
}

.is-relative {
  position: relative !important;
}

.box {
  box-shadow: none;
  color: #4a4a4a;
  background-color: #fff;
  border-radius: 6px;
  padding: 1.25rem;
  display: block;
}

a.box:focus, a.box:hover {
  box-shadow: 0 2px 3px #0a0a0a1a, 0 0 0 1px #5bb7db;
}

a.box:active {
  box-shadow: inset 0 1px 2px #0a0a0a33, 0 0 0 1px #5bb7db;
}

.button {
  color: #363636;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border-color: #dbdbdb;
  justify-content: center;
  padding: calc(.375em - 1px) .75em;
}

.button strong {
  color: inherit;
}

.button .icon, .button .icon.is-large, .button .icon.is-medium, .button .icon.is-small {
  height: 1.5em;
  width: 1.5em;
}

.button .icon:first-child:not(:last-child) {
  margin-left: calc(-.375em - 1px);
  margin-right: .1875em;
}

.button .icon:last-child:not(:first-child) {
  margin-left: .1875em;
  margin-right: calc(-.375em - 1px);
}

.button .icon:first-child:last-child {
  margin-left: calc(-.375em - 1px);
  margin-right: calc(-.375em - 1px);
}

.button.is-hovered, .button:hover {
  color: #363636;
  border-color: #b5b5b5;
}

.button.is-focused, .button:focus {
  color: #363636;
  border-color: #5bb7db;
}

.button.is-focused:not(:active), .button:focus:not(:active) {
  box-shadow: 0 0 0 .125em #5bb7db40;
}

.button.is-active, .button:active {
  color: #363636;
  border-color: #4a4a4a;
}

.button.is-text {
  color: #4a4a4a;
  background-color: #0000;
  border-color: #0000;
  text-decoration: underline;
}

.button.is-text.is-focused, .button.is-text.is-hovered, .button.is-text:focus, .button.is-text:hover {
  color: #363636;
  background-color: #f5f5f5;
}

.button.is-text.is-active, .button.is-text:active {
  color: #363636;
  background-color: #e8e8e8;
}

.button.is-text[disabled], fieldset[disabled] .button.is-text {
  box-shadow: none;
  background-color: #0000;
  border-color: #0000;
}

.button.is-white {
  color: #0a0a0a;
  background-color: #fff;
}

.button.is-white.is-hovered, .button.is-white:hover {
  color: #0a0a0a;
  background-color: #f9f9f9;
}

.button.is-white.is-focused, .button.is-white:focus {
  color: #0a0a0a;
}

.button.is-white.is-focused:not(:active), .button.is-white:focus:not(:active) {
  box-shadow: 0 0 0 .125em #ffffff40;
}

.button.is-white.is-active, .button.is-white:active {
  color: #0a0a0a;
  background-color: #f2f2f2;
  border-color: #0000;
}

.button.is-white[disabled], fieldset[disabled] .button.is-white {
  box-shadow: none;
  background-color: #fff;
  border-color: #0000;
}

.button.is-white.is-inverted {
  color: #fff;
  background-color: #0a0a0a;
}

.button.is-white.is-inverted.is-hovered, .button.is-white.is-inverted:hover {
  background-color: #000;
}

.button.is-white.is-inverted[disabled], fieldset[disabled] .button.is-white.is-inverted {
  box-shadow: none;
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0000;
}

.button.is-white.is-loading:after {
  border-color: #0000 #0000 #0a0a0a #0a0a0a !important;
}

.button.is-white.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-white.is-outlined.is-focused, .button.is-white.is-outlined.is-hovered, .button.is-white.is-outlined:focus, .button.is-white.is-outlined:hover {
  color: #0a0a0a;
  background-color: #fff;
  border-color: #fff;
}

.button.is-white.is-outlined.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-white.is-outlined.is-loading.is-focused:after, .button.is-white.is-outlined.is-loading.is-hovered:after, .button.is-white.is-outlined.is-loading:focus:after, .button.is-white.is-outlined.is-loading:hover:after {
  border-color: #0000 #0000 #0a0a0a #0a0a0a !important;
}

.button.is-white.is-outlined[disabled], fieldset[disabled] .button.is-white.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-white.is-inverted.is-outlined {
  color: #0a0a0a;
  background-color: #0000;
  border-color: #0a0a0a;
}

.button.is-white.is-inverted.is-outlined.is-focused, .button.is-white.is-inverted.is-outlined.is-hovered, .button.is-white.is-inverted.is-outlined:focus, .button.is-white.is-inverted.is-outlined:hover {
  color: #fff;
  background-color: #0a0a0a;
}

.button.is-white.is-inverted.is-outlined.is-loading.is-focused:after, .button.is-white.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-white.is-inverted.is-outlined.is-loading:focus:after, .button.is-white.is-inverted.is-outlined.is-loading:hover:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-white.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-white.is-inverted.is-outlined {
  box-shadow: none;
  color: #0a0a0a;
  background-color: #0000;
  border-color: #0a0a0a;
}

.button.is-black {
  color: #fff;
  background-color: #0a0a0a;
}

.button.is-black.is-hovered, .button.is-black:hover {
  color: #fff;
  background-color: #040404;
}

.button.is-black.is-focused, .button.is-black:focus {
  color: #fff;
}

.button.is-black.is-focused:not(:active), .button.is-black:focus:not(:active) {
  box-shadow: 0 0 0 .125em #0a0a0a40;
}

.button.is-black.is-active, .button.is-black:active {
  color: #fff;
  background-color: #000;
  border-color: #0000;
}

.button.is-black[disabled], fieldset[disabled] .button.is-black {
  box-shadow: none;
  background-color: #0a0a0a;
  border-color: #0000;
}

.button.is-black.is-inverted {
  color: #0a0a0a;
  background-color: #fff;
}

.button.is-black.is-inverted.is-hovered, .button.is-black.is-inverted:hover {
  background-color: #f2f2f2;
}

.button.is-black.is-inverted[disabled], fieldset[disabled] .button.is-black.is-inverted {
  box-shadow: none;
  color: #0a0a0a;
  background-color: #fff;
  border-color: #0000;
}

.button.is-black.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-black.is-outlined {
  color: #0a0a0a;
  background-color: #0000;
  border-color: #0a0a0a;
}

.button.is-black.is-outlined.is-focused, .button.is-black.is-outlined.is-hovered, .button.is-black.is-outlined:focus, .button.is-black.is-outlined:hover {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0a0a0a;
}

.button.is-black.is-outlined.is-loading:after {
  border-color: #0000 #0000 #0a0a0a #0a0a0a !important;
}

.button.is-black.is-outlined.is-loading.is-focused:after, .button.is-black.is-outlined.is-loading.is-hovered:after, .button.is-black.is-outlined.is-loading:focus:after, .button.is-black.is-outlined.is-loading:hover:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-black.is-outlined[disabled], fieldset[disabled] .button.is-black.is-outlined {
  box-shadow: none;
  color: #0a0a0a;
  background-color: #0000;
  border-color: #0a0a0a;
}

.button.is-black.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-black.is-inverted.is-outlined.is-focused, .button.is-black.is-inverted.is-outlined.is-hovered, .button.is-black.is-inverted.is-outlined:focus, .button.is-black.is-inverted.is-outlined:hover {
  color: #0a0a0a;
  background-color: #fff;
}

.button.is-black.is-inverted.is-outlined.is-loading.is-focused:after, .button.is-black.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-black.is-inverted.is-outlined.is-loading:focus:after, .button.is-black.is-inverted.is-outlined.is-loading:hover:after {
  border-color: #0000 #0000 #0a0a0a #0a0a0a !important;
}

.button.is-black.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-black.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-light {
  color: #363636;
  background-color: #f5f5f5;
}

.button.is-light.is-hovered, .button.is-light:hover {
  color: #363636;
  background-color: #eee;
}

.button.is-light.is-focused, .button.is-light:focus {
  color: #363636;
}

.button.is-light.is-focused:not(:active), .button.is-light:focus:not(:active) {
  box-shadow: 0 0 0 .125em #f5f5f540;
}

.button.is-light.is-active, .button.is-light:active {
  color: #363636;
  background-color: #e8e8e8;
  border-color: #0000;
}

.button.is-light[disabled], fieldset[disabled] .button.is-light {
  box-shadow: none;
  background-color: #f5f5f5;
  border-color: #0000;
}

.button.is-light.is-inverted {
  color: #f5f5f5;
  background-color: #363636;
}

.button.is-light.is-inverted.is-hovered, .button.is-light.is-inverted:hover {
  background-color: #292929;
}

.button.is-light.is-inverted[disabled], fieldset[disabled] .button.is-light.is-inverted {
  box-shadow: none;
  color: #f5f5f5;
  background-color: #363636;
  border-color: #0000;
}

.button.is-light.is-loading:after {
  border-color: #0000 #0000 #363636 #363636 !important;
}

.button.is-light.is-outlined {
  color: #f5f5f5;
  background-color: #0000;
  border-color: #f5f5f5;
}

.button.is-light.is-outlined.is-focused, .button.is-light.is-outlined.is-hovered, .button.is-light.is-outlined:focus, .button.is-light.is-outlined:hover {
  color: #363636;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.button.is-light.is-outlined.is-loading:after {
  border-color: #0000 #0000 #f5f5f5 #f5f5f5 !important;
}

.button.is-light.is-outlined.is-loading.is-focused:after, .button.is-light.is-outlined.is-loading.is-hovered:after, .button.is-light.is-outlined.is-loading:focus:after, .button.is-light.is-outlined.is-loading:hover:after {
  border-color: #0000 #0000 #363636 #363636 !important;
}

.button.is-light.is-outlined[disabled], fieldset[disabled] .button.is-light.is-outlined {
  box-shadow: none;
  color: #f5f5f5;
  background-color: #0000;
  border-color: #f5f5f5;
}

.button.is-light.is-inverted.is-outlined {
  color: #363636;
  background-color: #0000;
  border-color: #363636;
}

.button.is-light.is-inverted.is-outlined.is-focused, .button.is-light.is-inverted.is-outlined.is-hovered, .button.is-light.is-inverted.is-outlined:focus, .button.is-light.is-inverted.is-outlined:hover {
  color: #f5f5f5;
  background-color: #363636;
}

.button.is-light.is-inverted.is-outlined.is-loading.is-focused:after, .button.is-light.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-light.is-inverted.is-outlined.is-loading:focus:after, .button.is-light.is-inverted.is-outlined.is-loading:hover:after {
  border-color: #0000 #0000 #f5f5f5 #f5f5f5 !important;
}

.button.is-light.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-light.is-inverted.is-outlined {
  box-shadow: none;
  color: #363636;
  background-color: #0000;
  border-color: #363636;
}

.button.is-dark {
  color: #f5f5f5;
  background-color: #363636;
}

.button.is-dark.is-hovered, .button.is-dark:hover {
  color: #f5f5f5;
  background-color: #2f2f2f;
}

.button.is-dark.is-focused, .button.is-dark:focus {
  color: #f5f5f5;
}

.button.is-dark.is-focused:not(:active), .button.is-dark:focus:not(:active) {
  box-shadow: 0 0 0 .125em #36363640;
}

.button.is-dark.is-active, .button.is-dark:active {
  color: #f5f5f5;
  background-color: #292929;
  border-color: #0000;
}

.button.is-dark[disabled], fieldset[disabled] .button.is-dark {
  box-shadow: none;
  background-color: #363636;
  border-color: #0000;
}

.button.is-dark.is-inverted {
  color: #363636;
  background-color: #f5f5f5;
}

.button.is-dark.is-inverted.is-hovered, .button.is-dark.is-inverted:hover {
  background-color: #e8e8e8;
}

.button.is-dark.is-inverted[disabled], fieldset[disabled] .button.is-dark.is-inverted {
  box-shadow: none;
  color: #363636;
  background-color: #f5f5f5;
  border-color: #0000;
}

.button.is-dark.is-loading:after {
  border-color: #0000 #0000 #f5f5f5 #f5f5f5 !important;
}

.button.is-dark.is-outlined {
  color: #363636;
  background-color: #0000;
  border-color: #363636;
}

.button.is-dark.is-outlined.is-focused, .button.is-dark.is-outlined.is-hovered, .button.is-dark.is-outlined:focus, .button.is-dark.is-outlined:hover {
  color: #f5f5f5;
  background-color: #363636;
  border-color: #363636;
}

.button.is-dark.is-outlined.is-loading:after {
  border-color: #0000 #0000 #363636 #363636 !important;
}

.button.is-dark.is-outlined.is-loading.is-focused:after, .button.is-dark.is-outlined.is-loading.is-hovered:after, .button.is-dark.is-outlined.is-loading:focus:after, .button.is-dark.is-outlined.is-loading:hover:after {
  border-color: #0000 #0000 #f5f5f5 #f5f5f5 !important;
}

.button.is-dark.is-outlined[disabled], fieldset[disabled] .button.is-dark.is-outlined {
  box-shadow: none;
  color: #363636;
  background-color: #0000;
  border-color: #363636;
}

.button.is-dark.is-inverted.is-outlined {
  color: #f5f5f5;
  background-color: #0000;
  border-color: #f5f5f5;
}

.button.is-dark.is-inverted.is-outlined.is-focused, .button.is-dark.is-inverted.is-outlined.is-hovered, .button.is-dark.is-inverted.is-outlined:focus, .button.is-dark.is-inverted.is-outlined:hover {
  color: #363636;
  background-color: #f5f5f5;
}

.button.is-dark.is-inverted.is-outlined.is-loading.is-focused:after, .button.is-dark.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-dark.is-inverted.is-outlined.is-loading:focus:after, .button.is-dark.is-inverted.is-outlined.is-loading:hover:after {
  border-color: #0000 #0000 #363636 #363636 !important;
}

.button.is-dark.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-dark.is-inverted.is-outlined {
  box-shadow: none;
  color: #f5f5f5;
  background-color: #0000;
  border-color: #f5f5f5;
}

.button.is-primary {
  color: #fff;
  background-color: #158cba;
}

.button.is-primary.is-hovered, .button.is-primary:hover {
  color: #fff;
  background-color: #1483af;
}

.button.is-primary.is-focused, .button.is-primary:focus {
  color: #fff;
}

.button.is-primary.is-focused:not(:active), .button.is-primary:focus:not(:active) {
  box-shadow: 0 0 0 .125em #158cba40;
}

.button.is-primary.is-active, .button.is-primary:active {
  color: #fff;
  background-color: #127ba3;
  border-color: #0000;
}

.button.is-primary[disabled], fieldset[disabled] .button.is-primary {
  box-shadow: none;
  background-color: #158cba;
  border-color: #0000;
}

.button.is-primary.is-inverted {
  color: #158cba;
  background-color: #fff;
}

.button.is-primary.is-inverted.is-hovered, .button.is-primary.is-inverted:hover {
  background-color: #f2f2f2;
}

.button.is-primary.is-inverted[disabled], fieldset[disabled] .button.is-primary.is-inverted {
  box-shadow: none;
  color: #158cba;
  background-color: #fff;
  border-color: #0000;
}

.button.is-primary.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-primary.is-outlined {
  color: #158cba;
  background-color: #0000;
  border-color: #158cba;
}

.button.is-primary.is-outlined.is-focused, .button.is-primary.is-outlined.is-hovered, .button.is-primary.is-outlined:focus, .button.is-primary.is-outlined:hover {
  color: #fff;
  background-color: #158cba;
  border-color: #158cba;
}

.button.is-primary.is-outlined.is-loading:after {
  border-color: #0000 #0000 #158cba #158cba !important;
}

.button.is-primary.is-outlined.is-loading.is-focused:after, .button.is-primary.is-outlined.is-loading.is-hovered:after, .button.is-primary.is-outlined.is-loading:focus:after, .button.is-primary.is-outlined.is-loading:hover:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-primary.is-outlined[disabled], fieldset[disabled] .button.is-primary.is-outlined {
  box-shadow: none;
  color: #158cba;
  background-color: #0000;
  border-color: #158cba;
}

.button.is-primary.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-primary.is-inverted.is-outlined.is-focused, .button.is-primary.is-inverted.is-outlined.is-hovered, .button.is-primary.is-inverted.is-outlined:focus, .button.is-primary.is-inverted.is-outlined:hover {
  color: #158cba;
  background-color: #fff;
}

.button.is-primary.is-inverted.is-outlined.is-loading.is-focused:after, .button.is-primary.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-primary.is-inverted.is-outlined.is-loading:focus:after, .button.is-primary.is-inverted.is-outlined.is-loading:hover:after {
  border-color: #0000 #0000 #158cba #158cba !important;
}

.button.is-primary.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-primary.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-link {
  color: #fff;
  background-color: #5bb7db;
}

.button.is-link.is-hovered, .button.is-link:hover {
  color: #fff;
  background-color: #51b2d9;
}

.button.is-link.is-focused, .button.is-link:focus {
  color: #fff;
}

.button.is-link.is-focused:not(:active), .button.is-link:focus:not(:active) {
  box-shadow: 0 0 0 .125em #5bb7db40;
}

.button.is-link.is-active, .button.is-link:active {
  color: #fff;
  background-color: #46aed6;
  border-color: #0000;
}

.button.is-link[disabled], fieldset[disabled] .button.is-link {
  box-shadow: none;
  background-color: #5bb7db;
  border-color: #0000;
}

.button.is-link.is-inverted {
  color: #5bb7db;
  background-color: #fff;
}

.button.is-link.is-inverted.is-hovered, .button.is-link.is-inverted:hover {
  background-color: #f2f2f2;
}

.button.is-link.is-inverted[disabled], fieldset[disabled] .button.is-link.is-inverted {
  box-shadow: none;
  color: #5bb7db;
  background-color: #fff;
  border-color: #0000;
}

.button.is-link.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-link.is-outlined {
  color: #5bb7db;
  background-color: #0000;
  border-color: #5bb7db;
}

.button.is-link.is-outlined.is-focused, .button.is-link.is-outlined.is-hovered, .button.is-link.is-outlined:focus, .button.is-link.is-outlined:hover {
  color: #fff;
  background-color: #5bb7db;
  border-color: #5bb7db;
}

.button.is-link.is-outlined.is-loading:after {
  border-color: #0000 #0000 #5bb7db #5bb7db !important;
}

.button.is-link.is-outlined.is-loading.is-focused:after, .button.is-link.is-outlined.is-loading.is-hovered:after, .button.is-link.is-outlined.is-loading:focus:after, .button.is-link.is-outlined.is-loading:hover:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-link.is-outlined[disabled], fieldset[disabled] .button.is-link.is-outlined {
  box-shadow: none;
  color: #5bb7db;
  background-color: #0000;
  border-color: #5bb7db;
}

.button.is-link.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-link.is-inverted.is-outlined.is-focused, .button.is-link.is-inverted.is-outlined.is-hovered, .button.is-link.is-inverted.is-outlined:focus, .button.is-link.is-inverted.is-outlined:hover {
  color: #5bb7db;
  background-color: #fff;
}

.button.is-link.is-inverted.is-outlined.is-loading.is-focused:after, .button.is-link.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-link.is-inverted.is-outlined.is-loading:focus:after, .button.is-link.is-inverted.is-outlined.is-loading:hover:after {
  border-color: #0000 #0000 #5bb7db #5bb7db !important;
}

.button.is-link.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-link.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-info {
  color: #fff;
  background-color: #209cee;
}

.button.is-info.is-hovered, .button.is-info:hover {
  color: #fff;
  background-color: #1496ed;
}

.button.is-info.is-focused, .button.is-info:focus {
  color: #fff;
}

.button.is-info.is-focused:not(:active), .button.is-info:focus:not(:active) {
  box-shadow: 0 0 0 .125em #209cee40;
}

.button.is-info.is-active, .button.is-info:active {
  color: #fff;
  background-color: #118fe4;
  border-color: #0000;
}

.button.is-info[disabled], fieldset[disabled] .button.is-info {
  box-shadow: none;
  background-color: #209cee;
  border-color: #0000;
}

.button.is-info.is-inverted {
  color: #209cee;
  background-color: #fff;
}

.button.is-info.is-inverted.is-hovered, .button.is-info.is-inverted:hover {
  background-color: #f2f2f2;
}

.button.is-info.is-inverted[disabled], fieldset[disabled] .button.is-info.is-inverted {
  box-shadow: none;
  color: #209cee;
  background-color: #fff;
  border-color: #0000;
}

.button.is-info.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-info.is-outlined {
  color: #209cee;
  background-color: #0000;
  border-color: #209cee;
}

.button.is-info.is-outlined.is-focused, .button.is-info.is-outlined.is-hovered, .button.is-info.is-outlined:focus, .button.is-info.is-outlined:hover {
  color: #fff;
  background-color: #209cee;
  border-color: #209cee;
}

.button.is-info.is-outlined.is-loading:after {
  border-color: #0000 #0000 #209cee #209cee !important;
}

.button.is-info.is-outlined.is-loading.is-focused:after, .button.is-info.is-outlined.is-loading.is-hovered:after, .button.is-info.is-outlined.is-loading:focus:after, .button.is-info.is-outlined.is-loading:hover:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-info.is-outlined[disabled], fieldset[disabled] .button.is-info.is-outlined {
  box-shadow: none;
  color: #209cee;
  background-color: #0000;
  border-color: #209cee;
}

.button.is-info.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-info.is-inverted.is-outlined.is-focused, .button.is-info.is-inverted.is-outlined.is-hovered, .button.is-info.is-inverted.is-outlined:focus, .button.is-info.is-inverted.is-outlined:hover {
  color: #209cee;
  background-color: #fff;
}

.button.is-info.is-inverted.is-outlined.is-loading.is-focused:after, .button.is-info.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-info.is-inverted.is-outlined.is-loading:focus:after, .button.is-info.is-inverted.is-outlined.is-loading:hover:after {
  border-color: #0000 #0000 #209cee #209cee !important;
}

.button.is-info.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-info.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-success {
  color: #fff;
  background-color: #28b62c;
}

.button.is-success.is-hovered, .button.is-success:hover {
  color: #fff;
  background-color: #26ac29;
}

.button.is-success.is-focused, .button.is-success:focus {
  color: #fff;
}

.button.is-success.is-focused:not(:active), .button.is-success:focus:not(:active) {
  box-shadow: 0 0 0 .125em #28b62c40;
}

.button.is-success.is-active, .button.is-success:active {
  color: #fff;
  background-color: #23a127;
  border-color: #0000;
}

.button.is-success[disabled], fieldset[disabled] .button.is-success {
  box-shadow: none;
  background-color: #28b62c;
  border-color: #0000;
}

.button.is-success.is-inverted {
  color: #28b62c;
  background-color: #fff;
}

.button.is-success.is-inverted.is-hovered, .button.is-success.is-inverted:hover {
  background-color: #f2f2f2;
}

.button.is-success.is-inverted[disabled], fieldset[disabled] .button.is-success.is-inverted {
  box-shadow: none;
  color: #28b62c;
  background-color: #fff;
  border-color: #0000;
}

.button.is-success.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-success.is-outlined {
  color: #28b62c;
  background-color: #0000;
  border-color: #28b62c;
}

.button.is-success.is-outlined.is-focused, .button.is-success.is-outlined.is-hovered, .button.is-success.is-outlined:focus, .button.is-success.is-outlined:hover {
  color: #fff;
  background-color: #28b62c;
  border-color: #28b62c;
}

.button.is-success.is-outlined.is-loading:after {
  border-color: #0000 #0000 #28b62c #28b62c !important;
}

.button.is-success.is-outlined.is-loading.is-focused:after, .button.is-success.is-outlined.is-loading.is-hovered:after, .button.is-success.is-outlined.is-loading:focus:after, .button.is-success.is-outlined.is-loading:hover:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-success.is-outlined[disabled], fieldset[disabled] .button.is-success.is-outlined {
  box-shadow: none;
  color: #28b62c;
  background-color: #0000;
  border-color: #28b62c;
}

.button.is-success.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-success.is-inverted.is-outlined.is-focused, .button.is-success.is-inverted.is-outlined.is-hovered, .button.is-success.is-inverted.is-outlined:focus, .button.is-success.is-inverted.is-outlined:hover {
  color: #28b62c;
  background-color: #fff;
}

.button.is-success.is-inverted.is-outlined.is-loading.is-focused:after, .button.is-success.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-success.is-inverted.is-outlined.is-loading:focus:after, .button.is-success.is-inverted.is-outlined.is-loading:hover:after {
  border-color: #0000 #0000 #28b62c #28b62c !important;
}

.button.is-success.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-success.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-warning {
  color: #000000b3;
  background-color: #ffdd57;
}

.button.is-warning.is-hovered, .button.is-warning:hover {
  color: #000000b3;
  background-color: #ffdb4a;
}

.button.is-warning.is-focused, .button.is-warning:focus {
  color: #000000b3;
}

.button.is-warning.is-focused:not(:active), .button.is-warning:focus:not(:active) {
  box-shadow: 0 0 0 .125em #ffdd5740;
}

.button.is-warning.is-active, .button.is-warning:active {
  color: #000000b3;
  background-color: #ffd83d;
  border-color: #0000;
}

.button.is-warning[disabled], fieldset[disabled] .button.is-warning {
  box-shadow: none;
  background-color: #ffdd57;
  border-color: #0000;
}

.button.is-warning.is-inverted {
  color: #ffdd57;
}

.button.is-warning.is-inverted, .button.is-warning.is-inverted.is-hovered, .button.is-warning.is-inverted:hover {
  background-color: #000000b3;
}

.button.is-warning.is-inverted[disabled], fieldset[disabled] .button.is-warning.is-inverted {
  box-shadow: none;
  color: #ffdd57;
  background-color: #000000b3;
  border-color: #0000;
}

.button.is-warning.is-loading:after {
  border-color: #0000 #0000 #000000b3 #000000b3 !important;
}

.button.is-warning.is-outlined {
  color: #ffdd57;
  background-color: #0000;
  border-color: #ffdd57;
}

.button.is-warning.is-outlined.is-focused, .button.is-warning.is-outlined.is-hovered, .button.is-warning.is-outlined:focus, .button.is-warning.is-outlined:hover {
  color: #000000b3;
  background-color: #ffdd57;
  border-color: #ffdd57;
}

.button.is-warning.is-outlined.is-loading:after {
  border-color: #0000 #0000 #ffdd57 #ffdd57 !important;
}

.button.is-warning.is-outlined.is-loading.is-focused:after, .button.is-warning.is-outlined.is-loading.is-hovered:after, .button.is-warning.is-outlined.is-loading:focus:after, .button.is-warning.is-outlined.is-loading:hover:after {
  border-color: #0000 #0000 #000000b3 #000000b3 !important;
}

.button.is-warning.is-outlined[disabled], fieldset[disabled] .button.is-warning.is-outlined {
  box-shadow: none;
  color: #ffdd57;
  background-color: #0000;
  border-color: #ffdd57;
}

.button.is-warning.is-inverted.is-outlined {
  color: #000000b3;
  background-color: #0000;
  border-color: #000000b3;
}

.button.is-warning.is-inverted.is-outlined.is-focused, .button.is-warning.is-inverted.is-outlined.is-hovered, .button.is-warning.is-inverted.is-outlined:focus, .button.is-warning.is-inverted.is-outlined:hover {
  color: #ffdd57;
  background-color: #000000b3;
}

.button.is-warning.is-inverted.is-outlined.is-loading.is-focused:after, .button.is-warning.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-warning.is-inverted.is-outlined.is-loading:focus:after, .button.is-warning.is-inverted.is-outlined.is-loading:hover:after {
  border-color: #0000 #0000 #ffdd57 #ffdd57 !important;
}

.button.is-warning.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-warning.is-inverted.is-outlined {
  box-shadow: none;
  color: #000000b3;
  background-color: #0000;
  border-color: #000000b3;
}

.button.is-danger {
  color: #fff;
  background-color: #ff4136;
}

.button.is-danger.is-hovered, .button.is-danger:hover {
  color: #fff;
  background-color: #ff3529;
}

.button.is-danger.is-focused, .button.is-danger:focus {
  color: #fff;
}

.button.is-danger.is-focused:not(:active), .button.is-danger:focus:not(:active) {
  box-shadow: 0 0 0 .125em #ff413640;
}

.button.is-danger.is-active, .button.is-danger:active {
  color: #fff;
  background-color: #ff291d;
  border-color: #0000;
}

.button.is-danger[disabled], fieldset[disabled] .button.is-danger {
  box-shadow: none;
  background-color: #ff4136;
  border-color: #0000;
}

.button.is-danger.is-inverted {
  color: #ff4136;
  background-color: #fff;
}

.button.is-danger.is-inverted.is-hovered, .button.is-danger.is-inverted:hover {
  background-color: #f2f2f2;
}

.button.is-danger.is-inverted[disabled], fieldset[disabled] .button.is-danger.is-inverted {
  box-shadow: none;
  color: #ff4136;
  background-color: #fff;
  border-color: #0000;
}

.button.is-danger.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-danger.is-outlined {
  color: #ff4136;
  background-color: #0000;
  border-color: #ff4136;
}

.button.is-danger.is-outlined.is-focused, .button.is-danger.is-outlined.is-hovered, .button.is-danger.is-outlined:focus, .button.is-danger.is-outlined:hover {
  color: #fff;
  background-color: #ff4136;
  border-color: #ff4136;
}

.button.is-danger.is-outlined.is-loading:after {
  border-color: #0000 #0000 #ff4136 #ff4136 !important;
}

.button.is-danger.is-outlined.is-loading.is-focused:after, .button.is-danger.is-outlined.is-loading.is-hovered:after, .button.is-danger.is-outlined.is-loading:focus:after, .button.is-danger.is-outlined.is-loading:hover:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-danger.is-outlined[disabled], fieldset[disabled] .button.is-danger.is-outlined {
  box-shadow: none;
  color: #ff4136;
  background-color: #0000;
  border-color: #ff4136;
}

.button.is-danger.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-danger.is-inverted.is-outlined.is-focused, .button.is-danger.is-inverted.is-outlined.is-hovered, .button.is-danger.is-inverted.is-outlined:focus, .button.is-danger.is-inverted.is-outlined:hover {
  color: #ff4136;
  background-color: #fff;
}

.button.is-danger.is-inverted.is-outlined.is-loading.is-focused:after, .button.is-danger.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-danger.is-inverted.is-outlined.is-loading:focus:after, .button.is-danger.is-inverted.is-outlined.is-loading:hover:after {
  border-color: #0000 #0000 #ff4136 #ff4136 !important;
}

.button.is-danger.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-danger.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-small {
  border-radius: 2px;
  font-size: .75rem;
}

.button.is-normal {
  font-size: 1rem;
}

.button.is-medium {
  font-size: 1.25rem;
}

.button.is-large {
  font-size: 1.5rem;
}

.button[disabled], fieldset[disabled] .button {
  box-shadow: none;
  opacity: .5;
  background-color: #fff;
  border-color: #dbdbdb;
}

.button.is-fullwidth {
  width: 100%;
  display: flex;
}

.button.is-loading {
  pointer-events: none;
  color: #0000 !important;
}

.button.is-loading:after {
  top: calc(50% - .5em);
  left: calc(50% - .5em);
  position: absolute !important;
}

.button.is-static {
  color: #7a7a7a;
  box-shadow: none;
  pointer-events: none;
  background-color: #f5f5f5;
  border-color: #dbdbdb;
}

.button.is-rounded {
  border-radius: 290486px;
  padding-left: 1em;
  padding-right: 1em;
}

.buttons {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.buttons .button {
  margin-bottom: .5rem;
}

.buttons .button:not(:last-child):not(.is-fullwidth) {
  margin-right: .5rem;
}

.buttons:last-child {
  margin-bottom: -.5rem;
}

.buttons:not(:last-child) {
  margin-bottom: 1rem;
}

.buttons.are-small .button:not(.is-normal):not(.is-medium):not(.is-large) {
  border-radius: 2px;
  font-size: .75rem;
}

.buttons.are-medium .button:not(.is-small):not(.is-normal):not(.is-large) {
  font-size: 1.25rem;
}

.buttons.are-large .button:not(.is-small):not(.is-normal):not(.is-medium) {
  font-size: 1.5rem;
}

.buttons.has-addons .button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.buttons.has-addons .button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
}

.buttons.has-addons .button:last-child {
  margin-right: 0;
}

.buttons.has-addons .button.is-hovered, .buttons.has-addons .button:hover {
  z-index: 2;
}

.buttons.has-addons .button.is-active, .buttons.has-addons .button.is-focused, .buttons.has-addons .button.is-selected, .buttons.has-addons .button:active, .buttons.has-addons .button:focus {
  z-index: 3;
}

.buttons.has-addons .button.is-active:hover, .buttons.has-addons .button.is-focused:hover, .buttons.has-addons .button.is-selected:hover, .buttons.has-addons .button:active:hover, .buttons.has-addons .button:focus:hover {
  z-index: 4;
}

.buttons.has-addons .button.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.buttons.is-centered {
  justify-content: center;
}

.buttons.is-centered:not(.has-addons) .button:not(.is-fullwidth), .buttons.is-right:not(.has-addons) .button:not(.is-fullwidth) {
  margin-left: .25rem;
  margin-right: .25rem;
}

.buttons.is-right {
  justify-content: flex-end;
}

.container {
  width: auto;
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
}

@media screen and (min-width: 1024px) {
  .container {
    max-width: 960px;
  }

  .container.is-fluid {
    max-width: none;
    margin-left: 32px;
    margin-right: 32px;
  }
}

@media screen and (max-width: 1215px) {
  .container.is-widescreen {
    max-width: 1152px;
  }
}

@media screen and (max-width: 1407px) {
  .container.is-fullhd {
    max-width: 1344px;
  }
}

@media screen and (min-width: 1216px) {
  .container {
    max-width: 1152px;
  }
}

@media screen and (min-width: 1408px) {
  .container {
    max-width: 1344px;
  }
}

.content li + li {
  margin-top: .25em;
}

.content blockquote:not(:last-child), .content dl:not(:last-child), .content ol:not(:last-child), .content p:not(:last-child), .content pre:not(:last-child), .content table:not(:last-child), .content ul:not(:last-child) {
  margin-bottom: 1em;
}

.content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
  color: #363636;
  font-weight: 600;
  line-height: 1.125;
}

.content h1 {
  margin-bottom: .5em;
  font-size: 2em;
}

.content h1:not(:first-child) {
  margin-top: 1em;
}

.content h2 {
  margin-bottom: .5714em;
  font-size: 1.75em;
}

.content h2:not(:first-child) {
  margin-top: 1.1428em;
}

.content h3 {
  margin-bottom: .6666em;
  font-size: 1.5em;
}

.content h3:not(:first-child) {
  margin-top: 1.3333em;
}

.content h4 {
  margin-bottom: .8em;
  font-size: 1.25em;
}

.content h5 {
  margin-bottom: .8888em;
  font-size: 1.125em;
}

.content h6 {
  margin-bottom: 1em;
  font-size: 1em;
}

.content blockquote {
  background-color: #f5f5f5;
  border-left: 5px solid #dbdbdb;
  padding: 1.25em 1.5em;
}

.content ol {
  margin-top: 1em;
  margin-left: 2em;
  list-style-position: outside;
}

.content ol:not([type]) {
  list-style-type: decimal;
}

.content ol:not([type]).is-lower-alpha {
  list-style-type: lower-alpha;
}

.content ol:not([type]).is-lower-roman {
  list-style-type: lower-roman;
}

.content ol:not([type]).is-upper-alpha {
  list-style-type: upper-alpha;
}

.content ol:not([type]).is-upper-roman {
  list-style-type: upper-roman;
}

.content ul {
  margin-top: 1em;
  list-style: disc;
}

.content ul ul {
  margin-top: .5em;
  list-style-type: circle;
}

.content ul ul ul {
  list-style-type: square;
}

.content dd, .content ul {
  margin-left: 2em;
}

.content figure {
  text-align: center;
  margin-left: 2em;
  margin-right: 2em;
}

.content figure:not(:first-child) {
  margin-top: 2em;
}

.content figure:not(:last-child) {
  margin-bottom: 2em;
}

.content figure img {
  display: inline-block;
}

.content figure figcaption {
  font-style: italic;
}

.content pre {
  -webkit-overflow-scrolling: touch;
  white-space: pre;
  word-wrap: normal;
  padding: 1.25em 1.5em;
  overflow-x: auto;
}

.content sub, .content sup {
  font-size: 75%;
}

.content table {
  width: 100%;
}

.content table td, .content table th, .table td, .table th {
  vertical-align: top;
  border: 0 solid #dbdbdb;
  border-bottom-width: 1px;
  padding: .5em .75em;
}

.content table th, .table th {
  color: #363636;
}

.content table thead td, .content table thead th, .table thead td, .table thead th {
  color: #363636;
  border-width: 0 0 2px;
}

.content table tfoot td, .content table tfoot th, .table tfoot td, .table tfoot th {
  color: #363636;
  border-width: 2px 0 0;
}

.content table tbody tr:last-child td, .content table tbody tr:last-child th, .table tbody tr:last-child td, .table tbody tr:last-child th {
  border-bottom-width: 0;
}

.content .tabs li + li {
  margin-top: 0;
}

.content.is-small {
  font-size: .75rem;
}

.content.is-medium {
  font-size: 1.25rem;
}

.content.is-large {
  font-size: 1.5rem;
}

.icon {
  height: 1.5rem;
  width: 1.5rem;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.icon.is-small {
  height: 1rem;
  width: 1rem;
}

.icon.is-medium {
  height: 2rem;
  width: 2rem;
}

.icon.is-large {
  height: 3rem;
  width: 3rem;
}

.image {
  display: block;
  position: relative;
}

.image img {
  height: auto;
  width: 100%;
  display: block;
}

.image img.is-rounded {
  border-radius: 290486px;
}

.image.is-16by9 .has-ratio, .image.is-16by9 img, .image.is-1by1 .has-ratio, .image.is-1by1 img, .image.is-1by2 .has-ratio, .image.is-1by2 img, .image.is-1by3 .has-ratio, .image.is-1by3 img, .image.is-2by1 .has-ratio, .image.is-2by1 img, .image.is-2by3 .has-ratio, .image.is-2by3 img, .image.is-3by1 .has-ratio, .image.is-3by1 img, .image.is-3by2 .has-ratio, .image.is-3by2 img, .image.is-3by4 .has-ratio, .image.is-3by4 img, .image.is-3by5 .has-ratio, .image.is-3by5 img, .image.is-4by3 .has-ratio, .image.is-4by3 img, .image.is-4by5 .has-ratio, .image.is-4by5 img, .image.is-5by3 .has-ratio, .image.is-5by3 img, .image.is-5by4 .has-ratio, .image.is-5by4 img, .image.is-9by16 .has-ratio, .image.is-9by16 img, .image.is-square .has-ratio, .image.is-square img {
  height: 100%;
  width: 100%;
}

.image.is-1by1, .image.is-square {
  padding-top: 100%;
}

.image.is-5by4 {
  padding-top: 80%;
}

.image.is-4by3 {
  padding-top: 75%;
}

.image.is-3by2 {
  padding-top: 66.6666%;
}

.image.is-5by3 {
  padding-top: 60%;
}

.image.is-16by9 {
  padding-top: 56.25%;
}

.image.is-2by1 {
  padding-top: 50%;
}

.image.is-3by1 {
  padding-top: 33.3333%;
}

.image.is-4by5 {
  padding-top: 125%;
}

.image.is-3by4 {
  padding-top: 133.333%;
}

.image.is-2by3 {
  padding-top: 150%;
}

.image.is-3by5 {
  padding-top: 166.667%;
}

.image.is-9by16 {
  padding-top: 177.778%;
}

.image.is-1by2 {
  padding-top: 200%;
}

.image.is-1by3 {
  padding-top: 300%;
}

.image.is-16x16 {
  height: 16px;
  width: 16px;
}

.image.is-24x24 {
  height: 24px;
  width: 24px;
}

.image.is-32x32 {
  height: 32px;
  width: 32px;
}

.image.is-48x48 {
  height: 48px;
  width: 48px;
}

.image.is-64x64 {
  height: 64px;
  width: 64px;
}

.image.is-96x96 {
  height: 96px;
  width: 96px;
}

.image.is-128x128 {
  height: 128px;
  width: 128px;
}

.notification {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
  position: relative;
}

.message a:not(.button):not(.tag):not(.dropdown-item), .notification a:not(.button):not(.dropdown-item) {
  color: currentColor;
  text-decoration: underline;
}

.notification code, .notification pre {
  background: #fff;
}

.notification pre code {
  background: none;
}

.notification > .delete {
  position: absolute;
  top: .5rem;
  right: .5rem;
}

.notification .content, .notification .subtitle, .notification .title, .notification strong, .table td.is-selected a, .table td.is-selected strong, .table th.is-selected a, .table th.is-selected strong, .table tr.is-selected a, .table tr.is-selected strong {
  color: currentColor;
}

.notification.is-white {
  color: #0a0a0a;
  background-color: #fff;
}

.notification.is-black {
  color: #fff;
  background-color: #0a0a0a;
}

.notification.is-light {
  color: #363636;
  background-color: #f5f5f5;
}

.notification.is-dark {
  color: #f5f5f5;
  background-color: #363636;
}

.notification.is-primary {
  color: #fff;
  background-color: #158cba;
}

.notification.is-link {
  color: #fff;
  background-color: #5bb7db;
}

.notification.is-info {
  color: #fff;
  background-color: #209cee;
}

.notification.is-success {
  color: #fff;
  background-color: #28b62c;
}

.notification.is-warning {
  color: #000000b3;
  background-color: #ffdd57;
}

.notification.is-danger {
  color: #fff;
  background-color: #ff4136;
}

.progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 1rem;
  width: 100%;
  border: 0;
  padding: 0;
  display: block;
  overflow: hidden;
}

.progress::-webkit-progress-bar {
  background-color: #dbdbdb;
}

.progress::-webkit-progress-value {
  background-color: #4a4a4a;
}

.progress::-moz-progress-bar {
  background-color: #4a4a4a;
}

.progress::-ms-fill {
  background-color: #4a4a4a;
  border: 0;
}

.progress.is-white::-webkit-progress-value {
  background-color: #fff;
}

.progress.is-white::-moz-progress-bar {
  background-color: #fff;
}

.progress.is-white::-ms-fill {
  background-color: #fff;
}

.progress.is-white:indeterminate {
  background-image: linear-gradient(to right, #fff 30%, #dbdbdb 30%);
}

.progress.is-black::-webkit-progress-value {
  background-color: #0a0a0a;
}

.progress.is-black::-moz-progress-bar {
  background-color: #0a0a0a;
}

.progress.is-black::-ms-fill {
  background-color: #0a0a0a;
}

.progress.is-black:indeterminate {
  background-image: linear-gradient(to right, #0a0a0a 30%, #dbdbdb 30%);
}

.progress.is-light::-webkit-progress-value {
  background-color: #f5f5f5;
}

.progress.is-light::-moz-progress-bar {
  background-color: #f5f5f5;
}

.progress.is-light::-ms-fill {
  background-color: #f5f5f5;
}

.progress.is-light:indeterminate {
  background-image: linear-gradient(to right, #f5f5f5 30%, #dbdbdb 30%);
}

.progress.is-dark::-webkit-progress-value {
  background-color: #363636;
}

.progress.is-dark::-moz-progress-bar {
  background-color: #363636;
}

.progress.is-dark::-ms-fill {
  background-color: #363636;
}

.progress.is-dark:indeterminate {
  background-image: linear-gradient(to right, #363636 30%, #dbdbdb 30%);
}

.progress.is-primary::-webkit-progress-value {
  background-color: #158cba;
}

.progress.is-primary::-moz-progress-bar {
  background-color: #158cba;
}

.progress.is-primary::-ms-fill {
  background-color: #158cba;
}

.progress.is-primary:indeterminate {
  background-image: linear-gradient(to right, #158cba 30%, #dbdbdb 30%);
}

.progress.is-link::-webkit-progress-value {
  background-color: #5bb7db;
}

.progress.is-link::-moz-progress-bar {
  background-color: #5bb7db;
}

.progress.is-link::-ms-fill {
  background-color: #5bb7db;
}

.progress.is-link:indeterminate {
  background-image: linear-gradient(to right, #5bb7db 30%, #dbdbdb 30%);
}

.progress.is-info::-webkit-progress-value {
  background-color: #209cee;
}

.progress.is-info::-moz-progress-bar {
  background-color: #209cee;
}

.progress.is-info::-ms-fill {
  background-color: #209cee;
}

.progress.is-info:indeterminate {
  background-image: linear-gradient(to right, #209cee 30%, #dbdbdb 30%);
}

.progress.is-success::-webkit-progress-value {
  background-color: #28b62c;
}

.progress.is-success::-moz-progress-bar {
  background-color: #28b62c;
}

.progress.is-success::-ms-fill {
  background-color: #28b62c;
}

.progress.is-success:indeterminate {
  background-image: linear-gradient(to right, #28b62c 30%, #dbdbdb 30%);
}

.progress.is-warning::-webkit-progress-value {
  background-color: #ffdd57;
}

.progress.is-warning::-moz-progress-bar {
  background-color: #ffdd57;
}

.progress.is-warning::-ms-fill {
  background-color: #ffdd57;
}

.progress.is-warning:indeterminate {
  background-image: linear-gradient(to right, #ffdd57 30%, #dbdbdb 30%);
}

.progress.is-danger::-webkit-progress-value {
  background-color: #ff4136;
}

.progress.is-danger::-moz-progress-bar {
  background-color: #ff4136;
}

.progress.is-danger::-ms-fill {
  background-color: #ff4136;
}

.progress.is-danger:indeterminate {
  background-image: linear-gradient(to right, #ff4136 30%, #dbdbdb 30%);
}

.progress:indeterminate {
  background-color: #dbdbdb;
  background-image: linear-gradient(to right, #4a4a4a 30%, #dbdbdb 30%);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 150% 150%;
  animation-name: moveIndeterminate;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.progress:indeterminate::-webkit-progress-bar {
  background-color: #0000;
}

.progress:indeterminate::-moz-progress-bar {
  background-color: #0000;
}

.progress.is-small {
  height: .75rem;
}

.progress.is-medium {
  height: 1.25rem;
}

.progress.is-large {
  height: 1.5rem;
}

.table {
  color: #363636;
  background-color: #fff;
}

.table td.is-white, .table th.is-white {
  color: #0a0a0a;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-white .tabs.is-boxed li.is-active a, .hero.is-white .tabs.is-boxed li.is-active a:hover, .hero.is-white .tabs.is-toggle li.is-active a, .hero.is-white .tabs.is-toggle li.is-active a:hover, .table td.is-black, .table th.is-black {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0a0a0a;
}

.table td.is-light, .table th.is-light {
  color: #363636;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.table td.is-dark, .table th.is-dark {
  color: #f5f5f5;
  background-color: #363636;
  border-color: #363636;
}

.table td.is-primary, .table th.is-primary {
  color: #fff;
  background-color: #158cba;
  border-color: #158cba;
}

.table td.is-link, .table th.is-link {
  color: #fff;
  background-color: #5bb7db;
  border-color: #5bb7db;
}

.table td.is-info, .table th.is-info {
  color: #fff;
  background-color: #209cee;
  border-color: #209cee;
}

.table td.is-success, .table th.is-success {
  color: #fff;
  background-color: #28b62c;
  border-color: #28b62c;
}

.table td.is-warning, .table th.is-warning {
  color: #000000b3;
  background-color: #ffdd57;
  border-color: #ffdd57;
}

.table td.is-danger, .table th.is-danger {
  color: #fff;
  background-color: #ff4136;
  border-color: #ff4136;
}

.table td.is-narrow, .table th.is-narrow {
  white-space: nowrap;
  width: 1%;
}

.table td.is-selected, .table th.is-selected, .table tr.is-selected {
  color: #fff;
  background-color: #158cba;
}

.table th:not([align]) {
  text-align: left;
}

.table tr.is-selected td, .table tr.is-selected th {
  color: currentColor;
  border-color: #fff;
}

.message-body pre code, .table tbody, .table tfoot, .table thead {
  background-color: #0000;
}

.table.is-bordered td, .table.is-bordered th {
  border-width: 1px;
}

.table.is-bordered tr:last-child td, .table.is-bordered tr:last-child th {
  border-bottom-width: 1px;
}

.table.is-fullwidth {
  width: 100%;
}

.table.is-hoverable tbody tr:not(.is-selected):hover, .table.is-hoverable.is-striped tbody tr:not(.is-selected):hover {
  background-color: #fafafa;
}

.table.is-hoverable.is-striped tbody tr:not(.is-selected):hover:nth-child(2n) {
  background-color: #f5f5f5;
}

.table.is-narrow td, .table.is-narrow th {
  padding: .25em .5em;
}

.table.is-striped tbody tr:not(.is-selected):nth-child(2n) {
  background-color: #fafafa;
}

.table-container {
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.tags {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.tags .tag {
  margin-bottom: .5rem;
}

.tags .tag:not(:last-child) {
  margin-right: .5rem;
}

.tags:last-child {
  margin-bottom: -.5rem;
}

.tags:not(:last-child) {
  margin-bottom: 1rem;
}

.tags.are-medium .tag:not(.is-normal):not(.is-large) {
  font-size: 1rem;
}

.tags.are-large .tag:not(.is-normal):not(.is-medium) {
  font-size: 1.25rem;
}

.tags.is-centered {
  justify-content: center;
}

.tags.is-centered .tag {
  margin-left: .25rem;
  margin-right: .25rem;
}

.tags.is-right {
  justify-content: flex-end;
}

.tags.is-right .tag:not(:first-child) {
  margin-left: .5rem;
}

.tags.is-right .tag:not(:last-child), .tags.has-addons .tag {
  margin-right: 0;
}

.tags.has-addons .tag:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
}

.tags.has-addons .tag:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tag:not(body) {
  color: #4a4a4a;
  height: 2em;
  white-space: nowrap;
  background-color: #f5f5f5;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding-left: .75em;
  padding-right: .75em;
  font-size: .75rem;
  line-height: 1.5;
  display: inline-flex;
}

.tag:not(body) .delete {
  margin-left: .25rem;
  margin-right: -.375rem;
}

.tag:not(body).is-white {
  color: #0a0a0a;
  background-color: #fff;
}

.tag:not(body).is-black {
  color: #fff;
  background-color: #0a0a0a;
}

.tag:not(body).is-light {
  color: #363636;
  background-color: #f5f5f5;
}

.tag:not(body).is-dark {
  color: #f5f5f5;
  background-color: #363636;
}

.tag:not(body).is-primary {
  color: #fff;
  background-color: #158cba;
}

.tag:not(body).is-link {
  color: #fff;
  background-color: #5bb7db;
}

.tag:not(body).is-info {
  color: #fff;
  background-color: #209cee;
}

.tag:not(body).is-success {
  color: #fff;
  background-color: #28b62c;
}

.tag:not(body).is-warning {
  color: #000000b3;
  background-color: #ffdd57;
}

.tag:not(body).is-danger {
  color: #fff;
  background-color: #ff4136;
}

.tag:not(body).is-normal {
  font-size: .75rem;
}

.tag:not(body).is-medium {
  font-size: 1rem;
}

.tag:not(body).is-large {
  font-size: 1.25rem;
}

.tag:not(body) .icon:first-child:not(:last-child) {
  margin-left: -.375em;
  margin-right: .1875em;
}

.tag:not(body) .icon:last-child:not(:first-child) {
  margin-left: .1875em;
  margin-right: -.375em;
}

.tag:not(body) .icon:first-child:last-child {
  margin-left: -.375em;
  margin-right: -.375em;
}

.tag:not(body).is-delete {
  width: 2em;
  margin-left: 1px;
  padding: 0;
  position: relative;
}

.tag:not(body).is-delete:after, .tag:not(body).is-delete:before {
  content: "";
  transform-origin: center;
  background-color: currentColor;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.tag:not(body).is-delete:before {
  height: 1px;
  width: 50%;
}

.tag:not(body).is-delete:after {
  height: 50%;
  width: 1px;
}

.tag:not(body).is-delete:focus, .tag:not(body).is-delete:hover {
  background-color: #e8e8e8;
}

.tag:not(body).is-delete:active {
  background-color: #dbdbdb;
}

.tag:not(body).is-rounded {
  border-radius: 290486px;
}

a.tag:hover {
  text-decoration: underline;
}

.subtitle, .title {
  word-break: break-word;
}

.subtitle em, .subtitle span, .title em, .title span, .title strong {
  font-weight: inherit;
}

.subtitle sub, .subtitle sup, .title sub, .title sup {
  font-size: .75em;
}

.subtitle .tag, .title .tag {
  vertical-align: middle;
}

.title {
  color: #363636;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.125;
}

.title strong {
  color: inherit;
}

.title + .highlight {
  margin-top: -.75rem;
}

.subtitle:not(.is-spaced) + .title, .title:not(.is-spaced) + .subtitle {
  margin-top: -1.25rem;
}

.title.is-1 {
  font-size: 3rem;
}

.title.is-2 {
  font-size: 2.5rem;
}

.title.is-3 {
  font-size: 2rem;
}

.title.is-4 {
  font-size: 1.5rem;
}

.subtitle, .title.is-5 {
  font-size: 1.25rem;
}

.title.is-6 {
  font-size: 1rem;
}

.title.is-7 {
  font-size: .75rem;
}

.subtitle {
  color: #4a4a4a;
  font-weight: 400;
  line-height: 1.25;
}

.subtitle strong {
  color: #363636;
  font-weight: 600;
}

.subtitle.is-1 {
  font-size: 3rem;
}

.subtitle.is-2 {
  font-size: 2.5rem;
}

.subtitle.is-3 {
  font-size: 2rem;
}

.subtitle.is-4 {
  font-size: 1.5rem;
}

.subtitle.is-5 {
  font-size: 1.25rem;
}

.subtitle.is-6 {
  font-size: 1rem;
}

.subtitle.is-7 {
  font-size: .75rem;
}

.heading {
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 11px;
  display: block;
}

.highlight {
  max-width: 100%;
  padding: 0;
  font-weight: 400;
  overflow: hidden;
}

.highlight pre {
  max-width: 100%;
  overflow: auto;
}

.number {
  height: 2em;
  min-width: 2.5em;
  text-align: center;
  vertical-align: top;
  background-color: #f5f5f5;
  border-radius: 290486px;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;
  padding: .25rem .5rem;
  font-size: 1.25rem;
  display: inline-flex;
}

.input, .select select, .textarea {
  color: #363636;
  background-color: #fff;
  border-color: #dbdbdb;
  border-radius: 4px;
}

.input:-moz-placeholder, .input::-moz-placeholder, .select select:-moz-placeholder, .select select::-moz-placeholder, .textarea:-moz-placeholder, .textarea::-moz-placeholder {
  color: #3636364d;
}

.input::-webkit-input-placeholder, .select select::-webkit-input-placeholder, .textarea::-webkit-input-placeholder {
  color: #3636364d;
}

.input:-ms-input-placeholder, .select select:-ms-input-placeholder, .textarea:-ms-input-placeholder {
  color: #3636364d;
}

.input:hover, .is-hovered.input, .is-hovered.textarea, .select select.is-hovered, .select select:hover, .textarea:hover {
  border-color: #b5b5b5;
}

.input:focus, .textarea:focus {
  border-color: #5bb7db;
}

.is-focused.input, .is-focused.textarea, .select select.is-focused, .select select:focus {
  border-color: #5bb7db;
  box-shadow: 0 0 0 .125em #5bb7db40;
}

.input:active, .textarea:active {
  border-color: #5bb7db;
}

.is-active.input, .is-active.textarea, .select select.is-active, .select select:active {
  border-color: #5bb7db;
  box-shadow: 0 0 0 .125em #5bb7db40;
}

.input[disabled], .select fieldset[disabled] select, .select select[disabled], .textarea[disabled], fieldset[disabled] .input, fieldset[disabled] .select select, fieldset[disabled] .textarea {
  box-shadow: none;
  color: #7a7a7a;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.input[disabled]:-moz-placeholder, .input[disabled]::-moz-placeholder, .select fieldset[disabled] select:-moz-placeholder, .select fieldset[disabled] select::-moz-placeholder, .select select[disabled]:-moz-placeholder, .select select[disabled]::-moz-placeholder, .textarea[disabled]:-moz-placeholder, .textarea[disabled]::-moz-placeholder, fieldset[disabled] .input:-moz-placeholder, fieldset[disabled] .input::-moz-placeholder, fieldset[disabled] .select select:-moz-placeholder, fieldset[disabled] .select select::-moz-placeholder, fieldset[disabled] .textarea:-moz-placeholder, fieldset[disabled] .textarea::-moz-placeholder {
  color: #7a7a7a4d;
}

.input[disabled]::-webkit-input-placeholder, .select fieldset[disabled] select::-webkit-input-placeholder, .select select[disabled]::-webkit-input-placeholder, .textarea[disabled]::-webkit-input-placeholder, fieldset[disabled] .input::-webkit-input-placeholder, fieldset[disabled] .select select::-webkit-input-placeholder, fieldset[disabled] .textarea::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

.input[disabled]:-ms-input-placeholder, .select fieldset[disabled] select:-ms-input-placeholder, .select select[disabled]:-ms-input-placeholder, .textarea[disabled]:-ms-input-placeholder, fieldset[disabled] .input:-ms-input-placeholder, fieldset[disabled] .select select:-ms-input-placeholder, fieldset[disabled] .textarea:-ms-input-placeholder {
  color: #7a7a7a4d;
}

.input {
  max-width: 100%;
}

.input, .textarea {
  width: 100%;
}

.input[readonly], .textarea[readonly] {
  box-shadow: none;
}

.is-white.input, .is-white.textarea {
  border-color: #fff;
}

.is-white.input:active, .is-white.input:focus, .is-white.is-active.input, .is-white.is-active.textarea, .is-white.is-focused.input, .is-white.is-focused.textarea, .is-white.textarea:active, .is-white.textarea:focus {
  box-shadow: 0 0 0 .125em #ffffff40;
}

.is-black.input, .is-black.textarea {
  border-color: #0a0a0a;
}

.is-black.input:active, .is-black.input:focus, .is-black.is-active.input, .is-black.is-active.textarea, .is-black.is-focused.input, .is-black.is-focused.textarea, .is-black.textarea:active, .is-black.textarea:focus {
  box-shadow: 0 0 0 .125em #0a0a0a40;
}

.is-light.input, .is-light.textarea, .select select[disabled]:hover, fieldset[disabled] .select select:hover {
  border-color: #f5f5f5;
}

.is-light.input:active, .is-light.input:focus, .is-light.is-active.input, .is-light.is-active.textarea, .is-light.is-focused.input, .is-light.is-focused.textarea, .is-light.textarea:active, .is-light.textarea:focus {
  box-shadow: 0 0 0 .125em #f5f5f540;
}

.is-dark.input, .is-dark.textarea {
  border-color: #363636;
}

.is-dark.input:active, .is-dark.input:focus, .is-dark.is-active.input, .is-dark.is-active.textarea, .is-dark.is-focused.input, .is-dark.is-focused.textarea, .is-dark.textarea:active, .is-dark.textarea:focus {
  box-shadow: 0 0 0 .125em #36363640;
}

.is-primary.input, .is-primary.textarea {
  border-color: #158cba;
}

.is-primary.input:active, .is-primary.input:focus, .is-primary.is-active.input, .is-primary.is-active.textarea, .is-primary.is-focused.input, .is-primary.is-focused.textarea, .is-primary.textarea:active, .is-primary.textarea:focus {
  box-shadow: 0 0 0 .125em #158cba40;
}

.is-link.input, .is-link.textarea {
  border-color: #5bb7db;
}

.is-link.input:active, .is-link.input:focus, .is-link.is-active.input, .is-link.is-active.textarea, .is-link.is-focused.input, .is-link.is-focused.textarea, .is-link.textarea:active, .is-link.textarea:focus {
  box-shadow: 0 0 0 .125em #5bb7db40;
}

.is-info.input, .is-info.textarea {
  border-color: #209cee;
}

.is-info.input:active, .is-info.input:focus, .is-info.is-active.input, .is-info.is-active.textarea, .is-info.is-focused.input, .is-info.is-focused.textarea, .is-info.textarea:active, .is-info.textarea:focus {
  box-shadow: 0 0 0 .125em #209cee40;
}

.is-success.input, .is-success.textarea {
  border-color: #28b62c;
}

.is-success.input:active, .is-success.input:focus, .is-success.is-active.input, .is-success.is-active.textarea, .is-success.is-focused.input, .is-success.is-focused.textarea, .is-success.textarea:active, .is-success.textarea:focus {
  box-shadow: 0 0 0 .125em #28b62c40;
}

.is-warning.input, .is-warning.textarea {
  border-color: #ffdd57;
}

.is-warning.input:active, .is-warning.input:focus, .is-warning.is-active.input, .is-warning.is-active.textarea, .is-warning.is-focused.input, .is-warning.is-focused.textarea, .is-warning.textarea:active, .is-warning.textarea:focus {
  box-shadow: 0 0 0 .125em #ffdd5740;
}

.is-danger.input, .is-danger.textarea {
  border-color: #ff4136;
}

.is-danger.input:active, .is-danger.input:focus, .is-danger.is-active.input, .is-danger.is-active.textarea, .is-danger.is-focused.input, .is-danger.is-focused.textarea, .is-danger.textarea:active, .is-danger.textarea:focus {
  box-shadow: 0 0 0 .125em #ff413640;
}

.is-small.input, .is-small.textarea {
  border-radius: 2px;
  font-size: .75rem;
}

.is-medium.input, .is-medium.textarea {
  font-size: 1.25rem;
}

.is-large.input, .is-large.textarea {
  font-size: 1.5rem;
}

.is-fullwidth.input, .is-fullwidth.textarea {
  width: 100%;
  display: block;
}

.is-inline.input, .is-inline.textarea {
  width: auto;
  display: inline;
}

.input.is-rounded {
  border-radius: 290486px;
  padding-left: 1em;
  padding-right: 1em;
}

.input.is-static {
  box-shadow: none;
  background-color: #0000;
  border-color: #0000;
  padding-left: 0;
  padding-right: 0;
}

.textarea {
  max-width: 100%;
  min-width: 100%;
  resize: vertical;
  padding: .625em;
  display: block;
}

.textarea:not([rows]) {
  max-height: 600px;
  min-height: 120px;
}

.textarea[rows] {
  height: initial;
}

.textarea.has-fixed-size {
  resize: none;
}

.checkbox, .radio, .select {
  display: inline-block;
  position: relative;
}

.checkbox, .radio {
  cursor: pointer;
  line-height: 1.25;
}

.checkbox input, .radio input {
  cursor: pointer;
}

.checkbox:hover, .radio:hover {
  color: #363636;
}

.checkbox[disabled], .radio[disabled], fieldset[disabled] .checkbox, fieldset[disabled] .radio {
  color: #7a7a7a;
  cursor: not-allowed;
}

.radio + .radio {
  margin-left: .5em;
}

.select {
  max-width: 100%;
  vertical-align: top;
}

.select:not(.is-multiple) {
  height: 2.25em;
}

.select:not(.is-multiple):not(.is-loading):after {
  z-index: 4;
  border-color: #5bb7db;
  right: 1.125em;
}

.select.is-rounded select {
  border-radius: 290486px;
  padding-left: 1em;
}

.select select {
  cursor: pointer;
  max-width: 100%;
  border-width: 1px 1px 4px;
  outline: 0;
  font-size: 1em;
  display: block;
}

.select select::-ms-expand {
  display: none;
}

.select select:not([multiple]) {
  padding-right: 2.5em;
}

.select select[multiple] {
  height: auto;
  padding: 0;
}

.select select[multiple] option {
  padding: .5em 1em;
}

.select:not(.is-multiple):not(.is-loading):hover:after {
  border-color: #363636;
}

.select.is-white:not(:hover):after, .select.is-white select {
  border-color: #fff;
}

.select.is-white select.is-hovered, .select.is-white select:hover {
  border-color: #f2f2f2;
}

.select.is-white select.is-active, .select.is-white select.is-focused, .select.is-white select:active, .select.is-white select:focus {
  box-shadow: 0 0 0 .125em #ffffff40;
}

.select.is-black:not(:hover):after, .navbar.is-white .navbar-brand .navbar-link:after, .select.is-black select {
  border-color: #0a0a0a;
}

.select.is-black select.is-hovered, .select.is-black select:hover {
  border-color: #000;
}

.select.is-black select.is-active, .select.is-black select.is-focused, .select.is-black select:active, .select.is-black select:focus {
  box-shadow: 0 0 0 .125em #0a0a0a40;
}

.select.is-light:not(:hover):after, .select.is-light select {
  border-color: #f5f5f5;
}

.select.is-light select.is-hovered, .select.is-light select:hover {
  border-color: #e8e8e8;
}

.select.is-light select.is-active, .select.is-light select.is-focused, .select.is-light select:active, .select.is-light select:focus {
  box-shadow: 0 0 0 .125em #f5f5f540;
}

.select.is-dark:not(:hover):after, .select.is-dark select {
  border-color: #363636;
}

.select.is-dark select.is-hovered, .select.is-dark select:hover {
  border-color: #292929;
}

.select.is-dark select.is-active, .select.is-dark select.is-focused, .select.is-dark select:active, .select.is-dark select:focus {
  box-shadow: 0 0 0 .125em #36363640;
}

.select.is-primary:not(:hover):after, .select.is-primary select {
  border-color: #158cba;
}

.select.is-primary select.is-hovered, .select.is-primary select:hover {
  border-color: #127ba3;
}

.select.is-primary select.is-active, .select.is-primary select.is-focused, .select.is-primary select:active, .select.is-primary select:focus {
  box-shadow: 0 0 0 .125em #158cba40;
}

.select.is-link:not(:hover):after, .select.is-link select {
  border-color: #5bb7db;
}

.select.is-link select.is-hovered, .select.is-link select:hover {
  border-color: #46aed6;
}

.select.is-link select.is-active, .select.is-link select.is-focused, .select.is-link select:active, .select.is-link select:focus {
  box-shadow: 0 0 0 .125em #5bb7db40;
}

.select.is-info:not(:hover):after, .select.is-info select {
  border-color: #209cee;
}

.select.is-info select.is-hovered, .select.is-info select:hover {
  border-color: #118fe4;
}

.select.is-info select.is-active, .select.is-info select.is-focused, .select.is-info select:active, .select.is-info select:focus {
  box-shadow: 0 0 0 .125em #209cee40;
}

.select.is-success:not(:hover):after, .select.is-success select {
  border-color: #28b62c;
}

.select.is-success select.is-hovered, .select.is-success select:hover {
  border-color: #23a127;
}

.select.is-success select.is-active, .select.is-success select.is-focused, .select.is-success select:active, .select.is-success select:focus {
  box-shadow: 0 0 0 .125em #28b62c40;
}

.select.is-warning:not(:hover):after, .select.is-warning select {
  border-color: #ffdd57;
}

.select.is-warning select.is-hovered, .select.is-warning select:hover {
  border-color: #ffd83d;
}

.select.is-warning select.is-active, .select.is-warning select.is-focused, .select.is-warning select:active, .select.is-warning select:focus {
  box-shadow: 0 0 0 .125em #ffdd5740;
}

.select.is-danger:not(:hover):after, .select.is-danger select {
  border-color: #ff4136;
}

.select.is-danger select.is-hovered, .select.is-danger select:hover {
  border-color: #ff291d;
}

.select.is-danger select.is-active, .select.is-danger select.is-focused, .select.is-danger select:active, .select.is-danger select:focus {
  box-shadow: 0 0 0 .125em #ff413640;
}

.select.is-small {
  border-radius: 2px;
  font-size: .75rem;
}

.select.is-medium {
  font-size: 1.25rem;
}

.select.is-large {
  font-size: 1.5rem;
}

.select.is-disabled:after {
  border-color: #7a7a7a;
}

.select.is-fullwidth, .select.is-fullwidth select {
  width: 100%;
}

.select.is-loading:after {
  margin-top: 0;
  position: absolute;
  top: .625em;
  right: .625em;
  transform: none;
}

.file.is-small, .select.is-loading.is-small:after {
  font-size: .75rem;
}

.file.is-medium, .select.is-loading.is-medium:after {
  font-size: 1.25rem;
}

.file.is-large, .select.is-loading.is-large:after {
  font-size: 1.5rem;
}

.file {
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: relative;
}

.file.is-white .file-cta {
  color: #0a0a0a;
  background-color: #fff;
}

.file.is-white.is-hovered .file-cta, .file.is-white:hover .file-cta {
  color: #0a0a0a;
  background-color: #f9f9f9;
}

.file.is-white.is-focused .file-cta, .file.is-white:focus .file-cta {
  color: #0a0a0a;
  border-color: #0000;
  box-shadow: 0 0 .5em #ffffff40;
}

.file.is-white.is-active .file-cta, .file.is-white:active .file-cta {
  color: #0a0a0a;
  background-color: #f2f2f2;
  border-color: #0000;
}

.file.is-black .file-cta {
  color: #fff;
  background-color: #0a0a0a;
}

.file.is-black.is-hovered .file-cta, .file.is-black:hover .file-cta {
  color: #fff;
  background-color: #040404;
}

.file.is-black.is-focused .file-cta, .file.is-black:focus .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #0a0a0a40;
}

.file.is-black.is-active .file-cta, .file.is-black:active .file-cta {
  color: #fff;
  background-color: #000;
  border-color: #0000;
}

.file.is-light .file-cta {
  color: #363636;
  background-color: #f5f5f5;
}

.file.is-light.is-hovered .file-cta, .file.is-light:hover .file-cta {
  color: #363636;
  background-color: #eee;
}

.file.is-light.is-focused .file-cta, .file.is-light:focus .file-cta {
  color: #363636;
  border-color: #0000;
  box-shadow: 0 0 .5em #f5f5f540;
}

.file.is-light.is-active .file-cta, .file.is-light:active .file-cta {
  color: #363636;
  background-color: #e8e8e8;
  border-color: #0000;
}

.file.is-dark .file-cta {
  color: #f5f5f5;
  background-color: #363636;
}

.file.is-dark.is-hovered .file-cta, .file.is-dark:hover .file-cta {
  color: #f5f5f5;
  background-color: #2f2f2f;
}

.file.is-dark.is-focused .file-cta, .file.is-dark:focus .file-cta {
  color: #f5f5f5;
  border-color: #0000;
  box-shadow: 0 0 .5em #36363640;
}

.file.is-dark.is-active .file-cta, .file.is-dark:active .file-cta {
  color: #f5f5f5;
  background-color: #292929;
  border-color: #0000;
}

.file.is-primary .file-cta {
  color: #fff;
  background-color: #158cba;
}

.file.is-primary.is-hovered .file-cta, .file.is-primary:hover .file-cta {
  color: #fff;
  background-color: #1483af;
}

.file.is-primary.is-focused .file-cta, .file.is-primary:focus .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #158cba40;
}

.file.is-primary.is-active .file-cta, .file.is-primary:active .file-cta {
  color: #fff;
  background-color: #127ba3;
  border-color: #0000;
}

.file.is-link .file-cta {
  color: #fff;
  background-color: #5bb7db;
}

.file.is-link.is-hovered .file-cta, .file.is-link:hover .file-cta {
  color: #fff;
  background-color: #51b2d9;
}

.file.is-link.is-focused .file-cta, .file.is-link:focus .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #5bb7db40;
}

.file.is-link.is-active .file-cta, .file.is-link:active .file-cta {
  color: #fff;
  background-color: #46aed6;
  border-color: #0000;
}

.file.is-info .file-cta {
  color: #fff;
  background-color: #209cee;
}

.file.is-info.is-hovered .file-cta, .file.is-info:hover .file-cta {
  color: #fff;
  background-color: #1496ed;
}

.file.is-info.is-focused .file-cta, .file.is-info:focus .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #209cee40;
}

.file.is-info.is-active .file-cta, .file.is-info:active .file-cta {
  color: #fff;
  background-color: #118fe4;
  border-color: #0000;
}

.file.is-success .file-cta {
  color: #fff;
  background-color: #28b62c;
}

.file.is-success.is-hovered .file-cta, .file.is-success:hover .file-cta {
  color: #fff;
  background-color: #26ac29;
}

.file.is-success.is-focused .file-cta, .file.is-success:focus .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #28b62c40;
}

.file.is-success.is-active .file-cta, .file.is-success:active .file-cta {
  color: #fff;
  background-color: #23a127;
  border-color: #0000;
}

.file.is-warning .file-cta {
  color: #000000b3;
  background-color: #ffdd57;
}

.file.is-warning.is-hovered .file-cta, .file.is-warning:hover .file-cta {
  color: #000000b3;
  background-color: #ffdb4a;
}

.file.is-warning.is-focused .file-cta, .file.is-warning:focus .file-cta {
  color: #000000b3;
  border-color: #0000;
  box-shadow: 0 0 .5em #ffdd5740;
}

.file.is-warning.is-active .file-cta, .file.is-warning:active .file-cta {
  color: #000000b3;
  background-color: #ffd83d;
  border-color: #0000;
}

.file.is-danger .file-cta {
  color: #fff;
  background-color: #ff4136;
}

.file.is-danger.is-hovered .file-cta, .file.is-danger:hover .file-cta {
  color: #fff;
  background-color: #ff3529;
}

.file.is-danger.is-focused .file-cta, .file.is-danger:focus .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #ff413640;
}

.file.is-danger.is-active .file-cta, .file.is-danger:active .file-cta {
  color: #fff;
  background-color: #ff291d;
  border-color: #0000;
}

.file.is-medium .file-icon .fa {
  font-size: 21px;
}

.file.is-large .file-icon .fa {
  font-size: 28px;
}

.file.has-name .file-cta {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.file.has-name .file-name {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.file.has-name.is-empty .file-cta, .level code {
  border-radius: 4px;
}

.file.has-name.is-empty .file-name {
  display: none;
}

.file.is-boxed .file-label {
  flex-direction: column;
}

.file.is-boxed .file-cta {
  height: auto;
  flex-direction: column;
  padding: 1em 3em;
}

.file.is-boxed .file-name {
  border-width: 0 1px 1px;
}

.file.is-boxed .file-icon {
  height: 1.5em;
  width: 1.5em;
}

.file.is-boxed .file-icon .fa {
  font-size: 21px;
}

.file.is-boxed.is-small .file-icon .fa {
  font-size: 14px;
}

.file.is-boxed.is-medium .file-icon .fa {
  font-size: 28px;
}

.file.is-boxed.is-large .file-icon .fa {
  font-size: 35px;
}

.card .card-image img, .file.is-boxed.has-name .file-cta {
  border-radius: 4px 4px 0 0;
}

.file.is-boxed.has-name .file-name {
  border-radius: 0 0 4px 4px;
}

.file.is-centered {
  justify-content: center;
}

.file.is-fullwidth .file-label {
  width: 100%;
}

.file.is-fullwidth .file-name {
  max-width: none;
  flex-grow: 1;
}

.file.is-right {
  justify-content: flex-end;
}

.file.is-right .file-cta {
  border-radius: 0 4px 4px 0;
}

.file.is-right .file-name {
  border-width: 1px 0 1px 1px;
  border-radius: 4px 0 0 4px;
  order: -1;
}

.file-label {
  cursor: pointer;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: relative;
  overflow: hidden;
}

.file-label:hover .file-cta {
  color: #363636;
  background-color: #eee;
}

.file-label:hover .file-name {
  border-color: #d5d5d5;
}

.file-label:active .file-cta {
  color: #363636;
  background-color: #e8e8e8;
}

.file-label:active .file-name {
  border-color: #cfcfcf;
}

.file-input {
  height: 100%;
  opacity: 0;
  width: 100%;
  outline: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.file-cta {
  border-color: #dbdbdb;
}

.file-cta, .file-name {
  white-space: nowrap;
  border-radius: 4px;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 1em;
}

.file-cta {
  color: #4a4a4a;
  background-color: #f5f5f5;
}

.file-name {
  max-width: 16em;
  text-align: left;
  text-overflow: ellipsis;
  border: 1px solid #dbdbdb;
  border-left-width: 0;
  display: block;
  overflow: hidden;
}

.file-icon {
  height: 1em;
  width: 1em;
  justify-content: center;
  align-items: center;
  margin-right: .5em;
  display: flex;
}

.file-icon .fa {
  font-size: 14px;
}

.label {
  color: #363636;
  font-size: 1rem;
  font-weight: 700;
  display: block;
}

.label:not(:last-child) {
  margin-bottom: .5em;
}

.help, .label.is-small {
  font-size: .75rem;
}

.label.is-medium {
  font-size: 1.25rem;
}

.label.is-large {
  font-size: 1.5rem;
}

.help {
  margin-top: .25rem;
  display: block;
}

.help.is-white {
  color: #fff;
}

.help.is-black {
  color: #0a0a0a;
}

.help.is-light {
  color: #f5f5f5;
}

.breadcrumb a:hover, .help.is-dark {
  color: #363636;
}

.help.is-primary {
  color: #158cba;
}

.help.is-link {
  color: #5bb7db;
}

.help.is-info {
  color: #209cee;
}

.help.is-success {
  color: #28b62c;
}

.help.is-warning {
  color: #ffdd57;
}

.help.is-danger {
  color: #ff4136;
}

.field:not(:last-child) {
  margin-bottom: .75rem;
}

.field.has-addons, .field.is-grouped {
  justify-content: flex-start;
  display: flex;
}

.field.has-addons .control:not(:last-child) {
  margin-right: -1px;
}

.field.has-addons .control:not(:first-child):not(:last-child) .button, .field.has-addons .control:not(:first-child):not(:last-child) .input, .field.has-addons .control:not(:first-child):not(:last-child) .select select {
  border-radius: 0;
}

.field.has-addons .control:first-child:not(:only-child) .button, .field.has-addons .control:first-child:not(:only-child) .input, .field.has-addons .control:first-child:not(:only-child) .select select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.field.has-addons .control:last-child:not(:only-child) .button, .field.has-addons .control:last-child:not(:only-child) .input, .field.has-addons .control:last-child:not(:only-child) .select select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.field.has-addons .control .button:not([disabled]).is-hovered, .field.has-addons .control .button:not([disabled]):hover, .field.has-addons .control .input:not([disabled]).is-hovered, .field.has-addons .control .input:not([disabled]):hover, .field.has-addons .control .select select:not([disabled]).is-hovered, .field.has-addons .control .select select:not([disabled]):hover {
  z-index: 2;
}

.field.has-addons .control .button:not([disabled]).is-active, .field.has-addons .control .button:not([disabled]).is-focused, .field.has-addons .control .button:not([disabled]):active, .field.has-addons .control .button:not([disabled]):focus, .field.has-addons .control .input:not([disabled]).is-active, .field.has-addons .control .input:not([disabled]).is-focused, .field.has-addons .control .input:not([disabled]):active, .field.has-addons .control .input:not([disabled]):focus, .field.has-addons .control .select select:not([disabled]).is-active, .field.has-addons .control .select select:not([disabled]).is-focused, .field.has-addons .control .select select:not([disabled]):active, .field.has-addons .control .select select:not([disabled]):focus {
  z-index: 3;
}

.field.has-addons .control .button:not([disabled]).is-active:hover, .field.has-addons .control .button:not([disabled]).is-focused:hover, .field.has-addons .control .button:not([disabled]):active:hover, .field.has-addons .control .button:not([disabled]):focus:hover, .field.has-addons .control .input:not([disabled]).is-active:hover, .field.has-addons .control .input:not([disabled]).is-focused:hover, .field.has-addons .control .input:not([disabled]):active:hover, .field.has-addons .control .input:not([disabled]):focus:hover, .field.has-addons .control .select select:not([disabled]).is-active:hover, .field.has-addons .control .select select:not([disabled]).is-focused:hover, .field.has-addons .control .select select:not([disabled]):active:hover, .field.has-addons .control .select select:not([disabled]):focus:hover {
  z-index: 4;
}

.field.has-addons .control.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.field.has-addons.has-addons-centered {
  justify-content: center;
}

.field.has-addons.has-addons-right {
  justify-content: flex-end;
}

.field.has-addons.has-addons-fullwidth .control, .tabs.is-fullwidth li {
  flex-grow: 1;
  flex-shrink: 0;
}

.field.is-grouped > .control {
  flex-shrink: 0;
}

.field.is-grouped > .control:not(:last-child) {
  margin-bottom: 0;
  margin-right: .75rem;
}

.field.is-grouped > .control.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.breadcrumb.is-centered ol, .breadcrumb.is-centered ul, .field.is-grouped.is-grouped-centered {
  justify-content: center;
}

.breadcrumb.is-right ol, .breadcrumb.is-right ul, .field.is-grouped.is-grouped-right {
  justify-content: flex-end;
}

.field.is-grouped.is-grouped-multiline {
  flex-wrap: wrap;
}

.field.is-grouped.is-grouped-multiline > .control:last-child, .field.is-grouped.is-grouped-multiline > .control:not(:last-child) {
  margin-bottom: .75rem;
}

.field.is-grouped.is-grouped-multiline:last-child {
  margin-bottom: -.75rem;
}

.field.is-grouped.is-grouped-multiline:not(:last-child) {
  margin-bottom: 0;
}

@media screen and (min-width: 769px), print {
  .field.is-horizontal {
    display: flex;
  }
}

.field-label .label {
  font-size: inherit;
}

@media screen and (max-width: 768px) {
  .field-label {
    margin-bottom: .5rem;
  }
}

@media screen and (min-width: 769px), print {
  .field-label {
    text-align: right;
    flex: 1 0;
    margin-right: 1.5rem;
  }

  .field-label.is-small {
    padding-top: .375em;
    font-size: .75rem;
  }

  .field-label.is-normal {
    padding-top: .375em;
  }

  .field-label.is-medium {
    padding-top: .375em;
    font-size: 1.25rem;
  }

  .field-label.is-large {
    padding-top: .375em;
    font-size: 1.5rem;
  }
}

.field-body .field .field {
  margin-bottom: 0;
}

@media screen and (min-width: 769px), print {
  .field-body {
    flex-grow: 5;
    flex-basis: 0;
    display: flex;
  }

  .field-body .field {
    margin-bottom: 0;
  }

  .field-body, .field-body > .field {
    flex-shrink: 1;
  }

  .field-body > .field:not(.is-narrow) {
    flex-grow: 1;
  }

  .field-body > .field:not(:last-child) {
    margin-right: .75rem;
  }
}

.control {
  box-sizing: border-box;
  clear: both;
  text-align: left;
  font-size: 1rem;
  position: relative;
}

.control.has-icons-left .input:focus ~ .icon, .control.has-icons-left .select:focus ~ .icon, .control.has-icons-right .input:focus ~ .icon, .control.has-icons-right .select:focus ~ .icon {
  color: #7a7a7a;
}

.breadcrumb.is-small, .control.has-icons-left .input.is-small ~ .icon, .control.has-icons-left .select.is-small ~ .icon, .control.has-icons-right .input.is-small ~ .icon, .control.has-icons-right .select.is-small ~ .icon, .control.is-loading.is-small:after {
  font-size: .75rem;
}

.breadcrumb.is-medium, .control.has-icons-left .input.is-medium ~ .icon, .control.has-icons-left .select.is-medium ~ .icon, .control.has-icons-right .input.is-medium ~ .icon, .control.has-icons-right .select.is-medium ~ .icon, .control.is-loading.is-medium:after {
  font-size: 1.25rem;
}

.breadcrumb.is-large, .control.has-icons-left .input.is-large ~ .icon, .control.has-icons-left .select.is-large ~ .icon, .control.has-icons-right .input.is-large ~ .icon, .control.has-icons-right .select.is-large ~ .icon, .control.is-loading.is-large:after {
  font-size: 1.5rem;
}

.control.has-icons-left .icon, .control.has-icons-right .icon {
  color: #dbdbdb;
  height: 2.25em;
  pointer-events: none;
  width: 2.25em;
  z-index: 4;
  position: absolute;
  top: 0;
}

.control.has-icons-left .input, .control.has-icons-left .select select {
  padding-left: 2.25em;
}

.control.has-icons-left .icon.is-left {
  left: 0;
}

.control.has-icons-right .input, .control.has-icons-right .select select {
  padding-right: 2.25em;
}

.control.has-icons-right .icon.is-right {
  right: 0;
}

.control.is-loading:after {
  z-index: 4;
  top: .625em;
  right: .625em;
  position: absolute !important;
}

.breadcrumb {
  white-space: nowrap;
  font-size: 1rem;
}

.breadcrumb a {
  color: #5bb7db;
  justify-content: center;
  padding: 0 .75em;
}

.breadcrumb a, .breadcrumb li {
  align-items: center;
  display: flex;
}

.breadcrumb li:first-child a {
  padding-left: 0;
}

.breadcrumb li.is-active a {
  color: #363636;
  cursor: default;
  pointer-events: none;
}

.breadcrumb li + li:before {
  color: #b5b5b5;
  content: "/";
}

.breadcrumb ol, .breadcrumb ul {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.breadcrumb .icon:first-child {
  margin-right: .5em;
}

.breadcrumb .icon:last-child {
  margin-left: .5em;
}

.breadcrumb.has-arrow-separator li + li:before {
  content: "→";
}

.breadcrumb.has-bullet-separator li + li:before {
  content: "•";
}

.breadcrumb.has-dot-separator li + li:before {
  content: "·";
}

.breadcrumb.has-succeeds-separator li + li:before {
  content: "≻";
}

.card {
  color: #4a4a4a;
  max-width: 100%;
  background-color: #fff;
  position: relative;
  box-shadow: 0 2px 3px #0a0a0a1a, 0 0 0 1px #0a0a0a1a;
}

.card-header {
  background-color: #0000;
  align-items: stretch;
  display: flex;
  box-shadow: 0 1px 2px #0a0a0a1a;
}

.card-header-title {
  color: #363636;
  flex-grow: 1;
  align-items: center;
  padding: .75rem;
  font-weight: 700;
  display: flex;
}

.card-header-icon, .card-header-title.is-centered {
  justify-content: center;
}

.card-header-icon {
  cursor: pointer;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.card-image {
  display: block;
  position: relative;
}

.card-content, .card-footer {
  background-color: #0000;
}

.card-content {
  padding: 1.5rem;
}

.card-footer {
  border-top: 1px solid #dbdbdb;
  align-items: stretch;
  display: flex;
}

.card-footer-item {
  flex: 1 0;
  justify-content: center;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.card-footer-item:not(:last-child) {
  border-right: 1px solid #dbdbdb;
}

.card .media:not(:last-child) {
  margin-bottom: 1.5rem;
}

.dropdown {
  vertical-align: top;
  display: inline-flex;
  position: relative;
}

.dropdown.is-active .dropdown-menu, .dropdown.is-hoverable:hover .dropdown-menu {
  display: block;
}

.dropdown.is-right .dropdown-menu {
  left: auto;
  right: 0;
}

.dropdown.is-up .dropdown-menu {
  padding-bottom: 4px;
  padding-top: initial;
  top: auto;
  bottom: 100%;
}

.dropdown-menu {
  min-width: 12rem;
  z-index: 20;
  padding-top: 4px;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.dropdown-content {
  background-color: #fff;
  border-radius: 4px;
  padding-top: .5rem;
  padding-bottom: .5rem;
  box-shadow: 0 0 0 1px #dbdbdb, 0 4px 0 1px #dbdbdb;
}

.dropdown-item {
  color: #4a4a4a;
  padding: .375rem 1rem;
  font-size: .875rem;
  line-height: 1.5;
  display: block;
  position: relative;
}

a.dropdown-item, button.dropdown-item {
  text-align: left;
  white-space: nowrap;
  width: 100%;
  padding-right: 3rem;
}

a.dropdown-item:hover, button.dropdown-item:hover {
  color: #0a0a0a;
  background-color: #f5f5f5;
}

a.dropdown-item.is-active, button.dropdown-item.is-active {
  color: #fff;
  background-color: #5bb7db;
}

.dropdown-divider {
  height: 1px;
  background-color: #dbdbdb;
  border: 0;
  margin: .5rem 0;
  display: block;
}

.level {
  justify-content: space-between;
  align-items: center;
}

.level img {
  vertical-align: top;
  display: inline-block;
}

.level.is-mobile, .level.is-mobile .level-left, .level.is-mobile .level-right {
  display: flex;
}

.level.is-mobile .level-left + .level-right {
  margin-top: 0;
}

.level.is-mobile .level-item:not(:last-child) {
  margin-bottom: 0;
  margin-right: .75rem;
}

.level.is-mobile .level-item:not(.is-narrow) {
  flex-grow: 1;
}

@media screen and (min-width: 769px), print {
  .level {
    display: flex;
  }

  .level > .level-item:not(.is-narrow) {
    flex-grow: 1;
  }
}

.level-item {
  justify-content: center;
  align-items: center;
  display: flex;
}

.level-item .subtitle, .level-item .title {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .level-item:not(:last-child) {
    margin-bottom: .75rem;
  }
}

.level-item, .level-left, .level-right {
  flex: none;
}

.level-left .level-item.is-flexible, .level-right .level-item.is-flexible {
  flex-grow: 1;
}

@media screen and (min-width: 769px), print {
  .level-left .level-item:not(:last-child), .level-right .level-item:not(:last-child) {
    margin-right: .75rem;
  }
}

.level-left {
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .level-left + .level-right {
    margin-top: 1.5rem;
  }
}

@media screen and (min-width: 769px), print {
  .level-left {
    display: flex;
  }
}

.level-right {
  justify-content: flex-end;
  align-items: center;
}

@media screen and (min-width: 769px), print {
  .level-right {
    display: flex;
  }
}

.list {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 3px #0a0a0a1a, 0 0 0 1px #0a0a0a1a;
}

.list-item {
  padding: .5em 1em;
  display: block;
}

.list-item:not(a) {
  color: #4a4a4a;
}

.list-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.list-item:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.list-item:not(:last-child) {
  border-bottom: 1px solid #dbdbdb;
}

.list-item.is-active {
  color: #fff;
  background-color: #5bb7db;
}

a.list-item {
  cursor: pointer;
  background-color: #f5f5f5;
}

.media {
  text-align: left;
  align-items: flex-start;
  display: flex;
}

.media .content:not(:last-child) {
  margin-bottom: .75rem;
}

.media .media {
  border-top: 1px solid #dbdbdb80;
  padding-top: .75rem;
  display: flex;
}

.media .media .content:not(:last-child), .media .media .control:not(:last-child) {
  margin-bottom: .5rem;
}

.media .media .media {
  padding-top: .5rem;
}

.media .media .media + .media {
  margin-top: .5rem;
}

.media + .media {
  border-top: 1px solid #dbdbdb80;
  margin-top: 1rem;
  padding-top: 1rem;
}

.media.is-large + .media {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}

.media-left, .media-right {
  flex: none;
}

.media-left {
  margin-right: 1rem;
}

.media-right {
  margin-left: 1rem;
}

.media-content {
  text-align: left;
  flex: auto;
}

@media screen and (max-width: 768px) {
  .media-content {
    overflow-x: auto;
  }
}

.menu {
  font-size: 1rem;
}

.menu.is-small {
  font-size: .75rem;
}

.menu.is-medium {
  font-size: 1.25rem;
}

.menu.is-large {
  font-size: 1.5rem;
}

.menu-list {
  line-height: 1.25;
}

.menu-list a {
  color: #4a4a4a;
  border-radius: 2px;
  padding: .5em .75em;
  display: block;
}

.menu-list a:hover {
  color: #363636;
  background-color: #f5f5f5;
}

.menu-list a.is-active {
  color: #fff;
  background-color: #5bb7db;
}

.menu-list li ul {
  border-left: 1px solid #dbdbdb;
  margin: .75em;
  padding-left: .75em;
}

.menu-label {
  color: #7a7a7a;
  letter-spacing: .1em;
  text-transform: uppercase;
  font-size: .75em;
}

.menu-label:not(:first-child) {
  margin-top: 1em;
}

.menu-label:not(:last-child) {
  margin-bottom: 1em;
}

.message {
  background-color: #f5f5f5;
  border-radius: 4px;
  font-size: 1rem;
}

.message strong {
  color: currentColor;
}

.message.is-small {
  font-size: .75rem;
}

.message.is-medium {
  font-size: 1.25rem;
}

.message.is-large {
  font-size: 1.5rem;
}

.message-body code, .message-body pre, .message.is-white {
  background-color: #fff;
}

.message.is-white .message-header {
  color: #0a0a0a;
  background-color: #fff;
}

.message.is-white .message-body {
  color: #4d4d4d;
  border-color: #fff;
}

.message.is-black, .message.is-dark, .message.is-light {
  background-color: #fafafa;
}

.message.is-black .message-header {
  color: #fff;
  background-color: #0a0a0a;
}

.message.is-black .message-body {
  color: #090909;
  border-color: #0a0a0a;
}

.message.is-light .message-header {
  color: #363636;
  background-color: #f5f5f5;
}

.message.is-light .message-body {
  color: #505050;
  border-color: #f5f5f5;
}

.message.is-dark .message-header {
  color: #f5f5f5;
  background-color: #363636;
}

.message.is-dark .message-body {
  color: #2a2a2a;
  border-color: #363636;
}

.message.is-primary {
  background-color: #f6fcfe;
}

.message.is-primary .message-header {
  color: #fff;
  background-color: #158cba;
}

.message.is-primary .message-body {
  color: #0f475c;
  border-color: #158cba;
}

.message.is-link {
  background-color: #f7fbfd;
}

.message.is-link .message-header {
  color: #fff;
  background-color: #5bb7db;
}

.message.is-link .message-body {
  color: #24576b;
  border-color: #5bb7db;
}

.message.is-info {
  background-color: #f6fbfe;
}

.message.is-info .message-header {
  color: #fff;
  background-color: #209cee;
}

.message.is-info .message-body {
  color: #12537e;
  border-color: #209cee;
}

.message.is-success {
  background-color: #f7fdf7;
}

.message.is-success .message-header {
  color: #fff;
  background-color: #28b62c;
}

.message.is-success .message-body {
  color: #143f15;
  border-color: #28b62c;
}

.message.is-warning {
  background-color: #fffdf5;
}

.message.is-warning .message-header {
  color: #000000b3;
  background-color: #ffdd57;
}

.message.is-warning .message-body {
  color: #3b3108;
  border-color: #ffdd57;
}

.message.is-danger {
  background-color: #fff5f5;
}

.message.is-danger .message-header {
  color: #fff;
  background-color: #ff4136;
}

.message.is-danger .message-body {
  color: #c91309;
  border-color: #ff4136;
}

.message-header {
  color: #fff;
  background-color: #4a4a4a;
  border-radius: 4px 4px 0 0;
  justify-content: space-between;
  align-items: center;
  padding: .75em 1em;
  font-weight: 700;
  line-height: 1.25;
  display: flex;
  position: relative;
}

.message-header .delete {
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: .75em;
}

.message-header + .message-body {
  border-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.message-body {
  color: #4a4a4a;
  border: 0 solid #dbdbdb;
  border-left-width: 4px;
  border-radius: 4px;
  padding: 1.25em 1.5em;
}

.modal {
  z-index: 40;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  overflow: hidden;
}

.modal.is-active {
  display: flex;
}

.modal-background {
  background-color: #0a0a0adb;
}

.modal-content {
  max-height: calc(100vh - 160px);
  overflow: auto;
}

.modal-card, .modal-content {
  width: 100%;
  margin: 0 20px;
  position: relative;
}

@media screen and (min-width: 769px), print {
  .modal-card, .modal-content {
    max-height: calc(100vh - 40px);
    width: 640px;
    margin: 0 auto;
  }
}

.modal-close {
  height: 40px;
  width: 40px;
  background: none;
  position: fixed;
  top: 20px;
  right: 20px;
}

.modal-card {
  max-height: calc(100vh - 40px);
  -ms-overflow-y: visible;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.modal-card-foot, .modal-card-head {
  background-color: #f5f5f5;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  display: flex;
  position: relative;
}

.modal-card-head {
  border-bottom: 1px solid #dbdbdb;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.modal-card-title {
  color: #363636;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.5rem;
  line-height: 1;
}

.modal-card-foot {
  border-top: 1px solid #dbdbdb;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.modal-card-foot .button:not(:last-child) {
  margin-right: .5em;
}

.modal-card-body {
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 20px;
  overflow: auto;
}

.navbar {
  min-height: 3.25rem;
  z-index: 30;
  background-color: #fff;
  position: relative;
}

.navbar.is-white {
  color: #0a0a0a;
  background-color: #fff;
}

.navbar.is-white .navbar-brand .navbar-link, .navbar.is-white .navbar-brand > .navbar-item, .navbar.is-white .navbar-burger {
  color: #0a0a0a;
}

.navbar.is-white .navbar-brand .navbar-link.is-active, .navbar.is-white .navbar-brand .navbar-link:focus, .navbar.is-white .navbar-brand .navbar-link:hover, .navbar.is-white .navbar-brand > a.navbar-item.is-active, .navbar.is-white .navbar-brand > a.navbar-item:focus, .navbar.is-white .navbar-brand > a.navbar-item:hover {
  color: #0a0a0a;
  background-color: #f2f2f2;
}

@media screen and (min-width: 1024px) {
  .navbar.is-white .navbar-end .navbar-link, .navbar.is-white .navbar-end > .navbar-item, .navbar.is-white .navbar-start .navbar-link, .navbar.is-white .navbar-start > .navbar-item {
    color: #0a0a0a;
  }

  .navbar.is-white .navbar-end .navbar-link.is-active, .navbar.is-white .navbar-end .navbar-link:focus, .navbar.is-white .navbar-end .navbar-link:hover, .navbar.is-white .navbar-end > a.navbar-item.is-active, .navbar.is-white .navbar-end > a.navbar-item:focus, .navbar.is-white .navbar-end > a.navbar-item:hover, .navbar.is-white .navbar-item.has-dropdown.is-active .navbar-link, .navbar.is-white .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-white .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-white .navbar-start .navbar-link.is-active, .navbar.is-white .navbar-start .navbar-link:focus, .navbar.is-white .navbar-start .navbar-link:hover, .navbar.is-white .navbar-start > a.navbar-item.is-active, .navbar.is-white .navbar-start > a.navbar-item:focus, .navbar.is-white .navbar-start > a.navbar-item:hover {
    color: #0a0a0a;
    background-color: #f2f2f2;
  }

  .navbar.is-white .navbar-end .navbar-link:after, .navbar.is-white .navbar-start .navbar-link:after {
    border-color: #0a0a0a;
  }

  .navbar.is-white .navbar-dropdown a.navbar-item.is-active {
    color: #0a0a0a;
    background-color: #fff;
  }
}

.navbar.is-black {
  color: #fff;
  background-color: #0a0a0a;
}

.navbar.is-black .navbar-brand .navbar-link, .navbar.is-black .navbar-brand > .navbar-item, .navbar.is-black .navbar-burger {
  color: #fff;
}

.navbar.is-black .navbar-brand .navbar-link.is-active, .navbar.is-black .navbar-brand .navbar-link:focus, .navbar.is-black .navbar-brand .navbar-link:hover, .navbar.is-black .navbar-brand > a.navbar-item.is-active, .navbar.is-black .navbar-brand > a.navbar-item:focus, .navbar.is-black .navbar-brand > a.navbar-item:hover {
  color: #fff;
  background-color: #000;
}

.navbar.is-black .navbar-brand .navbar-link:after {
  border-color: #fff;
}

@media screen and (min-width: 1024px) {
  .navbar.is-black .navbar-end .navbar-link, .navbar.is-black .navbar-end > .navbar-item, .navbar.is-black .navbar-start .navbar-link, .navbar.is-black .navbar-start > .navbar-item {
    color: #fff;
  }

  .navbar.is-black .navbar-end .navbar-link.is-active, .navbar.is-black .navbar-end .navbar-link:focus, .navbar.is-black .navbar-end .navbar-link:hover, .navbar.is-black .navbar-end > a.navbar-item.is-active, .navbar.is-black .navbar-end > a.navbar-item:focus, .navbar.is-black .navbar-end > a.navbar-item:hover, .navbar.is-black .navbar-item.has-dropdown.is-active .navbar-link, .navbar.is-black .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-black .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-black .navbar-start .navbar-link.is-active, .navbar.is-black .navbar-start .navbar-link:focus, .navbar.is-black .navbar-start .navbar-link:hover, .navbar.is-black .navbar-start > a.navbar-item.is-active, .navbar.is-black .navbar-start > a.navbar-item:focus, .navbar.is-black .navbar-start > a.navbar-item:hover {
    color: #fff;
    background-color: #000;
  }

  .navbar.is-black .navbar-end .navbar-link:after, .navbar.is-black .navbar-start .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-black .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #0a0a0a;
  }
}

.navbar.is-light {
  color: #363636;
  background-color: #f5f5f5;
}

.navbar.is-light .navbar-brand .navbar-link, .navbar.is-light .navbar-brand > .navbar-item, .navbar.is-light .navbar-burger {
  color: #363636;
}

.navbar.is-light .navbar-brand .navbar-link.is-active, .navbar.is-light .navbar-brand .navbar-link:focus, .navbar.is-light .navbar-brand .navbar-link:hover, .navbar.is-light .navbar-brand > a.navbar-item.is-active, .navbar.is-light .navbar-brand > a.navbar-item:focus, .navbar.is-light .navbar-brand > a.navbar-item:hover {
  color: #363636;
  background-color: #e8e8e8;
}

.navbar.is-light .navbar-brand .navbar-link:after {
  border-color: #363636;
}

@media screen and (min-width: 1024px) {
  .navbar.is-light .navbar-end .navbar-link, .navbar.is-light .navbar-end > .navbar-item, .navbar.is-light .navbar-start .navbar-link, .navbar.is-light .navbar-start > .navbar-item {
    color: #363636;
  }

  .navbar.is-light .navbar-end .navbar-link.is-active, .navbar.is-light .navbar-end .navbar-link:focus, .navbar.is-light .navbar-end .navbar-link:hover, .navbar.is-light .navbar-end > a.navbar-item.is-active, .navbar.is-light .navbar-end > a.navbar-item:focus, .navbar.is-light .navbar-end > a.navbar-item:hover, .navbar.is-light .navbar-item.has-dropdown.is-active .navbar-link, .navbar.is-light .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-light .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-light .navbar-start .navbar-link.is-active, .navbar.is-light .navbar-start .navbar-link:focus, .navbar.is-light .navbar-start .navbar-link:hover, .navbar.is-light .navbar-start > a.navbar-item.is-active, .navbar.is-light .navbar-start > a.navbar-item:focus, .navbar.is-light .navbar-start > a.navbar-item:hover {
    color: #363636;
    background-color: #e8e8e8;
  }

  .navbar.is-light .navbar-end .navbar-link:after, .navbar.is-light .navbar-start .navbar-link:after {
    border-color: #363636;
  }

  .navbar.is-light .navbar-dropdown a.navbar-item.is-active {
    color: #363636;
    background-color: #f5f5f5;
  }
}

.navbar.is-dark {
  color: #f5f5f5;
  background-color: #363636;
}

.navbar.is-dark .navbar-brand .navbar-link, .navbar.is-dark .navbar-brand > .navbar-item, .navbar.is-dark .navbar-burger {
  color: #f5f5f5;
}

.navbar.is-dark .navbar-brand .navbar-link.is-active, .navbar.is-dark .navbar-brand .navbar-link:focus, .navbar.is-dark .navbar-brand .navbar-link:hover, .navbar.is-dark .navbar-brand > a.navbar-item.is-active, .navbar.is-dark .navbar-brand > a.navbar-item:focus, .navbar.is-dark .navbar-brand > a.navbar-item:hover {
  color: #f5f5f5;
  background-color: #292929;
}

.navbar.is-dark .navbar-brand .navbar-link:after {
  border-color: #f5f5f5;
}

@media screen and (min-width: 1024px) {
  .navbar.is-dark .navbar-end .navbar-link, .navbar.is-dark .navbar-end > .navbar-item, .navbar.is-dark .navbar-start .navbar-link, .navbar.is-dark .navbar-start > .navbar-item {
    color: #f5f5f5;
  }

  .navbar.is-dark .navbar-end .navbar-link.is-active, .navbar.is-dark .navbar-end .navbar-link:focus, .navbar.is-dark .navbar-end .navbar-link:hover, .navbar.is-dark .navbar-end > a.navbar-item.is-active, .navbar.is-dark .navbar-end > a.navbar-item:focus, .navbar.is-dark .navbar-end > a.navbar-item:hover, .navbar.is-dark .navbar-item.has-dropdown.is-active .navbar-link, .navbar.is-dark .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-dark .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-dark .navbar-start .navbar-link.is-active, .navbar.is-dark .navbar-start .navbar-link:focus, .navbar.is-dark .navbar-start .navbar-link:hover, .navbar.is-dark .navbar-start > a.navbar-item.is-active, .navbar.is-dark .navbar-start > a.navbar-item:focus, .navbar.is-dark .navbar-start > a.navbar-item:hover {
    color: #f5f5f5;
    background-color: #292929;
  }

  .navbar.is-dark .navbar-end .navbar-link:after, .navbar.is-dark .navbar-start .navbar-link:after {
    border-color: #f5f5f5;
  }

  .navbar.is-dark .navbar-dropdown a.navbar-item.is-active {
    color: #f5f5f5;
    background-color: #363636;
  }
}

.navbar.is-primary {
  color: #fff;
  background-color: #158cba;
}

.navbar.is-primary .navbar-brand .navbar-link, .navbar.is-primary .navbar-brand > .navbar-item, .navbar.is-primary .navbar-burger {
  color: #fff;
}

.navbar.is-primary .navbar-brand .navbar-link.is-active, .navbar.is-primary .navbar-brand .navbar-link:focus, .navbar.is-primary .navbar-brand .navbar-link:hover, .navbar.is-primary .navbar-brand > a.navbar-item.is-active, .navbar.is-primary .navbar-brand > a.navbar-item:focus, .navbar.is-primary .navbar-brand > a.navbar-item:hover {
  color: #fff;
  background-color: #127ba3;
}

.navbar.is-primary .navbar-brand .navbar-link:after {
  border-color: #fff;
}

@media screen and (min-width: 1024px) {
  .navbar.is-primary .navbar-end .navbar-link, .navbar.is-primary .navbar-end > .navbar-item, .navbar.is-primary .navbar-start .navbar-link, .navbar.is-primary .navbar-start > .navbar-item {
    color: #fff;
  }

  .navbar.is-primary .navbar-end .navbar-link.is-active, .navbar.is-primary .navbar-end .navbar-link:focus, .navbar.is-primary .navbar-end .navbar-link:hover, .navbar.is-primary .navbar-end > a.navbar-item.is-active, .navbar.is-primary .navbar-end > a.navbar-item:focus, .navbar.is-primary .navbar-end > a.navbar-item:hover, .navbar.is-primary .navbar-item.has-dropdown.is-active .navbar-link, .navbar.is-primary .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-primary .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-primary .navbar-start .navbar-link.is-active, .navbar.is-primary .navbar-start .navbar-link:focus, .navbar.is-primary .navbar-start .navbar-link:hover, .navbar.is-primary .navbar-start > a.navbar-item.is-active, .navbar.is-primary .navbar-start > a.navbar-item:focus, .navbar.is-primary .navbar-start > a.navbar-item:hover {
    color: #fff;
    background-color: #127ba3;
  }

  .navbar.is-primary .navbar-end .navbar-link:after, .navbar.is-primary .navbar-start .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-primary .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #158cba;
  }
}

.navbar.is-link {
  color: #fff;
  background-color: #5bb7db;
}

.navbar.is-link .navbar-brand .navbar-link, .navbar.is-link .navbar-brand > .navbar-item, .navbar.is-link .navbar-burger {
  color: #fff;
}

.navbar.is-link .navbar-brand .navbar-link.is-active, .navbar.is-link .navbar-brand .navbar-link:focus, .navbar.is-link .navbar-brand .navbar-link:hover, .navbar.is-link .navbar-brand > a.navbar-item.is-active, .navbar.is-link .navbar-brand > a.navbar-item:focus, .navbar.is-link .navbar-brand > a.navbar-item:hover {
  color: #fff;
  background-color: #46aed6;
}

.navbar.is-link .navbar-brand .navbar-link:after {
  border-color: #fff;
}

@media screen and (min-width: 1024px) {
  .navbar.is-link .navbar-end .navbar-link, .navbar.is-link .navbar-end > .navbar-item, .navbar.is-link .navbar-start .navbar-link, .navbar.is-link .navbar-start > .navbar-item {
    color: #fff;
  }

  .navbar.is-link .navbar-end .navbar-link.is-active, .navbar.is-link .navbar-end .navbar-link:focus, .navbar.is-link .navbar-end .navbar-link:hover, .navbar.is-link .navbar-end > a.navbar-item.is-active, .navbar.is-link .navbar-end > a.navbar-item:focus, .navbar.is-link .navbar-end > a.navbar-item:hover, .navbar.is-link .navbar-item.has-dropdown.is-active .navbar-link, .navbar.is-link .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-link .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-link .navbar-start .navbar-link.is-active, .navbar.is-link .navbar-start .navbar-link:focus, .navbar.is-link .navbar-start .navbar-link:hover, .navbar.is-link .navbar-start > a.navbar-item.is-active, .navbar.is-link .navbar-start > a.navbar-item:focus, .navbar.is-link .navbar-start > a.navbar-item:hover {
    color: #fff;
    background-color: #46aed6;
  }

  .navbar.is-link .navbar-end .navbar-link:after, .navbar.is-link .navbar-start .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-link .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #5bb7db;
  }
}

.navbar.is-info {
  color: #fff;
  background-color: #209cee;
}

.navbar.is-info .navbar-brand .navbar-link, .navbar.is-info .navbar-brand > .navbar-item, .navbar.is-info .navbar-burger {
  color: #fff;
}

.navbar.is-info .navbar-brand .navbar-link.is-active, .navbar.is-info .navbar-brand .navbar-link:focus, .navbar.is-info .navbar-brand .navbar-link:hover, .navbar.is-info .navbar-brand > a.navbar-item.is-active, .navbar.is-info .navbar-brand > a.navbar-item:focus, .navbar.is-info .navbar-brand > a.navbar-item:hover {
  color: #fff;
  background-color: #118fe4;
}

.navbar.is-info .navbar-brand .navbar-link:after {
  border-color: #fff;
}

@media screen and (min-width: 1024px) {
  .navbar.is-info .navbar-end .navbar-link, .navbar.is-info .navbar-end > .navbar-item, .navbar.is-info .navbar-start .navbar-link, .navbar.is-info .navbar-start > .navbar-item {
    color: #fff;
  }

  .navbar.is-info .navbar-end .navbar-link.is-active, .navbar.is-info .navbar-end .navbar-link:focus, .navbar.is-info .navbar-end .navbar-link:hover, .navbar.is-info .navbar-end > a.navbar-item.is-active, .navbar.is-info .navbar-end > a.navbar-item:focus, .navbar.is-info .navbar-end > a.navbar-item:hover, .navbar.is-info .navbar-item.has-dropdown.is-active .navbar-link, .navbar.is-info .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-info .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-info .navbar-start .navbar-link.is-active, .navbar.is-info .navbar-start .navbar-link:focus, .navbar.is-info .navbar-start .navbar-link:hover, .navbar.is-info .navbar-start > a.navbar-item.is-active, .navbar.is-info .navbar-start > a.navbar-item:focus, .navbar.is-info .navbar-start > a.navbar-item:hover {
    color: #fff;
    background-color: #118fe4;
  }

  .navbar.is-info .navbar-end .navbar-link:after, .navbar.is-info .navbar-start .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-info .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #209cee;
  }
}

.navbar.is-success {
  color: #fff;
  background-color: #28b62c;
}

.navbar.is-success .navbar-brand .navbar-link, .navbar.is-success .navbar-brand > .navbar-item, .navbar.is-success .navbar-burger {
  color: #fff;
}

.navbar.is-success .navbar-brand .navbar-link.is-active, .navbar.is-success .navbar-brand .navbar-link:focus, .navbar.is-success .navbar-brand .navbar-link:hover, .navbar.is-success .navbar-brand > a.navbar-item.is-active, .navbar.is-success .navbar-brand > a.navbar-item:focus, .navbar.is-success .navbar-brand > a.navbar-item:hover {
  color: #fff;
  background-color: #23a127;
}

.navbar.is-success .navbar-brand .navbar-link:after {
  border-color: #fff;
}

@media screen and (min-width: 1024px) {
  .navbar.is-success .navbar-end .navbar-link, .navbar.is-success .navbar-end > .navbar-item, .navbar.is-success .navbar-start .navbar-link, .navbar.is-success .navbar-start > .navbar-item {
    color: #fff;
  }

  .navbar.is-success .navbar-end .navbar-link.is-active, .navbar.is-success .navbar-end .navbar-link:focus, .navbar.is-success .navbar-end .navbar-link:hover, .navbar.is-success .navbar-end > a.navbar-item.is-active, .navbar.is-success .navbar-end > a.navbar-item:focus, .navbar.is-success .navbar-end > a.navbar-item:hover, .navbar.is-success .navbar-item.has-dropdown.is-active .navbar-link, .navbar.is-success .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-success .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-success .navbar-start .navbar-link.is-active, .navbar.is-success .navbar-start .navbar-link:focus, .navbar.is-success .navbar-start .navbar-link:hover, .navbar.is-success .navbar-start > a.navbar-item.is-active, .navbar.is-success .navbar-start > a.navbar-item:focus, .navbar.is-success .navbar-start > a.navbar-item:hover {
    color: #fff;
    background-color: #23a127;
  }

  .navbar.is-success .navbar-end .navbar-link:after, .navbar.is-success .navbar-start .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-success .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #28b62c;
  }
}

.navbar.is-warning {
  background-color: #ffdd57;
}

.navbar.is-warning, .navbar.is-warning .navbar-brand .navbar-link, .navbar.is-warning .navbar-brand > .navbar-item, .navbar.is-warning .navbar-burger {
  color: #000000b3;
}

.navbar.is-warning .navbar-brand .navbar-link.is-active, .navbar.is-warning .navbar-brand .navbar-link:focus, .navbar.is-warning .navbar-brand .navbar-link:hover, .navbar.is-warning .navbar-brand > a.navbar-item.is-active, .navbar.is-warning .navbar-brand > a.navbar-item:focus, .navbar.is-warning .navbar-brand > a.navbar-item:hover {
  color: #000000b3;
  background-color: #ffd83d;
}

.navbar.is-warning .navbar-brand .navbar-link:after {
  border-color: #000000b3;
}

@media screen and (min-width: 1024px) {
  .navbar.is-warning .navbar-end .navbar-link, .navbar.is-warning .navbar-end > .navbar-item, .navbar.is-warning .navbar-start .navbar-link, .navbar.is-warning .navbar-start > .navbar-item {
    color: #000000b3;
  }

  .navbar.is-warning .navbar-end .navbar-link.is-active, .navbar.is-warning .navbar-end .navbar-link:focus, .navbar.is-warning .navbar-end .navbar-link:hover, .navbar.is-warning .navbar-end > a.navbar-item.is-active, .navbar.is-warning .navbar-end > a.navbar-item:focus, .navbar.is-warning .navbar-end > a.navbar-item:hover, .navbar.is-warning .navbar-item.has-dropdown.is-active .navbar-link, .navbar.is-warning .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-warning .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-warning .navbar-start .navbar-link.is-active, .navbar.is-warning .navbar-start .navbar-link:focus, .navbar.is-warning .navbar-start .navbar-link:hover, .navbar.is-warning .navbar-start > a.navbar-item.is-active, .navbar.is-warning .navbar-start > a.navbar-item:focus, .navbar.is-warning .navbar-start > a.navbar-item:hover {
    color: #000000b3;
    background-color: #ffd83d;
  }

  .navbar.is-warning .navbar-end .navbar-link:after, .navbar.is-warning .navbar-start .navbar-link:after {
    border-color: #000000b3;
  }

  .navbar.is-warning .navbar-dropdown a.navbar-item.is-active {
    color: #000000b3;
    background-color: #ffdd57;
  }
}

.navbar.is-danger {
  color: #fff;
  background-color: #ff4136;
}

.navbar.is-danger .navbar-brand .navbar-link, .navbar.is-danger .navbar-brand > .navbar-item, .navbar.is-danger .navbar-burger {
  color: #fff;
}

.navbar.is-danger .navbar-brand .navbar-link.is-active, .navbar.is-danger .navbar-brand .navbar-link:focus, .navbar.is-danger .navbar-brand .navbar-link:hover, .navbar.is-danger .navbar-brand > a.navbar-item.is-active, .navbar.is-danger .navbar-brand > a.navbar-item:focus, .navbar.is-danger .navbar-brand > a.navbar-item:hover {
  color: #fff;
  background-color: #ff291d;
}

.navbar.is-danger .navbar-brand .navbar-link:after {
  border-color: #fff;
}

@media screen and (min-width: 1024px) {
  .navbar.is-danger .navbar-end .navbar-link, .navbar.is-danger .navbar-end > .navbar-item, .navbar.is-danger .navbar-start .navbar-link, .navbar.is-danger .navbar-start > .navbar-item {
    color: #fff;
  }

  .navbar.is-danger .navbar-end .navbar-link.is-active, .navbar.is-danger .navbar-end .navbar-link:focus, .navbar.is-danger .navbar-end .navbar-link:hover, .navbar.is-danger .navbar-end > a.navbar-item.is-active, .navbar.is-danger .navbar-end > a.navbar-item:focus, .navbar.is-danger .navbar-end > a.navbar-item:hover, .navbar.is-danger .navbar-item.has-dropdown.is-active .navbar-link, .navbar.is-danger .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-danger .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-danger .navbar-start .navbar-link.is-active, .navbar.is-danger .navbar-start .navbar-link:focus, .navbar.is-danger .navbar-start .navbar-link:hover, .navbar.is-danger .navbar-start > a.navbar-item.is-active, .navbar.is-danger .navbar-start > a.navbar-item:focus, .navbar.is-danger .navbar-start > a.navbar-item:hover {
    color: #fff;
    background-color: #ff291d;
  }

  .navbar.is-danger .navbar-end .navbar-link:after, .navbar.is-danger .navbar-start .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-danger .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #ff4136;
  }
}

.navbar > .container {
  min-height: 3.25rem;
  width: 100%;
  align-items: stretch;
  display: flex;
}

.navbar.has-shadow {
  box-shadow: 0 2px #f5f5f5;
}

.navbar.is-fixed-bottom, .navbar.is-fixed-top {
  z-index: 30;
  position: fixed;
  left: 0;
  right: 0;
}

.navbar.is-fixed-bottom {
  bottom: 0;
}

.navbar.is-fixed-bottom.has-shadow {
  box-shadow: 0 -2px #f5f5f5;
}

.navbar.is-fixed-top {
  top: 0;
}

body.has-navbar-fixed-top, html.has-navbar-fixed-top {
  padding-top: 3.25rem;
}

body.has-navbar-fixed-bottom, html.has-navbar-fixed-bottom {
  padding-bottom: 3.25rem;
}

.navbar-brand, .navbar-tabs {
  min-height: 3.25rem;
  flex-shrink: 0;
  align-items: stretch;
  display: flex;
}

.navbar-brand a.navbar-item:focus, .navbar-brand a.navbar-item:hover {
  background-color: #0000;
}

.navbar-tabs {
  -webkit-overflow-scrolling: touch;
  max-width: 100vw;
  overflow-x: auto;
  overflow-y: hidden;
}

.navbar-burger {
  color: #4a4a4a;
  cursor: pointer;
  height: 3.25rem;
  width: 3.25rem;
  margin-left: auto;
  display: block;
  position: relative;
}

.navbar-burger span {
  height: 1px;
  transform-origin: center;
  width: 16px;
  background-color: currentColor;
  transition-property: background-color, opacity, transform;
  transition-duration: 86ms;
  transition-timing-function: ease-out;
  display: block;
  position: absolute;
  left: calc(50% - 8px);
}

.navbar-burger span:nth-child(1) {
  top: calc(50% - 6px);
}

.navbar-burger span:nth-child(2) {
  top: calc(50% - 1px);
}

.navbar-burger span:nth-child(3) {
  top: calc(50% + 4px);
}

.navbar-burger:hover {
  background-color: #0000000d;
}

.navbar-burger.is-active span:nth-child(1) {
  transform: translateY(5px)rotate(45deg);
}

.navbar-burger.is-active span:nth-child(2) {
  opacity: 0;
}

.navbar-burger.is-active span:nth-child(3) {
  transform: translateY(-5px)rotate(-45deg);
}

.navbar-menu {
  display: none;
}

.navbar-item, .navbar-link {
  color: #4a4a4a;
  padding: .5rem .75rem;
  line-height: 1.5;
  position: relative;
}

.navbar-link {
  display: block;
}

.navbar-item .icon:only-child, .navbar-link .icon:only-child {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.navbar-link, a.navbar-item {
  cursor: pointer;
}

.navbar-link.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, a.navbar-item.is-active, a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover {
  color: #5bb7db;
  background-color: #fafafa;
}

.navbar-item {
  flex-grow: 0;
  flex-shrink: 0;
  display: block;
}

.navbar-item img {
  max-height: 1.75rem;
}

.navbar-item.has-dropdown {
  padding: 0;
}

.navbar-content, .navbar-item.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.navbar-item.is-tab {
  min-height: 3.25rem;
  border-bottom: 1px solid #0000;
  padding-bottom: calc(.5rem - 1px);
}

.navbar-item.is-tab:focus, .navbar-item.is-tab:hover {
  background-color: #0000;
  border-bottom-color: #5bb7db;
}

.navbar-item.is-tab.is-active {
  color: #5bb7db;
  background-color: #0000;
  border-bottom: 3px solid #5bb7db;
  padding-bottom: calc(.5rem - 3px);
}

.navbar-link:not(.is-arrowless) {
  padding-right: 2.5em;
}

.navbar-link:not(.is-arrowless):after {
  border-color: #5bb7db;
  margin-top: -.375em;
  right: 1.125em;
}

.navbar-dropdown {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: .875rem;
}

.navbar-dropdown .navbar-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.navbar-divider {
  height: 2px;
  background-color: #f5f5f5;
  border: 0;
  margin: .5rem 0;
  display: none;
}

@media screen and (max-width: 1023px) {
  .navbar > .container {
    display: block;
  }

  .navbar-brand .navbar-item, .navbar-tabs .navbar-item {
    align-items: center;
    display: flex;
  }

  .navbar-link:after {
    display: none;
  }

  .navbar-menu {
    background-color: #fff;
    padding: .5rem 0;
    box-shadow: 0 8px 16px #0a0a0a1a;
  }

  .navbar-menu.is-active {
    display: block;
  }

  .navbar.is-fixed-bottom-touch, .navbar.is-fixed-top-touch {
    z-index: 30;
    position: fixed;
    left: 0;
    right: 0;
  }

  .navbar.is-fixed-bottom-touch {
    bottom: 0;
  }

  .navbar.is-fixed-bottom-touch.has-shadow {
    box-shadow: 0 -2px 3px #0a0a0a1a;
  }

  .navbar.is-fixed-top-touch {
    top: 0;
  }

  .navbar.is-fixed-top .navbar-menu, .navbar.is-fixed-top-touch .navbar-menu {
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 3.25rem);
    overflow: auto;
  }

  body.has-navbar-fixed-top-touch, html.has-navbar-fixed-top-touch {
    padding-top: 3.25rem;
  }

  body.has-navbar-fixed-bottom-touch, html.has-navbar-fixed-bottom-touch {
    padding-bottom: 3.25rem;
  }
}

@media screen and (min-width: 1024px) {
  .navbar, .navbar-end, .navbar-menu, .navbar-start {
    align-items: stretch;
    display: flex;
  }

  .navbar {
    min-height: 3.25rem;
  }

  .navbar.is-spaced {
    padding: 1rem 2rem;
  }

  .navbar.is-spaced .navbar-end, .navbar.is-spaced .navbar-start {
    align-items: center;
  }

  .navbar.is-spaced .navbar-link, .navbar.is-spaced a.navbar-item {
    border-radius: 4px;
  }

  .navbar.is-transparent .navbar-link.is-active, .navbar.is-transparent .navbar-link:focus, .navbar.is-transparent .navbar-link:hover, .navbar.is-transparent a.navbar-item.is-active, .navbar.is-transparent a.navbar-item:focus, .navbar.is-transparent a.navbar-item:hover, .navbar.is-transparent .navbar-item.has-dropdown.is-active .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:focus .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:focus-within .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:hover .navbar-link {
    background-color: #0000 !important;
  }

  .navbar-dropdown a.navbar-item:focus, .navbar-dropdown a.navbar-item:hover, .navbar.is-transparent .navbar-dropdown a.navbar-item:focus, .navbar.is-transparent .navbar-dropdown a.navbar-item:hover {
    color: #0a0a0a;
    background-color: #f5f5f5;
  }

  .navbar-dropdown a.navbar-item.is-active, .navbar.is-transparent .navbar-dropdown a.navbar-item.is-active {
    color: #5bb7db;
    background-color: #f5f5f5;
  }

  .navbar-burger {
    display: none;
  }

  .navbar-item, .navbar-link {
    align-items: center;
  }

  .navbar-link, .navbar-item {
    display: flex;
  }

  .navbar-item.has-dropdown {
    align-items: stretch;
  }

  .navbar-item.has-dropdown-up .navbar-link:after {
    transform: rotate(135deg)translate(.25em, -.25em);
  }

  .navbar-item.has-dropdown-up .navbar-dropdown {
    border-top: none;
    border-bottom: 2px solid #dbdbdb;
    border-radius: 6px 6px 0 0;
    top: auto;
    bottom: 100%;
    box-shadow: 0 -8px 8px #0a0a0a1a;
  }

  .navbar-item.is-active .navbar-dropdown, .navbar-item.is-hoverable:focus .navbar-dropdown, .navbar-item.is-hoverable:focus-within .navbar-dropdown, .navbar-item.is-hoverable:hover .navbar-dropdown {
    display: block;
  }

  .navbar-item.is-active .navbar-dropdown.is-boxed, .navbar-item.is-hoverable:focus .navbar-dropdown.is-boxed, .navbar-item.is-hoverable:focus-within .navbar-dropdown.is-boxed, .navbar-item.is-hoverable:hover .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-active .navbar-dropdown, .navbar.is-spaced .navbar-item.is-hoverable:focus .navbar-dropdown, .navbar.is-spaced .navbar-item.is-hoverable:focus-within .navbar-dropdown, .navbar.is-spaced .navbar-item.is-hoverable:hover .navbar-dropdown {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }

  .navbar-menu {
    flex-grow: 1;
    flex-shrink: 0;
  }

  .navbar-start {
    justify-content: flex-start;
    margin-right: auto;
  }

  .navbar-end {
    justify-content: flex-end;
    margin-left: auto;
  }

  .navbar-dropdown {
    min-width: 100%;
    z-index: 20;
    background-color: #fff;
    border-top: 2px solid #dbdbdb;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    font-size: .875rem;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0 8px 8px #0a0a0a1a;
  }

  .navbar-dropdown .navbar-item {
    white-space: nowrap;
    padding: .375rem 1rem;
  }

  .navbar-dropdown a.navbar-item {
    padding-right: 3rem;
  }

  .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-dropdown {
    opacity: 0;
    pointer-events: none;
    border-top: none;
    border-radius: 6px;
    transition-property: opacity, transform;
    transition-duration: 86ms;
    display: block;
    top: calc(100% - 4px);
    transform: translateY(-5px);
    box-shadow: 0 0 0 1px #dbdbdb, 0 4px 0 1px #dbdbdb;
  }

  .navbar-dropdown.is-right {
    left: auto;
    right: 0;
  }

  .navbar-divider {
    display: block;
  }

  .container > .navbar .navbar-brand, .navbar > .container .navbar-brand {
    margin-left: -.75rem;
  }

  .container > .navbar .navbar-menu, .navbar > .container .navbar-menu {
    margin-right: -.75rem;
  }

  .navbar.is-fixed-bottom-desktop, .navbar.is-fixed-top-desktop {
    z-index: 30;
    position: fixed;
    left: 0;
    right: 0;
  }

  .navbar.is-fixed-bottom-desktop {
    bottom: 0;
  }

  .navbar.is-fixed-bottom-desktop.has-shadow {
    box-shadow: 0 -2px 3px #0a0a0a1a;
  }

  .navbar.is-fixed-top-desktop {
    top: 0;
  }

  body.has-navbar-fixed-top-desktop, html.has-navbar-fixed-top-desktop {
    padding-top: 3.25rem;
  }

  body.has-navbar-fixed-bottom-desktop, html.has-navbar-fixed-bottom-desktop {
    padding-bottom: 3.25rem;
  }

  body.has-spaced-navbar-fixed-top, html.has-spaced-navbar-fixed-top {
    padding-top: 5.25rem;
  }

  body.has-spaced-navbar-fixed-bottom, html.has-spaced-navbar-fixed-bottom {
    padding-bottom: 5.25rem;
  }

  .navbar-link.is-active, a.navbar-item.is-active {
    color: #158cba;
  }

  .navbar-link.is-active:not(:focus):not(:hover), a.navbar-item.is-active:not(:focus):not(:hover) {
    background-color: #0000;
  }

  .navbar-item.has-dropdown.is-active .navbar-link, .navbar-item.has-dropdown:focus .navbar-link, .navbar-item.has-dropdown:hover .navbar-link {
    background-color: #fafafa;
  }
}

.hero.is-fullheight-with-navbar {
  min-height: calc(100vh - 3.25rem);
}

.pagination {
  margin: -.25rem;
  font-size: 1rem;
}

.pagination.is-small {
  font-size: .75rem;
}

.pagination.is-medium {
  font-size: 1.25rem;
}

.pagination.is-large {
  font-size: 1.5rem;
}

.pagination.is-rounded .pagination-next, .pagination.is-rounded .pagination-previous {
  border-radius: 290486px;
  padding-left: 1em;
  padding-right: 1em;
}

.pagination.is-rounded .pagination-link {
  border-radius: 290486px;
}

.pagination, .pagination-list {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pagination-ellipsis, .pagination-link, .pagination-next, .pagination-previous {
  text-align: center;
  justify-content: center;
  margin: .25rem;
  font-size: 1em;
}

.pagination-ellipsis, .pagination-link {
  padding-left: .5em;
  padding-right: .5em;
}

.pagination-link, .pagination-next, .pagination-previous {
  color: #363636;
  min-width: 2.25em;
  border-color: #dbdbdb;
}

.pagination-link:hover, .pagination-next:hover, .pagination-previous:hover {
  color: #363636;
  border-color: #b5b5b5;
}

.pagination-link:focus, .pagination-next:focus, .pagination-previous:focus {
  border-color: #5bb7db;
}

.pagination-link:active, .pagination-next:active, .pagination-previous:active {
  box-shadow: inset 0 1px 2px #0a0a0a33;
}

.pagination-link[disabled], .pagination-next[disabled], .pagination-previous[disabled] {
  box-shadow: none;
  color: #7a7a7a;
  opacity: .5;
  background-color: #dbdbdb;
  border-color: #dbdbdb;
}

.pagination-next, .pagination-previous {
  white-space: nowrap;
  padding-left: .75em;
  padding-right: .75em;
}

.pagination-link.is-current {
  color: #fff;
  background-color: #5bb7db;
  border-color: #5bb7db;
}

.pagination-ellipsis {
  color: #b5b5b5;
  pointer-events: none;
}

.pagination-list {
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .pagination {
    flex-wrap: wrap;
  }

  .pagination-list li, .pagination-next, .pagination-previous {
    flex-grow: 1;
    flex-shrink: 1;
  }
}

@media screen and (min-width: 769px), print {
  .pagination-list {
    flex-grow: 1;
    flex-shrink: 1;
    order: 1;
    justify-content: flex-start;
  }

  .pagination-previous {
    order: 2;
  }

  .pagination-next {
    order: 3;
  }

  .pagination {
    justify-content: space-between;
  }

  .pagination.is-centered .pagination-previous {
    order: 1;
  }

  .pagination.is-centered .pagination-list {
    order: 2;
    justify-content: center;
  }

  .pagination.is-centered .pagination-next {
    order: 3;
  }

  .pagination.is-right .pagination-previous {
    order: 1;
  }

  .pagination.is-right .pagination-next {
    order: 2;
  }

  .pagination.is-right .pagination-list {
    order: 3;
    justify-content: flex-end;
  }
}

.panel {
  font-size: 1rem;
}

.panel:not(:last-child) {
  margin-bottom: 1.5rem;
}

.panel-block, .panel-heading, .panel-tabs {
  border-bottom: 1px solid #dbdbdb;
  border-left: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
}

.panel-block:first-child, .panel-heading:first-child, .panel-tabs:first-child {
  border-top: 1px solid #dbdbdb;
}

.panel-heading {
  color: #363636;
  background-color: #f5f5f5;
  border-radius: 4px 4px 0 0;
  padding: .5em .75em;
  font-size: 1.25em;
  font-weight: 300;
  line-height: 1.25;
}

.panel-tabs {
  justify-content: center;
  align-items: flex-end;
  font-size: .875em;
  display: flex;
}

.panel-tabs a {
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: -1px;
  padding: .5em;
}

.panel-tabs a.is-active {
  color: #363636;
  border-bottom-color: #4a4a4a;
}

.panel-list a {
  color: #4a4a4a;
}

.panel-block.is-active .panel-icon, .panel-list a:hover {
  color: #5bb7db;
}

.panel-block {
  color: #363636;
  justify-content: flex-start;
  align-items: center;
  padding: .5em .75em;
  display: flex;
}

.panel-block input[type="checkbox"], .panel-icon {
  margin-right: .75em;
}

.panel-block > .control {
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
}

.panel-block.is-wrapped {
  flex-wrap: wrap;
}

.panel-block.is-active {
  color: #363636;
  border-left-color: #5bb7db;
}

a.panel-block, label.panel-block {
  cursor: pointer;
}

a.panel-block:hover, label.panel-block:hover {
  background-color: #f5f5f5;
}

.panel-icon {
  height: 1em;
  text-align: center;
  vertical-align: top;
  width: 1em;
  color: #7a7a7a;
  font-size: 14px;
  line-height: 1em;
  display: inline-block;
}

.panel-icon .fa {
  font-size: inherit;
  line-height: inherit;
}

.tabs {
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  justify-content: space-between;
  align-items: stretch;
  font-size: 1rem;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.tabs a, .tabs ul {
  border-bottom: 1px solid #dbdbdb;
  align-items: center;
  display: flex;
}

.tabs a {
  color: #4a4a4a;
  vertical-align: top;
  justify-content: center;
  margin-bottom: -1px;
  padding: .5em 1em;
}

.tabs a:hover {
  color: #363636;
  border-bottom-color: #363636;
}

.tabs li {
  display: block;
}

.tabs li.is-active a {
  color: #5bb7db;
  border-bottom-color: #5bb7db;
}

.tabs ul {
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-start;
}

.tabs ul.is-center, .tabs ul.is-left {
  padding-right: .75em;
}

.tabs ul.is-center {
  flex: none;
  justify-content: center;
  padding-left: .75em;
}

.tabs ul.is-right {
  justify-content: flex-end;
  padding-left: .75em;
}

.tabs .icon:first-child {
  margin-right: .5em;
}

.tabs .icon:last-child {
  margin-left: .5em;
}

.tabs.is-centered ul {
  justify-content: center;
}

.tabs.is-right ul {
  justify-content: flex-end;
}

.tabs.is-boxed a {
  border: 1px solid #0000;
  border-radius: 4px 4px 0 0;
}

.tabs.is-boxed a:hover {
  background-color: #f5f5f5;
  border-bottom-color: #dbdbdb;
}

.tabs.is-boxed li.is-active a {
  background-color: #fff;
  border-color: #dbdbdb;
  border-bottom-color: #0000 !important;
}

.tabs.is-toggle a {
  border: 1px solid #dbdbdb;
  margin-bottom: 0;
  position: relative;
}

.tabs.is-toggle a:hover {
  z-index: 2;
  background-color: #f5f5f5;
  border-color: #b5b5b5;
}

.tabs.is-toggle li + li {
  margin-left: -1px;
}

.tabs.is-toggle li:first-child a {
  border-radius: 4px 0 0 4px;
}

.tabs.is-toggle li:last-child a {
  border-radius: 0 4px 4px 0;
}

.tabs.is-toggle li.is-active a {
  color: #fff;
  z-index: 1;
  background-color: #5bb7db;
  border-color: #5bb7db;
}

.hero .tabs ul, .tabs.is-toggle ul {
  border-bottom: none;
}

.tabs.is-toggle.is-toggle-rounded li:first-child a {
  border-top-left-radius: 290486px;
  border-bottom-left-radius: 290486px;
  padding-left: 1.25em;
}

.tabs.is-toggle.is-toggle-rounded li:last-child a {
  border-top-right-radius: 290486px;
  border-bottom-right-radius: 290486px;
  padding-right: 1.25em;
}

.tabs.is-small {
  font-size: .75rem;
}

.tabs.is-medium {
  font-size: 1.25rem;
}

.tabs.is-large {
  font-size: 1.5rem;
}

.column {
  flex: 1;
  padding: .75rem;
  display: block;
}

.columns.is-mobile > .column.is-narrow {
  flex: none;
}

.columns.is-mobile > .column.is-full {
  width: 100%;
  flex: none;
}

.columns.is-mobile > .column.is-three-quarters {
  width: 75%;
  flex: none;
}

.columns.is-mobile > .column.is-two-thirds {
  width: 66.6666%;
  flex: none;
}

.columns.is-mobile > .column.is-half {
  width: 50%;
  flex: none;
}

.columns.is-mobile > .column.is-one-third {
  width: 33.3333%;
  flex: none;
}

.columns.is-mobile > .column.is-one-quarter {
  width: 25%;
  flex: none;
}

.columns.is-mobile > .column.is-one-fifth {
  width: 20%;
  flex: none;
}

.columns.is-mobile > .column.is-two-fifths {
  width: 40%;
  flex: none;
}

.columns.is-mobile > .column.is-three-fifths {
  width: 60%;
  flex: none;
}

.columns.is-mobile > .column.is-four-fifths {
  width: 80%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-three-quarters {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-offset-two-thirds {
  margin-left: 66.6666%;
}

.columns.is-mobile > .column.is-offset-half {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-offset-one-third {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-offset-one-quarter {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-offset-one-fifth {
  margin-left: 20%;
}

.columns.is-mobile > .column.is-offset-two-fifths {
  margin-left: 40%;
}

.columns.is-mobile > .column.is-offset-three-fifths {
  margin-left: 60%;
}

.columns.is-mobile > .column.is-offset-four-fifths {
  margin-left: 80%;
}

.columns.is-mobile > .column.is-0 {
  width: 0%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-0 {
  margin-left: 0;
}

.columns.is-mobile > .column.is-1 {
  width: 8.33333%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-1 {
  margin-left: 8.33333%;
}

.columns.is-mobile > .column.is-2 {
  width: 16.6667%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-2 {
  margin-left: 16.6667%;
}

.columns.is-mobile > .column.is-3 {
  width: 25%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-3 {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-4 {
  width: 33.3333%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-4 {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-5 {
  width: 41.6667%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-5 {
  margin-left: 41.6667%;
}

.columns.is-mobile > .column.is-6 {
  width: 50%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-6 {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-7 {
  width: 58.3333%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-7 {
  margin-left: 58.3333%;
}

.columns.is-mobile > .column.is-8 {
  width: 66.6667%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-8 {
  margin-left: 66.6667%;
}

.columns.is-mobile > .column.is-9 {
  width: 75%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-9 {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-10 {
  width: 83.3333%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-10 {
  margin-left: 83.3333%;
}

.columns.is-mobile > .column.is-11 {
  width: 91.6667%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-11 {
  margin-left: 91.6667%;
}

.columns.is-mobile > .column.is-12 {
  width: 100%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-12 {
  margin-left: 100%;
}

@media screen and (max-width: 768px) {
  .column.is-narrow-mobile {
    flex: none;
  }

  .column.is-full-mobile {
    width: 100%;
    flex: none;
  }

  .column.is-three-quarters-mobile {
    width: 75%;
    flex: none;
  }

  .column.is-two-thirds-mobile {
    width: 66.6666%;
    flex: none;
  }

  .column.is-half-mobile {
    width: 50%;
    flex: none;
  }

  .column.is-one-third-mobile {
    width: 33.3333%;
    flex: none;
  }

  .column.is-one-quarter-mobile {
    width: 25%;
    flex: none;
  }

  .column.is-one-fifth-mobile {
    width: 20%;
    flex: none;
  }

  .column.is-two-fifths-mobile {
    width: 40%;
    flex: none;
  }

  .column.is-three-fifths-mobile {
    width: 60%;
    flex: none;
  }

  .column.is-four-fifths-mobile {
    width: 80%;
    flex: none;
  }

  .column.is-offset-three-quarters-mobile {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-mobile {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-mobile {
    margin-left: 50%;
  }

  .column.is-offset-one-third-mobile {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-mobile {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-mobile {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-mobile {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-mobile {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-mobile {
    margin-left: 80%;
  }

  .column.is-0-mobile {
    width: 0%;
    flex: none;
  }

  .column.is-offset-0-mobile {
    margin-left: 0;
  }

  .column.is-1-mobile {
    width: 8.33333%;
    flex: none;
  }

  .column.is-offset-1-mobile {
    margin-left: 8.33333%;
  }

  .column.is-2-mobile {
    width: 16.6667%;
    flex: none;
  }

  .column.is-offset-2-mobile {
    margin-left: 16.6667%;
  }

  .column.is-3-mobile {
    width: 25%;
    flex: none;
  }

  .column.is-offset-3-mobile {
    margin-left: 25%;
  }

  .column.is-4-mobile {
    width: 33.3333%;
    flex: none;
  }

  .column.is-offset-4-mobile {
    margin-left: 33.3333%;
  }

  .column.is-5-mobile {
    width: 41.6667%;
    flex: none;
  }

  .column.is-offset-5-mobile {
    margin-left: 41.6667%;
  }

  .column.is-6-mobile {
    width: 50%;
    flex: none;
  }

  .column.is-offset-6-mobile {
    margin-left: 50%;
  }

  .column.is-7-mobile {
    width: 58.3333%;
    flex: none;
  }

  .column.is-offset-7-mobile {
    margin-left: 58.3333%;
  }

  .column.is-8-mobile {
    width: 66.6667%;
    flex: none;
  }

  .column.is-offset-8-mobile {
    margin-left: 66.6667%;
  }

  .column.is-9-mobile {
    width: 75%;
    flex: none;
  }

  .column.is-offset-9-mobile {
    margin-left: 75%;
  }

  .column.is-10-mobile {
    width: 83.3333%;
    flex: none;
  }

  .column.is-offset-10-mobile {
    margin-left: 83.3333%;
  }

  .column.is-11-mobile {
    width: 91.6667%;
    flex: none;
  }

  .column.is-offset-11-mobile {
    margin-left: 91.6667%;
  }

  .column.is-12-mobile {
    width: 100%;
    flex: none;
  }

  .column.is-offset-12-mobile {
    margin-left: 100%;
  }
}

@media screen and (min-width: 769px), print {
  .column.is-narrow, .column.is-narrow-tablet {
    flex: none;
  }

  .column.is-full, .column.is-full-tablet {
    width: 100%;
    flex: none;
  }

  .column.is-three-quarters, .column.is-three-quarters-tablet {
    width: 75%;
    flex: none;
  }

  .column.is-two-thirds, .column.is-two-thirds-tablet {
    width: 66.6666%;
    flex: none;
  }

  .column.is-half, .column.is-half-tablet {
    width: 50%;
    flex: none;
  }

  .column.is-one-third, .column.is-one-third-tablet {
    width: 33.3333%;
    flex: none;
  }

  .column.is-one-quarter, .column.is-one-quarter-tablet {
    width: 25%;
    flex: none;
  }

  .column.is-one-fifth, .column.is-one-fifth-tablet {
    width: 20%;
    flex: none;
  }

  .column.is-two-fifths, .column.is-two-fifths-tablet {
    width: 40%;
    flex: none;
  }

  .column.is-three-fifths, .column.is-three-fifths-tablet {
    width: 60%;
    flex: none;
  }

  .column.is-four-fifths, .column.is-four-fifths-tablet {
    width: 80%;
    flex: none;
  }

  .column.is-offset-three-quarters, .column.is-offset-three-quarters-tablet {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds, .column.is-offset-two-thirds-tablet {
    margin-left: 66.6666%;
  }

  .column.is-offset-half, .column.is-offset-half-tablet {
    margin-left: 50%;
  }

  .column.is-offset-one-third, .column.is-offset-one-third-tablet {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter, .column.is-offset-one-quarter-tablet {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth, .column.is-offset-one-fifth-tablet {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths, .column.is-offset-two-fifths-tablet {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths, .column.is-offset-three-fifths-tablet {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths, .column.is-offset-four-fifths-tablet {
    margin-left: 80%;
  }

  .column.is-0, .column.is-0-tablet {
    width: 0%;
    flex: none;
  }

  .column.is-offset-0, .column.is-offset-0-tablet {
    margin-left: 0;
  }

  .column.is-1, .column.is-1-tablet {
    width: 8.33333%;
    flex: none;
  }

  .column.is-offset-1, .column.is-offset-1-tablet {
    margin-left: 8.33333%;
  }

  .column.is-2, .column.is-2-tablet {
    width: 16.6667%;
    flex: none;
  }

  .column.is-offset-2, .column.is-offset-2-tablet {
    margin-left: 16.6667%;
  }

  .column.is-3, .column.is-3-tablet {
    width: 25%;
    flex: none;
  }

  .column.is-offset-3, .column.is-offset-3-tablet {
    margin-left: 25%;
  }

  .column.is-4, .column.is-4-tablet {
    width: 33.3333%;
    flex: none;
  }

  .column.is-offset-4, .column.is-offset-4-tablet {
    margin-left: 33.3333%;
  }

  .column.is-5, .column.is-5-tablet {
    width: 41.6667%;
    flex: none;
  }

  .column.is-offset-5, .column.is-offset-5-tablet {
    margin-left: 41.6667%;
  }

  .column.is-6, .column.is-6-tablet {
    width: 50%;
    flex: none;
  }

  .column.is-offset-6, .column.is-offset-6-tablet {
    margin-left: 50%;
  }

  .column.is-7, .column.is-7-tablet {
    width: 58.3333%;
    flex: none;
  }

  .column.is-offset-7, .column.is-offset-7-tablet {
    margin-left: 58.3333%;
  }

  .column.is-8, .column.is-8-tablet {
    width: 66.6667%;
    flex: none;
  }

  .column.is-offset-8, .column.is-offset-8-tablet {
    margin-left: 66.6667%;
  }

  .column.is-9, .column.is-9-tablet {
    width: 75%;
    flex: none;
  }

  .column.is-offset-9, .column.is-offset-9-tablet {
    margin-left: 75%;
  }

  .column.is-10, .column.is-10-tablet {
    width: 83.3333%;
    flex: none;
  }

  .column.is-offset-10, .column.is-offset-10-tablet {
    margin-left: 83.3333%;
  }

  .column.is-11, .column.is-11-tablet {
    width: 91.6667%;
    flex: none;
  }

  .column.is-offset-11, .column.is-offset-11-tablet {
    margin-left: 91.6667%;
  }

  .column.is-12, .column.is-12-tablet {
    width: 100%;
    flex: none;
  }

  .column.is-offset-12, .column.is-offset-12-tablet {
    margin-left: 100%;
  }
}

@media screen and (max-width: 1023px) {
  .column.is-narrow-touch {
    flex: none;
  }

  .column.is-full-touch {
    width: 100%;
    flex: none;
  }

  .column.is-three-quarters-touch {
    width: 75%;
    flex: none;
  }

  .column.is-two-thirds-touch {
    width: 66.6666%;
    flex: none;
  }

  .column.is-half-touch {
    width: 50%;
    flex: none;
  }

  .column.is-one-third-touch {
    width: 33.3333%;
    flex: none;
  }

  .column.is-one-quarter-touch {
    width: 25%;
    flex: none;
  }

  .column.is-one-fifth-touch {
    width: 20%;
    flex: none;
  }

  .column.is-two-fifths-touch {
    width: 40%;
    flex: none;
  }

  .column.is-three-fifths-touch {
    width: 60%;
    flex: none;
  }

  .column.is-four-fifths-touch {
    width: 80%;
    flex: none;
  }

  .column.is-offset-three-quarters-touch {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-touch {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-touch {
    margin-left: 50%;
  }

  .column.is-offset-one-third-touch {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-touch {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-touch {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-touch {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-touch {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-touch {
    margin-left: 80%;
  }

  .column.is-0-touch {
    width: 0%;
    flex: none;
  }

  .column.is-offset-0-touch {
    margin-left: 0;
  }

  .column.is-1-touch {
    width: 8.33333%;
    flex: none;
  }

  .column.is-offset-1-touch {
    margin-left: 8.33333%;
  }

  .column.is-2-touch {
    width: 16.6667%;
    flex: none;
  }

  .column.is-offset-2-touch {
    margin-left: 16.6667%;
  }

  .column.is-3-touch {
    width: 25%;
    flex: none;
  }

  .column.is-offset-3-touch {
    margin-left: 25%;
  }

  .column.is-4-touch {
    width: 33.3333%;
    flex: none;
  }

  .column.is-offset-4-touch {
    margin-left: 33.3333%;
  }

  .column.is-5-touch {
    width: 41.6667%;
    flex: none;
  }

  .column.is-offset-5-touch {
    margin-left: 41.6667%;
  }

  .column.is-6-touch {
    width: 50%;
    flex: none;
  }

  .column.is-offset-6-touch {
    margin-left: 50%;
  }

  .column.is-7-touch {
    width: 58.3333%;
    flex: none;
  }

  .column.is-offset-7-touch {
    margin-left: 58.3333%;
  }

  .column.is-8-touch {
    width: 66.6667%;
    flex: none;
  }

  .column.is-offset-8-touch {
    margin-left: 66.6667%;
  }

  .column.is-9-touch {
    width: 75%;
    flex: none;
  }

  .column.is-offset-9-touch {
    margin-left: 75%;
  }

  .column.is-10-touch {
    width: 83.3333%;
    flex: none;
  }

  .column.is-offset-10-touch {
    margin-left: 83.3333%;
  }

  .column.is-11-touch {
    width: 91.6667%;
    flex: none;
  }

  .column.is-offset-11-touch {
    margin-left: 91.6667%;
  }

  .column.is-12-touch {
    width: 100%;
    flex: none;
  }

  .column.is-offset-12-touch {
    margin-left: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .column.is-narrow-desktop {
    flex: none;
  }

  .column.is-full-desktop {
    width: 100%;
    flex: none;
  }

  .column.is-three-quarters-desktop {
    width: 75%;
    flex: none;
  }

  .column.is-two-thirds-desktop {
    width: 66.6666%;
    flex: none;
  }

  .column.is-half-desktop {
    width: 50%;
    flex: none;
  }

  .column.is-one-third-desktop {
    width: 33.3333%;
    flex: none;
  }

  .column.is-one-quarter-desktop {
    width: 25%;
    flex: none;
  }

  .column.is-one-fifth-desktop {
    width: 20%;
    flex: none;
  }

  .column.is-two-fifths-desktop {
    width: 40%;
    flex: none;
  }

  .column.is-three-fifths-desktop {
    width: 60%;
    flex: none;
  }

  .column.is-four-fifths-desktop {
    width: 80%;
    flex: none;
  }

  .column.is-offset-three-quarters-desktop {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-desktop {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-desktop {
    margin-left: 50%;
  }

  .column.is-offset-one-third-desktop {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-desktop {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-desktop {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-desktop {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-desktop {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-desktop {
    margin-left: 80%;
  }

  .column.is-0-desktop {
    width: 0%;
    flex: none;
  }

  .column.is-offset-0-desktop {
    margin-left: 0;
  }

  .column.is-1-desktop {
    width: 8.33333%;
    flex: none;
  }

  .column.is-offset-1-desktop {
    margin-left: 8.33333%;
  }

  .column.is-2-desktop {
    width: 16.6667%;
    flex: none;
  }

  .column.is-offset-2-desktop {
    margin-left: 16.6667%;
  }

  .column.is-3-desktop {
    width: 25%;
    flex: none;
  }

  .column.is-offset-3-desktop {
    margin-left: 25%;
  }

  .column.is-4-desktop {
    width: 33.3333%;
    flex: none;
  }

  .column.is-offset-4-desktop {
    margin-left: 33.3333%;
  }

  .column.is-5-desktop {
    width: 41.6667%;
    flex: none;
  }

  .column.is-offset-5-desktop {
    margin-left: 41.6667%;
  }

  .column.is-6-desktop {
    width: 50%;
    flex: none;
  }

  .column.is-offset-6-desktop {
    margin-left: 50%;
  }

  .column.is-7-desktop {
    width: 58.3333%;
    flex: none;
  }

  .column.is-offset-7-desktop {
    margin-left: 58.3333%;
  }

  .column.is-8-desktop {
    width: 66.6667%;
    flex: none;
  }

  .column.is-offset-8-desktop {
    margin-left: 66.6667%;
  }

  .column.is-9-desktop {
    width: 75%;
    flex: none;
  }

  .column.is-offset-9-desktop {
    margin-left: 75%;
  }

  .column.is-10-desktop {
    width: 83.3333%;
    flex: none;
  }

  .column.is-offset-10-desktop {
    margin-left: 83.3333%;
  }

  .column.is-11-desktop {
    width: 91.6667%;
    flex: none;
  }

  .column.is-offset-11-desktop {
    margin-left: 91.6667%;
  }

  .column.is-12-desktop {
    width: 100%;
    flex: none;
  }

  .column.is-offset-12-desktop {
    margin-left: 100%;
  }
}

@media screen and (min-width: 1216px) {
  .column.is-narrow-widescreen {
    flex: none;
  }

  .column.is-full-widescreen {
    width: 100%;
    flex: none;
  }

  .column.is-three-quarters-widescreen {
    width: 75%;
    flex: none;
  }

  .column.is-two-thirds-widescreen {
    width: 66.6666%;
    flex: none;
  }

  .column.is-half-widescreen {
    width: 50%;
    flex: none;
  }

  .column.is-one-third-widescreen {
    width: 33.3333%;
    flex: none;
  }

  .column.is-one-quarter-widescreen {
    width: 25%;
    flex: none;
  }

  .column.is-one-fifth-widescreen {
    width: 20%;
    flex: none;
  }

  .column.is-two-fifths-widescreen {
    width: 40%;
    flex: none;
  }

  .column.is-three-fifths-widescreen {
    width: 60%;
    flex: none;
  }

  .column.is-four-fifths-widescreen {
    width: 80%;
    flex: none;
  }

  .column.is-offset-three-quarters-widescreen {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-widescreen {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-widescreen {
    margin-left: 50%;
  }

  .column.is-offset-one-third-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-widescreen {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-widescreen {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-widescreen {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-widescreen {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-widescreen {
    margin-left: 80%;
  }

  .column.is-0-widescreen {
    width: 0%;
    flex: none;
  }

  .column.is-offset-0-widescreen {
    margin-left: 0;
  }

  .column.is-1-widescreen {
    width: 8.33333%;
    flex: none;
  }

  .column.is-offset-1-widescreen {
    margin-left: 8.33333%;
  }

  .column.is-2-widescreen {
    width: 16.6667%;
    flex: none;
  }

  .column.is-offset-2-widescreen {
    margin-left: 16.6667%;
  }

  .column.is-3-widescreen {
    width: 25%;
    flex: none;
  }

  .column.is-offset-3-widescreen {
    margin-left: 25%;
  }

  .column.is-4-widescreen {
    width: 33.3333%;
    flex: none;
  }

  .column.is-offset-4-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-5-widescreen {
    width: 41.6667%;
    flex: none;
  }

  .column.is-offset-5-widescreen {
    margin-left: 41.6667%;
  }

  .column.is-6-widescreen {
    width: 50%;
    flex: none;
  }

  .column.is-offset-6-widescreen {
    margin-left: 50%;
  }

  .column.is-7-widescreen {
    width: 58.3333%;
    flex: none;
  }

  .column.is-offset-7-widescreen {
    margin-left: 58.3333%;
  }

  .column.is-8-widescreen {
    width: 66.6667%;
    flex: none;
  }

  .column.is-offset-8-widescreen {
    margin-left: 66.6667%;
  }

  .column.is-9-widescreen {
    width: 75%;
    flex: none;
  }

  .column.is-offset-9-widescreen {
    margin-left: 75%;
  }

  .column.is-10-widescreen {
    width: 83.3333%;
    flex: none;
  }

  .column.is-offset-10-widescreen {
    margin-left: 83.3333%;
  }

  .column.is-11-widescreen {
    width: 91.6667%;
    flex: none;
  }

  .column.is-offset-11-widescreen {
    margin-left: 91.6667%;
  }

  .column.is-12-widescreen {
    width: 100%;
    flex: none;
  }

  .column.is-offset-12-widescreen {
    margin-left: 100%;
  }
}

@media screen and (min-width: 1408px) {
  .column.is-narrow-fullhd {
    flex: none;
  }

  .column.is-full-fullhd {
    width: 100%;
    flex: none;
  }

  .column.is-three-quarters-fullhd {
    width: 75%;
    flex: none;
  }

  .column.is-two-thirds-fullhd {
    width: 66.6666%;
    flex: none;
  }

  .column.is-half-fullhd {
    width: 50%;
    flex: none;
  }

  .column.is-one-third-fullhd {
    width: 33.3333%;
    flex: none;
  }

  .column.is-one-quarter-fullhd {
    width: 25%;
    flex: none;
  }

  .column.is-one-fifth-fullhd {
    width: 20%;
    flex: none;
  }

  .column.is-two-fifths-fullhd {
    width: 40%;
    flex: none;
  }

  .column.is-three-fifths-fullhd {
    width: 60%;
    flex: none;
  }

  .column.is-four-fifths-fullhd {
    width: 80%;
    flex: none;
  }

  .column.is-offset-three-quarters-fullhd {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-fullhd {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-fullhd {
    margin-left: 50%;
  }

  .column.is-offset-one-third-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-fullhd {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-fullhd {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-fullhd {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-fullhd {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-fullhd {
    margin-left: 80%;
  }

  .column.is-0-fullhd {
    width: 0%;
    flex: none;
  }

  .column.is-offset-0-fullhd {
    margin-left: 0;
  }

  .column.is-1-fullhd {
    width: 8.33333%;
    flex: none;
  }

  .column.is-offset-1-fullhd {
    margin-left: 8.33333%;
  }

  .column.is-2-fullhd {
    width: 16.6667%;
    flex: none;
  }

  .column.is-offset-2-fullhd {
    margin-left: 16.6667%;
  }

  .column.is-3-fullhd {
    width: 25%;
    flex: none;
  }

  .column.is-offset-3-fullhd {
    margin-left: 25%;
  }

  .column.is-4-fullhd {
    width: 33.3333%;
    flex: none;
  }

  .column.is-offset-4-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-5-fullhd {
    width: 41.6667%;
    flex: none;
  }

  .column.is-offset-5-fullhd {
    margin-left: 41.6667%;
  }

  .column.is-6-fullhd {
    width: 50%;
    flex: none;
  }

  .column.is-offset-6-fullhd {
    margin-left: 50%;
  }

  .column.is-7-fullhd {
    width: 58.3333%;
    flex: none;
  }

  .column.is-offset-7-fullhd {
    margin-left: 58.3333%;
  }

  .column.is-8-fullhd {
    width: 66.6667%;
    flex: none;
  }

  .column.is-offset-8-fullhd {
    margin-left: 66.6667%;
  }

  .column.is-9-fullhd {
    width: 75%;
    flex: none;
  }

  .column.is-offset-9-fullhd {
    margin-left: 75%;
  }

  .column.is-10-fullhd {
    width: 83.3333%;
    flex: none;
  }

  .column.is-offset-10-fullhd {
    margin-left: 83.3333%;
  }

  .column.is-11-fullhd {
    width: 91.6667%;
    flex: none;
  }

  .column.is-offset-11-fullhd {
    margin-left: 91.6667%;
  }

  .column.is-12-fullhd {
    width: 100%;
    flex: none;
  }

  .column.is-offset-12-fullhd {
    margin-left: 100%;
  }
}

.columns {
  margin-top: -.75rem;
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.columns:last-child {
  margin-bottom: -.75rem;
}

.columns:not(:last-child) {
  margin-bottom: .75rem;
}

.columns.is-centered {
  justify-content: center;
}

.columns.is-gapless {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.columns.is-gapless > .column {
  margin: 0;
  padding: 0 !important;
}

.columns.is-gapless:not(:last-child) {
  margin-bottom: 1.5rem;
}

.columns.is-gapless:last-child {
  margin-bottom: 0;
}

.columns.is-mobile {
  display: flex;
}

.columns.is-multiline {
  flex-wrap: wrap;
}

.columns.is-vcentered {
  align-items: center;
}

@media screen and (min-width: 769px), print {
  .columns:not(.is-desktop) {
    display: flex;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-desktop {
    display: flex;
  }
}

.columns.is-variable {
  --columnGap: .75rem;
  margin-left: calc(-1 * var(--columnGap) );
  margin-right: calc(-1 * var(--columnGap) );
}

.columns.is-variable .column {
  padding-left: var(--columnGap);
  padding-right: var(--columnGap);
}

.columns.is-variable.is-0 {
  --columnGap: 0rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-0-mobile {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-0-tablet {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-0-tablet-only {
    --columnGap: 0rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-0-touch {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-0-desktop {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-0-desktop-only {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-0-widescreen {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-0-widescreen-only {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-0-fullhd {
    --columnGap: 0rem;
  }
}

.columns.is-variable.is-1 {
  --columnGap: .25rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-1-mobile {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-1-tablet {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-1-tablet-only {
    --columnGap: .25rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-1-touch {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-1-desktop {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-1-desktop-only {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-1-widescreen {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-1-widescreen-only {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-1-fullhd {
    --columnGap: .25rem;
  }
}

.columns.is-variable.is-2 {
  --columnGap: .5rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-2-mobile {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-2-tablet {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-2-tablet-only {
    --columnGap: .5rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-2-touch {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-2-desktop {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-2-desktop-only {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-2-widescreen {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-2-widescreen-only {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-2-fullhd {
    --columnGap: .5rem;
  }
}

.columns.is-variable.is-3 {
  --columnGap: .75rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-3-mobile {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-3-tablet {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-3-tablet-only {
    --columnGap: .75rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-3-touch {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-3-desktop {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-3-desktop-only {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-3-widescreen {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-3-widescreen-only {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-3-fullhd {
    --columnGap: .75rem;
  }
}

.columns.is-variable.is-4 {
  --columnGap: 1rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-4-mobile {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-4-tablet {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-4-tablet-only {
    --columnGap: 1rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-4-touch {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-4-desktop {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-4-desktop-only {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-4-widescreen {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-4-widescreen-only {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-4-fullhd {
    --columnGap: 1rem;
  }
}

.columns.is-variable.is-5 {
  --columnGap: 1.25rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-5-mobile {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-5-tablet {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-5-tablet-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-5-touch {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-5-desktop {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-5-desktop-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-5-widescreen {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-5-widescreen-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-5-fullhd {
    --columnGap: 1.25rem;
  }
}

.columns.is-variable.is-6 {
  --columnGap: 1.5rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-6-mobile {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-6-tablet {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-6-tablet-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-6-touch {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-6-desktop {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-6-desktop-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-6-widescreen {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-6-widescreen-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-6-fullhd {
    --columnGap: 1.5rem;
  }
}

.columns.is-variable.is-7 {
  --columnGap: 1.75rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-7-mobile {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-7-tablet {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-7-tablet-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-7-touch {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-7-desktop {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-7-desktop-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-7-widescreen {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-7-widescreen-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-7-fullhd {
    --columnGap: 1.75rem;
  }
}

.columns.is-variable.is-8 {
  --columnGap: 2rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-8-mobile {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-8-tablet {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-8-tablet-only {
    --columnGap: 2rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-8-touch {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-8-desktop {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-8-desktop-only {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-8-widescreen {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-8-widescreen-only {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-8-fullhd {
    --columnGap: 2rem;
  }
}

.tile {
  min-height: -webkit-min-content;
  min-height: -moz-min-content;
  min-height: min-content;
  flex: 1;
  align-items: stretch;
  display: block;
}

.tile.is-ancestor {
  margin-top: -.75rem;
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.tile.is-ancestor:last-child {
  margin-bottom: -.75rem;
}

.tile.is-ancestor:not(:last-child) {
  margin-bottom: .75rem;
}

.tile.is-child {
  margin: 0 !important;
}

.tile.is-parent {
  padding: .75rem;
}

.tile.is-vertical {
  flex-direction: column;
}

.tile.is-vertical > .tile.is-child:not(:last-child) {
  margin-bottom: 1.5rem !important;
}

@media screen and (min-width: 769px), print {
  .tile:not(.is-child) {
    display: flex;
  }

  .tile.is-1 {
    width: 8.33333%;
    flex: none;
  }

  .tile.is-2 {
    width: 16.6667%;
    flex: none;
  }

  .tile.is-3 {
    width: 25%;
    flex: none;
  }

  .tile.is-4 {
    width: 33.3333%;
    flex: none;
  }

  .tile.is-5 {
    width: 41.6667%;
    flex: none;
  }

  .tile.is-6 {
    width: 50%;
    flex: none;
  }

  .tile.is-7 {
    width: 58.3333%;
    flex: none;
  }

  .tile.is-8 {
    width: 66.6667%;
    flex: none;
  }

  .tile.is-9 {
    width: 75%;
    flex: none;
  }

  .tile.is-10 {
    width: 83.3333%;
    flex: none;
  }

  .tile.is-11 {
    width: 91.6667%;
    flex: none;
  }

  .tile.is-12 {
    width: 100%;
    flex: none;
  }
}

.hero {
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.hero .navbar {
  background: none;
}

.hero.is-white {
  color: #0a0a0a;
  background-color: #fff;
}

.hero.is-black a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-black strong, .hero.is-danger a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-danger strong, .hero.is-dark a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-dark strong, .hero.is-info a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-info strong, .hero.is-light a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-light strong, .hero.is-link a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-link strong, .hero.is-primary a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-primary strong, .hero.is-success a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-success strong, .hero.is-warning a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-warning strong, .hero.is-white a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-white strong {
  color: inherit;
}

.hero.is-white .title {
  color: #0a0a0a;
}

.hero.is-white .subtitle {
  color: #0a0a0ae6;
}

.hero.is-white .subtitle a:not(.button), .hero.is-white .subtitle strong {
  color: #0a0a0a;
}

@media screen and (max-width: 1023px) {
  .hero.is-white .navbar-menu {
    background-color: #fff;
  }
}

.hero.is-white .navbar-item, .hero.is-white .navbar-link {
  color: #0a0a0ab3;
}

.hero.is-white .navbar-link.is-active, .hero.is-white .navbar-link:hover, .hero.is-white a.navbar-item.is-active, .hero.is-white a.navbar-item:hover {
  color: #0a0a0a;
  background-color: #f2f2f2;
}

.hero.is-white .tabs a {
  color: #0a0a0a;
  opacity: .9;
}

.hero.is-white .tabs a:hover, .hero.is-white .tabs li.is-active a {
  opacity: 1;
}

.hero.is-white .tabs.is-boxed a, .hero.is-white .tabs.is-toggle a {
  color: #0a0a0a;
}

.hero.is-black .tabs.is-boxed a:hover, .hero.is-black .tabs.is-toggle a:hover, .hero.is-danger .tabs.is-boxed a:hover, .hero.is-danger .tabs.is-toggle a:hover, .hero.is-dark .tabs.is-boxed a:hover, .hero.is-dark .tabs.is-toggle a:hover, .hero.is-info .tabs.is-boxed a:hover, .hero.is-info .tabs.is-toggle a:hover, .hero.is-light .tabs.is-boxed a:hover, .hero.is-light .tabs.is-toggle a:hover, .hero.is-link .tabs.is-boxed a:hover, .hero.is-link .tabs.is-toggle a:hover, .hero.is-primary .tabs.is-boxed a:hover, .hero.is-primary .tabs.is-toggle a:hover, .hero.is-success .tabs.is-boxed a:hover, .hero.is-success .tabs.is-toggle a:hover, .hero.is-warning .tabs.is-boxed a:hover, .hero.is-warning .tabs.is-toggle a:hover, .hero.is-white .tabs.is-boxed a:hover, .hero.is-white .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-white.is-bold {
  background-image: linear-gradient(141deg, #e6e6e6 0%, #fff 71% 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-white.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #e6e6e6 0%, #fff 71% 100%);
  }
}

.hero.is-black {
  color: #fff;
  background-color: #0a0a0a;
}

.hero.is-black .title {
  color: #fff;
}

.hero.is-black .subtitle {
  color: #ffffffe6;
}

.hero.is-black .subtitle a:not(.button), .hero.is-black .subtitle strong {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .hero.is-black .navbar-menu {
    background-color: #0a0a0a;
  }
}

.hero.is-black .navbar-item, .hero.is-black .navbar-link {
  color: #ffffffb3;
}

.hero.is-black .navbar-link.is-active, .hero.is-black .navbar-link:hover, .hero.is-black a.navbar-item.is-active, .hero.is-black a.navbar-item:hover {
  color: #fff;
  background-color: #000;
}

.hero.is-black .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-black .tabs a:hover, .hero.is-black .tabs li.is-active a {
  opacity: 1;
}

.hero.is-black .tabs.is-boxed a, .hero.is-black .tabs.is-toggle a {
  color: #fff;
}

.hero.is-black .tabs.is-boxed li.is-active a, .hero.is-black .tabs.is-boxed li.is-active a:hover, .hero.is-black .tabs.is-toggle li.is-active a, .hero.is-black .tabs.is-toggle li.is-active a:hover {
  color: #0a0a0a;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-black.is-bold {
  background-image: linear-gradient(141deg, #000 0%, #0a0a0a 71%, #181616 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-black.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #000 0%, #0a0a0a 71%, #181616 100%);
  }
}

.hero.is-light {
  color: #363636;
  background-color: #f5f5f5;
}

.hero.is-light .title {
  color: #363636;
}

.hero.is-light .subtitle {
  color: #363636e6;
}

.hero.is-light .subtitle a:not(.button), .hero.is-light .subtitle strong {
  color: #363636;
}

@media screen and (max-width: 1023px) {
  .hero.is-light .navbar-menu {
    background-color: #f5f5f5;
  }
}

.hero.is-light .navbar-item, .hero.is-light .navbar-link {
  color: #363636b3;
}

.hero.is-light .navbar-link.is-active, .hero.is-light .navbar-link:hover, .hero.is-light a.navbar-item.is-active, .hero.is-light a.navbar-item:hover {
  color: #363636;
  background-color: #e8e8e8;
}

.hero.is-light .tabs a {
  color: #363636;
  opacity: .9;
}

.hero.is-light .tabs a:hover, .hero.is-light .tabs li.is-active a {
  opacity: 1;
}

.hero.is-light .tabs.is-boxed a, .hero.is-light .tabs.is-toggle a {
  color: #363636;
}

.hero.is-light .tabs.is-boxed li.is-active a, .hero.is-light .tabs.is-boxed li.is-active a:hover, .hero.is-light .tabs.is-toggle li.is-active a, .hero.is-light .tabs.is-toggle li.is-active a:hover {
  color: #f5f5f5;
  background-color: #363636;
  border-color: #363636;
}

.hero.is-light.is-bold {
  background-image: linear-gradient(141deg, #dfd8d9 0%, #f5f5f5 71%, #fff 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-light.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #dfd8d9 0%, #f5f5f5 71%, #fff 100%);
  }
}

.hero.is-dark {
  color: #f5f5f5;
  background-color: #363636;
}

.hero.is-dark .title {
  color: #f5f5f5;
}

.hero.is-dark .subtitle {
  color: #f5f5f5e6;
}

.hero.is-dark .subtitle a:not(.button), .hero.is-dark .subtitle strong {
  color: #f5f5f5;
}

@media screen and (max-width: 1023px) {
  .hero.is-dark .navbar-menu {
    background-color: #363636;
  }
}

.hero.is-dark .navbar-item, .hero.is-dark .navbar-link {
  color: #f5f5f5b3;
}

.hero.is-dark .navbar-link.is-active, .hero.is-dark .navbar-link:hover, .hero.is-dark a.navbar-item.is-active, .hero.is-dark a.navbar-item:hover {
  color: #f5f5f5;
  background-color: #292929;
}

.hero.is-dark .tabs a {
  color: #f5f5f5;
  opacity: .9;
}

.hero.is-dark .tabs a:hover, .hero.is-dark .tabs li.is-active a {
  opacity: 1;
}

.hero.is-dark .tabs.is-boxed a, .hero.is-dark .tabs.is-toggle a {
  color: #f5f5f5;
}

.hero.is-dark .tabs.is-boxed li.is-active a, .hero.is-dark .tabs.is-boxed li.is-active a:hover, .hero.is-dark .tabs.is-toggle li.is-active a, .hero.is-dark .tabs.is-toggle li.is-active a:hover {
  color: #363636;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.hero.is-dark.is-bold {
  background-image: linear-gradient(141deg, #1f191a 0%, #363636 71%, #46403f 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-dark.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #1f191a 0%, #363636 71%, #46403f 100%);
  }
}

.hero.is-primary {
  color: #fff;
  background-color: #158cba;
}

.hero.is-primary .title {
  color: #fff;
}

.hero.is-primary .subtitle {
  color: #ffffffe6;
}

.hero.is-primary .subtitle a:not(.button), .hero.is-primary .subtitle strong {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .hero.is-primary .navbar-menu {
    background-color: #158cba;
  }
}

.hero.is-primary .navbar-item, .hero.is-primary .navbar-link {
  color: #ffffffb3;
}

.hero.is-primary .navbar-link.is-active, .hero.is-primary .navbar-link:hover, .hero.is-primary a.navbar-item.is-active, .hero.is-primary a.navbar-item:hover {
  color: #fff;
  background-color: #127ba3;
}

.hero.is-info .tabs a, .hero.is-link .tabs a, .hero.is-primary .tabs a, .hero.is-success .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-primary .tabs a:hover, .hero.is-primary .tabs li.is-active a {
  opacity: 1;
}

.hero.is-primary .tabs.is-boxed li.is-active a, .hero.is-primary .tabs.is-boxed li.is-active a:hover, .hero.is-primary .tabs.is-toggle li.is-active a, .hero.is-primary .tabs.is-toggle li.is-active a:hover {
  color: #158cba;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-primary.is-bold {
  background-image: linear-gradient(141deg, #088494 0%, #158cba 71%, #127fd7 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-primary.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #088494 0%, #158cba 71%, #127fd7 100%);
  }
}

.hero.is-link {
  color: #fff;
  background-color: #5bb7db;
}

.hero.is-link .title, .hero.is-primary .tabs.is-boxed a, .hero.is-primary .tabs.is-toggle a {
  color: #fff;
}

.hero.is-link .subtitle {
  color: #ffffffe6;
}

.hero.is-link .subtitle a:not(.button), .hero.is-link .subtitle strong {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .hero.is-link .navbar-menu {
    background-color: #5bb7db;
  }
}

.hero.is-link .navbar-item, .hero.is-link .navbar-link {
  color: #ffffffb3;
}

.hero.is-link .navbar-link.is-active, .hero.is-link .navbar-link:hover, .hero.is-link a.navbar-item.is-active, .hero.is-link a.navbar-item:hover {
  color: #fff;
  background-color: #46aed6;
}

.hero.is-link .tabs a:hover, .hero.is-link .tabs li.is-active a {
  opacity: 1;
}

.hero.is-link .tabs.is-boxed li.is-active a, .hero.is-link .tabs.is-boxed li.is-active a:hover, .hero.is-link .tabs.is-toggle li.is-active a, .hero.is-link .tabs.is-toggle li.is-active a:hover {
  color: #5bb7db;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-link.is-bold {
  background-image: linear-gradient(141deg, #25c9de 0%, #5bb7db 71%, #6caee4 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-link.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #25c9de 0%, #5bb7db 71%, #6caee4 100%);
  }
}

.hero.is-info {
  color: #fff;
  background-color: #209cee;
}

.hero.is-info .title, .hero.is-link .tabs.is-boxed a, .hero.is-link .tabs.is-toggle a {
  color: #fff;
}

.hero.is-info .subtitle {
  color: #ffffffe6;
}

.hero.is-info .subtitle a:not(.button), .hero.is-info .subtitle strong {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .hero.is-info .navbar-menu {
    background-color: #209cee;
  }
}

.hero.is-info .navbar-item, .hero.is-info .navbar-link {
  color: #ffffffb3;
}

.hero.is-info .navbar-link.is-active, .hero.is-info .navbar-link:hover, .hero.is-info a.navbar-item.is-active, .hero.is-info a.navbar-item:hover {
  color: #fff;
  background-color: #118fe4;
}

.hero.is-info .tabs a:hover, .hero.is-info .tabs li.is-active a {
  opacity: 1;
}

.hero.is-info .tabs.is-boxed li.is-active a, .hero.is-info .tabs.is-boxed li.is-active a:hover, .hero.is-info .tabs.is-toggle li.is-active a, .hero.is-info .tabs.is-toggle li.is-active a:hover {
  color: #209cee;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-info.is-bold {
  background-image: linear-gradient(141deg, #04a6d7 0%, #209cee 71%, #3287f5 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-info.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #04a6d7 0%, #209cee 71%, #3287f5 100%);
  }
}

.hero.is-success {
  color: #fff;
  background-color: #28b62c;
}

.hero.is-info .tabs.is-boxed a, .hero.is-info .tabs.is-toggle a, .hero.is-success .title {
  color: #fff;
}

.hero.is-success .subtitle {
  color: #ffffffe6;
}

.hero.is-success .subtitle a:not(.button), .hero.is-success .subtitle strong {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .hero.is-success .navbar-menu {
    background-color: #28b62c;
  }
}

.hero.is-success .navbar-item, .hero.is-success .navbar-link {
  color: #ffffffb3;
}

.hero.is-success .navbar-link.is-active, .hero.is-success .navbar-link:hover, .hero.is-success a.navbar-item.is-active, .hero.is-success a.navbar-item:hover {
  color: #fff;
  background-color: #23a127;
}

.hero.is-success .tabs a:hover, .hero.is-success .tabs li.is-active a {
  opacity: 1;
}

.hero.is-success .tabs.is-boxed a, .hero.is-success .tabs.is-toggle a {
  color: #fff;
}

.hero.is-success .tabs.is-boxed li.is-active a, .hero.is-success .tabs.is-boxed li.is-active a:hover, .hero.is-success .tabs.is-toggle li.is-active a, .hero.is-success .tabs.is-toggle li.is-active a:hover {
  color: #28b62c;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-success.is-bold {
  background-image: linear-gradient(141deg, #289516 0%, #28b62c 71%, #26d148 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-success.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #289516 0%, #28b62c 71%, #26d148 100%);
  }
}

.hero.is-warning {
  background-color: #ffdd57;
}

.hero.is-warning, .hero.is-warning .title {
  color: #000000b3;
}

.hero.is-warning .subtitle {
  color: #000000e6;
}

.hero.is-warning .subtitle a:not(.button), .hero.is-warning .subtitle strong {
  color: #000000b3;
}

@media screen and (max-width: 1023px) {
  .hero.is-warning .navbar-menu {
    background-color: #ffdd57;
  }
}

.hero.is-warning .navbar-item, .hero.is-warning .navbar-link {
  color: #000000b3;
}

.hero.is-warning .navbar-link.is-active, .hero.is-warning .navbar-link:hover, .hero.is-warning a.navbar-item.is-active, .hero.is-warning a.navbar-item:hover {
  color: #000000b3;
  background-color: #ffd83d;
}

.hero.is-warning .tabs a {
  color: #000000b3;
  opacity: .9;
}

.hero.is-warning .tabs a:hover, .hero.is-warning .tabs li.is-active a {
  opacity: 1;
}

.hero.is-warning .tabs.is-boxed a, .hero.is-warning .tabs.is-toggle a {
  color: #000000b3;
}

.hero.is-warning .tabs.is-boxed li.is-active a, .hero.is-warning .tabs.is-boxed li.is-active a:hover, .hero.is-warning .tabs.is-toggle li.is-active a, .hero.is-warning .tabs.is-toggle li.is-active a:hover {
  color: #ffdd57;
  background-color: #000000b3;
  border-color: #000000b3;
}

.hero.is-warning.is-bold {
  background-image: linear-gradient(141deg, #ffaf24 0%, #ffdd57 71%, #fffa70 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-warning.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #ffaf24 0%, #ffdd57 71%, #fffa70 100%);
  }
}

.hero.is-danger {
  color: #fff;
  background-color: #ff4136;
}

.hero.is-danger .title {
  color: #fff;
}

.hero.is-danger .subtitle {
  color: #ffffffe6;
}

.hero.is-danger .subtitle a:not(.button), .hero.is-danger .subtitle strong {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .hero.is-danger .navbar-menu {
    background-color: #ff4136;
  }
}

.hero.is-danger .navbar-item, .hero.is-danger .navbar-link {
  color: #ffffffb3;
}

.hero.is-danger .navbar-link.is-active, .hero.is-danger .navbar-link:hover, .hero.is-danger a.navbar-item.is-active, .hero.is-danger a.navbar-item:hover {
  color: #fff;
  background-color: #ff291d;
}

.hero.is-danger .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-danger .tabs a:hover, .hero.is-danger .tabs li.is-active a {
  opacity: 1;
}

.hero.is-danger .tabs.is-boxed a, .hero.is-danger .tabs.is-toggle a {
  color: #fff;
}

.hero.is-danger .tabs.is-boxed li.is-active a, .hero.is-danger .tabs.is-boxed li.is-active a:hover, .hero.is-danger .tabs.is-toggle li.is-active a, .hero.is-danger .tabs.is-toggle li.is-active a:hover {
  color: #ff4136;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-danger.is-bold {
  background-image: linear-gradient(141deg, #ff031f 0%, #ff4136 71%, #ff7650 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-danger.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #ff031f 0%, #ff4136 71%, #ff7650 100%);
  }
}

.hero.is-small .hero-body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

@media screen and (min-width: 769px), print {
  .hero.is-medium .hero-body {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .hero.is-large .hero-body {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }
}

.hero.is-fullheight .hero-body, .hero.is-fullheight-with-navbar .hero-body, .hero.is-halfheight .hero-body {
  align-items: center;
  display: flex;
}

.hero.is-fullheight .hero-body > .container, .hero.is-fullheight-with-navbar .hero-body > .container, .hero.is-halfheight .hero-body > .container {
  flex-grow: 1;
  flex-shrink: 1;
}

.hero.is-halfheight {
  min-height: 50vh;
}

.hero.is-fullheight {
  min-height: 100vh;
}

.hero-video {
  overflow: hidden;
}

.hero-video video {
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.hero-video.is-transparent {
  opacity: .3;
}

@media screen and (max-width: 768px) {
  .hero-video {
    display: none;
  }
}

.hero-buttons {
  margin-top: 1.5rem;
}

@media screen and (max-width: 768px) {
  .hero-buttons .button {
    display: flex;
  }

  .hero-buttons .button:not(:last-child) {
    margin-bottom: .75rem;
  }
}

@media screen and (min-width: 769px), print {
  .hero-buttons {
    justify-content: center;
    display: flex;
  }

  .hero-buttons .button:not(:last-child) {
    margin-right: 1.5rem;
  }
}

.hero-body, .hero-foot, .hero-head {
  flex-grow: 0;
  flex-shrink: 0;
}

.hero-body {
  flex-grow: 1;
}

.hero-body, .section {
  padding: 3rem 1.5rem;
}

@media screen and (min-width: 1024px) {
  .section.is-medium {
    padding: 9rem 1.5rem;
  }

  .section.is-large {
    padding: 18rem 1.5rem;
  }
}

.footer {
  background-color: #fafafa;
  padding: 3rem 1.5rem 6rem;
}

.box, .button {
  border-style: solid;
  border-width: 1px 1px 4px;
}

.box {
  border-color: #dbdbdb;
}

.button {
  height: 2.648em;
  text-transform: uppercase;
  font-size: .85rem;
  font-weight: 700;
  transition: all .3s;
}

.button.is-hovered, .button:hover {
  border-bottom-width: 3px;
}

.button.is-active, .button.is-focused, .button:active, .button:focus {
  box-shadow: none;
}

.button.is-white {
  border-color: #f2f2f2;
}

.button.is-white.is-hovered, .button.is-white:hover {
  border-color: #e6e6e6 !important;
}

.button.is-white.is-active, .button.is-white.is-focused, .button.is-white:active, .button.is-white:focus {
  border-color: #e6e6e6;
  box-shadow: 0 0 0 .125em #ffffff40;
}

.button.is-black {
  border-color: #000;
}

.button.is-black.is-hovered, .button.is-black:hover {
  border-color: #000 !important;
}

.button.is-black.is-active, .button.is-black.is-focused, .button.is-black:active, .button.is-black:focus {
  border-color: #000;
  box-shadow: 0 0 0 .125em #0a0a0a40;
}

.button.is-light {
  border-color: #e8e8e8;
}

.button.is-light.is-hovered, .button.is-light:hover {
  border-color: #dbdbdb !important;
}

.button.is-light.is-active, .button.is-light.is-focused, .button.is-light:active, .button.is-light:focus {
  border-color: #dbdbdb;
  box-shadow: 0 0 0 .125em #f5f5f540;
}

.button.is-dark {
  border-color: #292929;
}

.button.is-dark.is-hovered, .button.is-dark:hover {
  border-color: #1c1c1c !important;
}

.button.is-dark.is-active, .button.is-dark.is-focused, .button.is-dark:active, .button.is-dark:focus {
  border-color: #1c1c1c;
  box-shadow: 0 0 0 .125em #36363640;
}

.button.is-primary {
  border-color: #127ba3;
}

.button.is-primary.is-hovered, .button.is-primary:hover {
  border-color: #106a8c !important;
}

.button.is-primary.is-active, .button.is-primary.is-focused, .button.is-primary:active, .button.is-primary:focus {
  border-color: #106a8c;
  box-shadow: 0 0 0 .125em #158cba40;
}

.button.is-link {
  border-color: #46aed6;
}

.button.is-link.is-hovered, .button.is-link:hover {
  border-color: #31a5d2 !important;
}

.button.is-link.is-active, .button.is-link.is-focused, .button.is-link:active, .button.is-link:focus {
  border-color: #31a5d2;
  box-shadow: 0 0 0 .125em #5bb7db40;
}

.button.is-info {
  border-color: #118fe4;
}

.button.is-info.is-hovered, .button.is-info:hover {
  border-color: #0f81cc !important;
}

.button.is-info.is-active, .button.is-info.is-focused, .button.is-info:active, .button.is-info:focus {
  border-color: #0f81cc;
  box-shadow: 0 0 0 .125em #209cee40;
}

.button.is-success {
  border-color: #23a127;
}

.button.is-success.is-hovered, .button.is-success:hover {
  border-color: #1f8c22 !important;
}

.button.is-success.is-active, .button.is-success.is-focused, .button.is-success:active, .button.is-success:focus {
  border-color: #1f8c22;
  box-shadow: 0 0 0 .125em #28b62c40;
}

.button.is-warning {
  border-color: #ffd83d;
}

.button.is-warning.is-hovered, .button.is-warning:hover {
  border-color: #ffd324 !important;
}

.button.is-warning.is-active, .button.is-warning.is-focused, .button.is-warning:active, .button.is-warning:focus {
  border-color: #ffd324;
  box-shadow: 0 0 0 .125em #ffdd5740;
}

.button.is-danger {
  border-color: #ff291d;
}

.button.is-danger.is-hovered, .button.is-danger:hover {
  border-color: #ff1103 !important;
}

.button.is-danger.is-active, .button.is-danger.is-focused, .button.is-danger:active, .button.is-danger:focus {
  border-color: #ff1103;
  box-shadow: 0 0 0 .125em #ff413640;
}

.input, .textarea {
  box-shadow: inset 0 .125em #0a0a0a13;
}

.input.is-active, .input.is-focused, .input:active, .input:focus, .textarea.is-active, .textarea.is-focused, .textarea:active, .textarea:focus {
  box-shadow: inset 0 .125em #0a0a0a13, 0 0 0 .125em #5bb7db40;
}

.input.is-white.is-active, .input.is-white.is-focused, .input.is-white:active, .input.is-white:focus, .textarea.is-white.is-active, .textarea.is-white.is-focused, .textarea.is-white:active, .textarea.is-white:focus {
  border-color: #e6e6e6;
  box-shadow: inset 0 .125em #0a0a0a13, 0 0 0 .125em #ffffff40;
}

.input.is-black.is-active, .input.is-black.is-focused, .input.is-black:active, .input.is-black:focus, .textarea.is-black.is-active, .textarea.is-black.is-focused, .textarea.is-black:active, .textarea.is-black:focus {
  border-color: #000;
  box-shadow: inset 0 .125em #0a0a0a13, 0 0 0 .125em #0a0a0a40;
}

.input.is-light.is-active, .input.is-light.is-focused, .input.is-light:active, .input.is-light:focus, .textarea.is-light.is-active, .textarea.is-light.is-focused, .textarea.is-light:active, .textarea.is-light:focus {
  border-color: #dbdbdb;
  box-shadow: inset 0 .125em #0a0a0a13, 0 0 0 .125em #f5f5f540;
}

.input.is-dark.is-active, .input.is-dark.is-focused, .input.is-dark:active, .input.is-dark:focus, .textarea.is-dark.is-active, .textarea.is-dark.is-focused, .textarea.is-dark:active, .textarea.is-dark:focus {
  border-color: #1c1c1c;
  box-shadow: inset 0 .125em #0a0a0a13, 0 0 0 .125em #36363640;
}

.input.is-primary.is-active, .input.is-primary.is-focused, .input.is-primary:active, .input.is-primary:focus, .textarea.is-primary.is-active, .textarea.is-primary.is-focused, .textarea.is-primary:active, .textarea.is-primary:focus {
  border-color: #106a8c;
  box-shadow: inset 0 .125em #0a0a0a13, 0 0 0 .125em #158cba40;
}

.input.is-link.is-active, .input.is-link.is-focused, .input.is-link:active, .input.is-link:focus, .textarea.is-link.is-active, .textarea.is-link.is-focused, .textarea.is-link:active, .textarea.is-link:focus {
  border-color: #31a5d2;
  box-shadow: inset 0 .125em #0a0a0a13, 0 0 0 .125em #5bb7db40;
}

.input.is-info.is-active, .input.is-info.is-focused, .input.is-info:active, .input.is-info:focus, .textarea.is-info.is-active, .textarea.is-info.is-focused, .textarea.is-info:active, .textarea.is-info:focus {
  border-color: #0f81cc;
  box-shadow: inset 0 .125em #0a0a0a13, 0 0 0 .125em #209cee40;
}

.input.is-success.is-active, .input.is-success.is-focused, .input.is-success:active, .input.is-success:focus, .textarea.is-success.is-active, .textarea.is-success.is-focused, .textarea.is-success:active, .textarea.is-success:focus {
  border-color: #1f8c22;
  box-shadow: inset 0 .125em #0a0a0a13, 0 0 0 .125em #28b62c40;
}

.input.is-warning.is-active, .input.is-warning.is-focused, .input.is-warning:active, .input.is-warning:focus, .textarea.is-warning.is-active, .textarea.is-warning.is-focused, .textarea.is-warning:active, .textarea.is-warning:focus {
  border-color: #ffd324;
  box-shadow: inset 0 .125em #0a0a0a13, 0 0 0 .125em #ffdd5740;
}

.input.is-danger.is-active, .input.is-danger.is-focused, .input.is-danger:active, .input.is-danger:focus, .textarea.is-danger.is-active, .textarea.is-danger.is-focused, .textarea.is-danger:active, .textarea.is-danger:focus {
  border-color: #ff1103;
  box-shadow: inset 0 .125em #0a0a0a13, 0 0 0 .125em #ff413640;
}

.select:after {
  margin-top: -.575em;
}

.select select:not([multiple]) {
  height: calc(2.25em + 4px);
}

.field.has-addons .control .select select {
  height: 2.25em;
}

.file .file-cta, .file .file-name {
  position: unset;
  border-width: 1px 1px 4px;
}

.file.has-name .file-name {
  border-left-width: 0;
}

.file.is-boxed.has-name .file-name {
  border-width: 1px 1px 4px;
}

.file.is-white .file-cta, .notification.is-white {
  border-color: #f2f2f2;
}

.file.is-white.is-hovered .file-cta, .file.is-white:hover .file-cta {
  border-color: #e6e6e6;
}

.file.is-black .file-cta, .file.is-black.is-hovered .file-cta, .file.is-black:hover .file-cta, .notification.is-black {
  border-color: #000;
}

.file.is-light .file-cta, .notification.is-light {
  border-color: #e8e8e8;
}

.file.is-light.is-hovered .file-cta, .file.is-light:hover .file-cta, .notification {
  border-color: #dbdbdb;
}

.file.is-dark .file-cta, .notification.is-dark {
  border-color: #292929;
}

.file.is-dark.is-hovered .file-cta, .file.is-dark:hover .file-cta {
  border-color: #1c1c1c;
}

.file.is-primary .file-cta, .notification.is-primary {
  border-color: #127ba3;
}

.file.is-primary.is-hovered .file-cta, .file.is-primary:hover .file-cta {
  border-color: #106a8c;
}

.file.is-link .file-cta, .notification.is-link {
  border-color: #46aed6;
}

.file.is-link.is-hovered .file-cta, .file.is-link:hover .file-cta {
  border-color: #31a5d2;
}

.file.is-info .file-cta, .notification.is-info {
  border-color: #118fe4;
}

.file.is-info.is-hovered .file-cta, .file.is-info:hover .file-cta {
  border-color: #0f81cc;
}

.file.is-success .file-cta, .notification.is-success {
  border-color: #23a127;
}

.file.is-success.is-hovered .file-cta, .file.is-success:hover .file-cta {
  border-color: #1f8c22;
}

.file.is-warning .file-cta, .notification.is-warning {
  border-color: #ffd83d;
}

.file.is-warning.is-hovered .file-cta, .file.is-warning:hover .file-cta {
  border-color: #ffd324;
}

.file.is-danger .file-cta, .notification.is-danger {
  border-color: #ff291d;
}

.file.is-danger.is-hovered .file-cta, .file.is-danger:hover .file-cta {
  border-color: #ff1103;
}

.notification {
  border-style: solid;
  border-width: 1px 1px 4px;
}

.progress {
  border-radius: 6px;
}

.card {
  box-shadow: none;
  background-color: #dbdbdb13;
  border-color: #dbdbdb;
  border-radius: 4px;
}

.card .card-header {
  box-shadow: none;
  border-bottom: 1px solid #dbdbdb;
  border-radius: 4px 4px 0 0;
}

.card, .message .message-body {
  border-style: solid;
  border-width: 1px 1px 4px;
}

.hero .navbar {
  border: 0;
  box-shadow: 0 4px #dbdbdb;
}

.hero.is-white .navbar {
  box-shadow: 0 4px #f2f2f2;
}

.hero.is-black .navbar {
  box-shadow: 0 4px #000;
}

.hero.is-light .navbar {
  box-shadow: 0 4px #e8e8e8;
}

.hero.is-dark .navbar {
  box-shadow: 0 4px #292929;
}

.hero.is-primary .navbar {
  box-shadow: 0 4px #127ba3;
}

.hero.is-link .navbar {
  box-shadow: 0 4px #46aed6;
}

.hero.is-info .navbar {
  box-shadow: 0 4px #118fe4;
}

.hero.is-success .navbar {
  box-shadow: 0 4px #23a127;
}

.hero.is-warning .navbar {
  box-shadow: 0 4px #ffd83d;
}

.hero.is-danger .navbar {
  box-shadow: 0 4px #ff291d;
}

@media screen and (max-width: 1023px) {
  .hero .navbar-menu {
    box-shadow: none;
  }
}

.navbar {
  border: solid #dbdbdb;
}

.navbar.is-white {
  border-color: #f2f2f2;
}

.navbar.is-black {
  border-color: #000;
}

.navbar.is-light {
  border-color: #e8e8e8;
}

.navbar.is-dark {
  border-color: #292929;
}

.navbar.is-primary {
  border-color: #127ba3;
}

.navbar.is-link {
  border-color: #46aed6;
}

.navbar.is-info {
  border-color: #118fe4;
}

.navbar.is-success {
  border-color: #23a127;
}

.navbar.is-warning {
  border-color: #ffd83d;
}

.navbar.is-danger {
  border-color: #ff291d;
}

.navbar .navbar-dropdown {
  top: 101%;
  box-shadow: 0 0 0 1px #dbdbdb, 0 4px 0 1px #dbdbdb;
}

.navbar, .pagination-link, .pagination-next, .pagination-previous {
  border-width: 1px 1px 4px;
}

.tabs.is-boxed li.is-active a {
  border-top-width: 4px;
}

.tabs.tabs.is-toggle li.is-active a {
  border-color: #31a5d2;
  box-shadow: inset 0 -4px #31a5d2;
}

.multi-value-formatter-content span {
  border: 1px solid #d3d3d3;
  border-radius: 2px;
  margin-right: 4px;
  padding: 2px 4px;
}

.react-tags {
  border: none !important;
  padding: 0 0 0 6px !important;
}

.react-tags__selected-tag:after {
  display: none !important;
}

.react-tags__selected-tag {
  padding: 2px 4px !important;
}

.react-tags__search input {
  width: 100% !important;
}

.react-tags {
  cursor: text;
  border: 1px solid #d1d1d1;
  border-radius: 1px;
  padding: 6px 0 0 6px;
  font-size: 1em;
  line-height: 1.2;
  position: relative;
}

.react-tags.is-focused {
  border-color: #b1b1b1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  box-sizing: border-box;
  font-size: inherit;
  line-height: inherit;
  background: #f1f1f1;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  display: inline-block;
}

.react-tags__selected-tag:after {
  content: "✕";
  color: #aaa;
  margin-left: 8px;
}

.react-tags__selected-tag:hover, .react-tags__selected-tag:focus {
  border-color: #b1b1b1;
}

.react-tags__search {
  max-width: 100%;
  margin-bottom: 6px;
  padding: 7px 2px;
  display: inline-block;
}

@media screen and (min-width: 30em) {
  .react-tags__search {
    position: relative;
  }
}

.react-tags__search input {
  max-width: 100%;
  font-size: inherit;
  line-height: inherit;
  border: 0;
  outline: none;
  margin: 0;
  padding: 0;
}

.react-tags__search input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
}

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 240px;
  }
}

.react-tags__suggestions ul {
  background: #fff;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  box-shadow: 0 2px 6px #0003;
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  background: none;
  font-weight: 600;
  text-decoration: underline;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: .5;
  cursor: auto;
}

.tabulator {
  text-align: left;
  background-color: #fff;
  font-size: 1rem;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
}

.tabulator[tabulator-layout="fitDataFill"] .tabulator-tableHolder .tabulator-table {
  min-width: 100%;
}

.tabulator[tabulator-layout="fitDataTable"] {
  display: inline-block;
}

.tabulator.tabulator-block-select {
  -webkit-user-select: none;
  user-select: none;
}

.tabulator .tabulator-header {
  box-sizing: border-box;
  width: 100%;
  color: #363636;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  background-color: #0000;
  border: 0 solid #dbdbdb;
  border-bottom-width: 2px;
  font-weight: 700;
  position: relative;
  overflow: hidden;
}

.tabulator .tabulator-header.tabulator-header-hidden {
  display: none;
}

.tabulator .tabulator-header .tabulator-col {
  box-sizing: border-box;
  text-align: left;
  vertical-align: bottom;
  background: none;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.tabulator .tabulator-header .tabulator-col.tabulator-moving {
  pointer-events: none;
  background: none;
  position: absolute;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content {
  box-sizing: border-box;
  padding: .5em .75em;
  position: relative;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-header-menu-button {
  padding: 0 8px;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-header-menu-button:hover {
  cursor: pointer;
  opacity: .6;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title-holder {
  position: relative;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title {
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: bottom;
  overflow: hidden;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title .tabulator-title-editor {
  box-sizing: border-box;
  width: 100%;
  background: #fff;
  border: 1px solid #999;
  padding: 1px;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title .tabulator-header-menu-button + .tabulator-title-editor {
  width: calc(100% - 22px);
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-sorter {
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
  width: 0;
  height: 0;
  border-bottom: 6px solid #bbb;
  border-left: 6px solid #0000;
  border-right: 6px solid #0000;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-group .tabulator-col-group-cols {
  border-top: 1px solid #aaa;
  margin-right: -1px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.tabulator .tabulator-header .tabulator-col:first-child .tabulator-col-resize-handle.prev {
  display: none;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter {
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  margin-top: 2px;
  position: relative;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter textarea {
  height: auto !important;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter svg {
  margin-top: 3px;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter input {
  border: 1px solid #dbdbdb;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter input::-ms-clear {
  width: 0;
  height: 0;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable .tabulator-col-title {
  padding-right: 25px;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable:hover {
  cursor: pointer;
  background-color: #0000;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="none"] .tabulator-col-content .tabulator-col-sorter {
  color: #bbb;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="none"] .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
  border-top: none;
  border-bottom: 6px solid #bbb;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="asc"] .tabulator-col-content .tabulator-col-sorter {
  color: #363636;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="asc"] .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
  border-top: none;
  border-bottom: 6px solid #363636;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="desc"] .tabulator-col-content .tabulator-col-sorter {
  color: #363636;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="desc"] .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
  color: #363636;
  border-top: 6px solid #363636;
  border-bottom: none;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-vertical .tabulator-col-content .tabulator-col-title {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  justify-content: center;
  align-items: center;
  display: flex;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-vertical.tabulator-col-vertical-flip .tabulator-col-title {
  transform: rotate(180deg);
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-vertical.tabulator-sortable .tabulator-col-title {
  padding-top: 20px;
  padding-right: 0;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-vertical.tabulator-sortable.tabulator-col-vertical-flip .tabulator-col-title {
  padding-bottom: 20px;
  padding-right: 0;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-vertical.tabulator-sortable .tabulator-col-sorter {
  justify-content: center;
  inset: 4px 0 auto;
}

.tabulator .tabulator-header .tabulator-frozen {
  z-index: 10;
  display: inline-block;
  position: absolute;
}

.tabulator .tabulator-header .tabulator-frozen.tabulator-frozen-left {
  border-right: 2px solid #aaa;
}

.tabulator .tabulator-header .tabulator-frozen.tabulator-frozen-right {
  border-left: 2px solid #aaa;
}

.tabulator .tabulator-header .tabulator-calcs-holder {
  box-sizing: border-box;
  min-width: 600%;
  border: 0 solid #dbdbdb;
  border-top-width: 2px;
  overflow: hidden;
  background: #0d0d0d00 !important;
}

.tabulator .tabulator-header .tabulator-calcs-holder .tabulator-row {
  background: #0d0d0d00 !important;
}

.tabulator .tabulator-header .tabulator-calcs-holder .tabulator-row .tabulator-col-resize-handle {
  display: none;
}

.tabulator .tabulator-header .tabulator-calcs-holder .tabulator-row .tabulator-cell {
  border-bottom-width: 0;
}

.tabulator .tabulator-header .tabulator-frozen-rows-holder {
  min-width: 600%;
}

.tabulator .tabulator-header .tabulator-frozen-rows-holder:empty {
  display: none;
}

.tabulator .tabulator-tableHolder {
  width: 100%;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  position: relative;
  overflow: auto;
}

.tabulator .tabulator-tableHolder:focus {
  outline: none;
}

.tabulator .tabulator-tableHolder .tabulator-placeholder {
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  display: flex;
}

.tabulator .tabulator-tableHolder .tabulator-placeholder[tabulator-render-mode="virtual"] {
  min-height: 100%;
  min-width: 100%;
}

.tabulator .tabulator-tableHolder .tabulator-placeholder span {
  color: #ccc;
  margin: 0 auto;
  padding: 10px;
  font-size: 20px;
  font-weight: 700;
  display: inline-block;
}

.tabulator .tabulator-tableHolder .tabulator-table {
  white-space: nowrap;
  color: #363636;
  background-color: #0000;
  display: inline-block;
  position: relative;
  overflow: visible;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.tabulator-calcs {
  font-weight: 700;
  background: #ededed !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.tabulator-calcs.tabulator-calcs-top {
  border: 0 solid #dbdbdb;
  border-bottom-width: 2px;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.tabulator-calcs.tabulator-calcs-bottom {
  border: 0 solid #dbdbdb;
  border-top-width: 2px;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.tabulator-calcs .tabulator-cell {
  border-bottom-width: 0;
}

.tabulator .tabulator-footer {
  text-align: right;
  color: #363636;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  background-color: #0000;
  border: 0 solid #dbdbdb;
  border-top-width: 2px;
  padding: .5em .75em;
  font-weight: 700;
}

.tabulator .tabulator-footer .tabulator-calcs-holder {
  box-sizing: border-box;
  width: calc(100% + 20px);
  text-align: left;
  border: 0 solid #dbdbdb;
  border-bottom-width: 2px;
  margin: -5px -10px 10px;
  overflow: hidden;
  background: #0d0d0d00 !important;
}

.tabulator .tabulator-footer .tabulator-calcs-holder .tabulator-row {
  background: #0d0d0d00 !important;
}

.tabulator .tabulator-footer .tabulator-calcs-holder .tabulator-row .tabulator-col-resize-handle {
  display: none;
}

.tabulator .tabulator-footer .tabulator-calcs-holder .tabulator-row .tabulator-cell {
  border-bottom-width: 0;
}

.tabulator .tabulator-footer .tabulator-calcs-holder:only-child {
  border-bottom: none;
  margin-bottom: -5px;
}

.tabulator .tabulator-footer .tabulator-paginator {
  color: #363636;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
}

.tabulator .tabulator-footer .tabulator-page-size {
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  margin: 0 5px;
  padding: 2px 5px;
  display: inline-block;
}

.tabulator .tabulator-footer .tabulator-pages {
  margin: 0 7px;
}

.tabulator .tabulator-footer .tabulator-page {
  background: #fff3;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  margin: 0 .1875em;
  padding: calc(.375em - 1px) .75em;
  font-size: 1rem;
  display: inline-block;
}

.tabulator .tabulator-footer .tabulator-page.active {
  color: #363636;
  border-color: #4a4a4a;
  font-weight: 700;
}

.tabulator .tabulator-footer .tabulator-page:disabled {
  opacity: .5;
}

.tabulator .tabulator-footer .tabulator-page:not(.disabled):hover {
  cursor: pointer;
  border-color: #b5b5b5;
}

.tabulator .tabulator-col-resize-handle {
  width: 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.tabulator .tabulator-col-resize-handle.prev {
  left: 0;
  right: auto;
}

.tabulator .tabulator-col-resize-handle:hover {
  cursor: ew-resize;
}

.tabulator .tabulator-loader {
  z-index: 100;
  height: 100%;
  width: 100%;
  text-align: center;
  background: #0006;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.tabulator .tabulator-loader .tabulator-loader-msg {
  background: #fff;
  border-radius: 10px;
  margin: 0 auto;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
}

.tabulator .tabulator-loader .tabulator-loader-msg.tabulator-loading {
  color: #000;
  border: 4px solid #333;
}

.tabulator .tabulator-loader .tabulator-loader-msg.tabulator-error {
  color: #590000;
  border: 4px solid #d00;
}

.tabulator.is-striped .tabulator-row:nth-child(2n) {
  background-color: #fafafa;
}

.tabulator.is-bordered {
  border: 1px solid #dbdbdb;
}

.tabulator.is-bordered .tabulator-header .tabulator-col, .tabulator.is-bordered .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell {
  border-right: 1px solid #dbdbdb;
}

.tabulator.is-narrow .tabulator-header .tabulator-col .tabulator-col-content, .tabulator.is-narrow .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell {
  padding: .25em .5em;
}

.tabulator-row {
  box-sizing: border-box;
  min-height: 22px;
  background-color: #0000;
  position: relative;
}

.tabulator-row.tabulator-selectable:hover {
  cursor: pointer;
  background-color: #fafafa;
}

.tabulator-row.tabulator-selected {
  background-color: #00d1b2;
}

.tabulator-row.tabulator-selected:hover {
  cursor: pointer;
  background-color: #009e86;
}

.tabulator-row.tabulator-row-moving {
  background: #fff;
  border: 1px solid #000;
}

.tabulator-row.tabulator-moving {
  pointer-events: none;
  z-index: 15;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  position: absolute;
}

.tabulator-row .tabulator-row-resize-handle {
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.tabulator-row .tabulator-row-resize-handle.prev {
  top: 0;
  bottom: auto;
}

.tabulator-row .tabulator-row-resize-handle:hover {
  cursor: ns-resize;
}

.tabulator-row .tabulator-frozen {
  background-color: inherit;
  z-index: 10;
  display: inline-block;
  position: absolute;
}

.tabulator-row .tabulator-frozen.tabulator-frozen-left {
  border-right: 2px solid #aaa;
}

.tabulator-row .tabulator-frozen.tabulator-frozen-right {
  border-left: 2px solid #aaa;
}

.tabulator-row .tabulator-responsive-collapse {
  box-sizing: border-box;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  padding: 5px;
}

.tabulator-row .tabulator-responsive-collapse:empty {
  display: none;
}

.tabulator-row .tabulator-responsive-collapse table {
  font-size: 1rem;
}

.tabulator-row .tabulator-responsive-collapse table tr td {
  position: relative;
}

.tabulator-row .tabulator-responsive-collapse table tr td:first-of-type {
  padding-right: 10px;
}

.tabulator-row .tabulator-cell {
  box-sizing: border-box;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 0 solid #dbdbdb;
  border-bottom-width: 1px;
  padding: .5em .75em;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.tabulator-row .tabulator-cell.tabulator-editing {
  border: 1px solid #1d68cd;
  outline: none;
  padding: 0;
}

.tabulator-row .tabulator-cell.tabulator-editing input, .tabulator-row .tabulator-cell.tabulator-editing select {
  background: none;
  border: 1px;
}

.tabulator-row .tabulator-cell.tabulator-validation-fail {
  border: 1px solid #d00;
}

.tabulator-row .tabulator-cell.tabulator-validation-fail input, .tabulator-row .tabulator-cell.tabulator-validation-fail select {
  color: #d00;
  background: none;
  border: 1px;
}

.tabulator-row .tabulator-cell:first-child .tabulator-col-resize-handle.prev {
  display: none;
}

.tabulator-row .tabulator-cell.tabulator-row-handle {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.tabulator-row .tabulator-cell.tabulator-row-handle .tabulator-row-handle-box {
  width: 80%;
}

.tabulator-row .tabulator-cell.tabulator-row-handle .tabulator-row-handle-box .tabulator-row-handle-bar {
  width: 100%;
  height: 3px;
  background: #666;
  margin-top: 2px;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-branch {
  vertical-align: middle;
  height: 9px;
  width: 7px;
  border-bottom: 2px solid #aaa;
  border-left: 2px solid #aaa;
  border-bottom-left-radius: 1px;
  margin-top: -9px;
  margin-right: 5px;
  display: inline-block;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control {
  vertical-align: middle;
  height: 11px;
  width: 11px;
  background: #0000001a;
  border: 1px solid #363636;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  display: inline-flex;
  overflow: hidden;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control:hover {
  cursor: pointer;
  background: #0003;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control .tabulator-data-tree-control-collapse {
  height: 7px;
  width: 1px;
  background: none;
  display: inline-block;
  position: relative;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control .tabulator-data-tree-control-collapse:after {
  content: "";
  height: 1px;
  width: 7px;
  background: #363636;
  position: absolute;
  top: 3px;
  left: -3px;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control .tabulator-data-tree-control-expand {
  height: 7px;
  width: 1px;
  background: #363636;
  display: inline-block;
  position: relative;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control .tabulator-data-tree-control-expand:after {
  content: "";
  height: 1px;
  width: 7px;
  background: #363636;
  position: absolute;
  top: 3px;
  left: -3px;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  height: 15px;
  width: 15px;
  color: #0000;
  background: #666;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  font-size: 1.1em;
  font-weight: 700;
  display: inline-flex;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle:hover {
  opacity: .7;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle.open .tabulator-responsive-collapse-toggle-close {
  display: initial;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle.open .tabulator-responsive-collapse-toggle-open, .tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle .tabulator-responsive-collapse-toggle-close {
  display: none;
}

.tabulator-row .tabulator-cell .tabulator-traffic-light {
  height: 14px;
  width: 14px;
  border-radius: 14px;
  display: inline-block;
}

.tabulator-row.tabulator-group {
  box-sizing: border-box;
  color: #363636;
  min-width: 100%;
  background: #ccc;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  padding: 5px 5px 5px 10px;
  font-weight: 700;
}

.tabulator-row.tabulator-group:hover {
  cursor: pointer;
  background-color: #0000001a;
}

.tabulator-row.tabulator-group.tabulator-group-visible .tabulator-arrow {
  border: 6px solid #0000;
  border-top-color: #363636;
  border-bottom: 0;
  margin-right: 10px;
}

.tabulator-row.tabulator-group.tabulator-group-level-1 {
  padding-left: 30px;
}

.tabulator-row.tabulator-group.tabulator-group-level-2 {
  padding-left: 50px;
}

.tabulator-row.tabulator-group.tabulator-group-level-3 {
  padding-left: 70px;
}

.tabulator-row.tabulator-group.tabulator-group-level-4 {
  padding-left: 90px;
}

.tabulator-row.tabulator-group.tabulator-group-level-5 {
  padding-left: 110px;
}

.tabulator-row.tabulator-group .tabulator-group-toggle {
  display: inline-block;
}

.tabulator-row.tabulator-group .tabulator-arrow {
  width: 0;
  height: 0;
  vertical-align: middle;
  border: 6px solid #0000;
  border-left-color: #363636;
  border-right: 0;
  margin-right: 16px;
  display: inline-block;
}

.tabulator-row.tabulator-group span {
  color: #d00;
  margin-left: 10px;
}

.tabulator-menu {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  z-index: 10000;
  background: none;
  border: 1px solid #aaa;
  font-size: 1rem;
  display: inline-block;
  position: absolute;
  overflow-y: auto;
  box-shadow: 0 0 5px #0003;
}

.tabulator-menu .tabulator-menu-item {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  padding: 5px 10px;
  position: relative;
}

.tabulator-menu .tabulator-menu-item.tabulator-menu-item-disabled {
  opacity: .5;
}

.tabulator-menu .tabulator-menu-item:not(.tabulator-menu-item-disabled):hover {
  cursor: pointer;
  background: #fafafa;
}

.tabulator-menu .tabulator-menu-item.tabulator-menu-item-submenu {
  padding-right: 25px;
}

.tabulator-menu .tabulator-menu-item.tabulator-menu-item-submenu:after {
  height: 7px;
  width: 7px;
  content: "";
  vertical-align: top;
  border: 1px solid #aaa;
  border-width: 1px 1px 0 0;
  display: inline-block;
  position: absolute;
  top: calc(5px + .4em);
  right: 10px;
  transform: rotate(45deg);
}

.tabulator-menu .tabulator-menu-separator {
  border-top: 1px solid #aaa;
}

.tabulator-edit-select-list {
  box-sizing: border-box;
  max-height: 200px;
  -webkit-overflow-scrolling: touch;
  z-index: 10000;
  background: none;
  border: 1px solid #aaa;
  font-size: 1rem;
  display: inline-block;
  position: absolute;
  overflow-y: auto;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item {
  color: #363636;
  padding: 4px;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item.active {
  color: #0000;
  background: #1d68cd;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item.active.focused {
  outline: 1px solid #00000080;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item.focused {
  outline: 1px solid #1d68cd;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item:hover {
  cursor: pointer;
  color: #0000;
  background: #1d68cd;
}

.tabulator-edit-select-list .tabulator-edit-select-list-notice {
  color: #363636;
  text-align: center;
  padding: 4px;
}

.tabulator-edit-select-list .tabulator-edit-select-list-group {
  color: #363636;
  border-bottom: 1px solid #aaa;
  padding: 6px 4px 4px;
  font-weight: 700;
}

.tabulator.tabulator-ltr {
  direction: ltr;
}

.tabulator.tabulator-rtl {
  text-align: initial;
  direction: rtl;
}

.tabulator.tabulator-rtl .tabulator-header .tabulator-col {
  text-align: initial;
  border-left: 1px solid #aaa;
  border-right: initial;
}

.tabulator.tabulator-rtl .tabulator-header .tabulator-col.tabulator-col-group .tabulator-col-group-cols {
  margin-left: -1px;
  margin-right: 0;
}

.tabulator.tabulator-rtl .tabulator-header .tabulator-col.tabulator-sortable .tabulator-col-title {
  padding-left: 25px;
  padding-right: 0;
}

.tabulator.tabulator-rtl .tabulator-header .tabulator-col .tabulator-col-content .tabulator-arrow {
  left: 8px;
  right: auto;
}

.tabulator.tabulator-rtl .tabulator-row .tabulator-cell {
  border-right: initial;
  border-left: 1px solid #aaa;
}

.tabulator.tabulator-rtl .tabulator-row .tabulator-cell .tabulator-data-tree-branch {
  border-left: initial;
  border-right: 2px solid #aaa;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 1px;
  margin-left: 5px;
  margin-right: 0;
}

.tabulator.tabulator-rtl .tabulator-row .tabulator-cell .tabulator-data-tree-control {
  margin-left: 5px;
  margin-right: 0;
}

.tabulator.tabulator-rtl .tabulator-col-resize-handle {
  position: absolute;
  left: 0;
  right: auto;
}

.tabulator.tabulator-rtl .tabulator-col-resize-handle.prev {
  left: auto;
  right: 0;
}

.tabulator-print-fullscreen {
  z-index: 10000;
  position: absolute;
  inset: 0;
}

body.tabulator-print-fullscreen-hide > :not(.tabulator-print-fullscreen) {
  display: none !important;
}

.tabulator-print-table {
  border-collapse: collapse;
}

.tabulator-print-table .tabulator-print-table-group {
  box-sizing: border-box;
  color: #363636;
  min-width: 100%;
  background: #ccc;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  padding: 5px 5px 5px 10px;
  font-weight: 700;
}

.tabulator-print-table .tabulator-print-table-group:hover {
  cursor: pointer;
  background-color: #0000001a;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-visible .tabulator-arrow {
  border: 6px solid #0000;
  border-top-color: #363636;
  border-bottom: 0;
  margin-right: 10px;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-level-1 td {
  padding-left: 30px !important;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-level-2 td {
  padding-left: 50px !important;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-level-3 td {
  padding-left: 70px !important;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-level-4 td {
  padding-left: 90px !important;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-level-5 td {
  padding-left: 110px !important;
}

.tabulator-print-table .tabulator-print-table-group .tabulator-group-toggle {
  display: inline-block;
}

.tabulator-print-table .tabulator-print-table-group .tabulator-arrow {
  width: 0;
  height: 0;
  vertical-align: middle;
  border: 6px solid #0000;
  border-left-color: #363636;
  border-right: 0;
  margin-right: 16px;
  display: inline-block;
}

.tabulator-print-table .tabulator-print-table-group span {
  color: #d00;
  margin-left: 10px;
}

.tabulator-print-table .tabulator-data-tree-branch {
  vertical-align: middle;
  height: 9px;
  width: 7px;
  border-bottom: 2px solid #aaa;
  border-left: 2px solid #aaa;
  border-bottom-left-radius: 1px;
  margin-top: -9px;
  margin-right: 5px;
  display: inline-block;
}

.tabulator-print-table .tabulator-data-tree-control {
  vertical-align: middle;
  height: 11px;
  width: 11px;
  background: #0000001a;
  border: 1px solid #363636;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  display: inline-flex;
  overflow: hidden;
}

.tabulator-print-table .tabulator-data-tree-control:hover {
  cursor: pointer;
  background: #0003;
}

.tabulator-print-table .tabulator-data-tree-control .tabulator-data-tree-control-collapse {
  height: 7px;
  width: 1px;
  background: none;
  display: inline-block;
  position: relative;
}

.tabulator-print-table .tabulator-data-tree-control .tabulator-data-tree-control-collapse:after {
  content: "";
  height: 1px;
  width: 7px;
  background: #363636;
  position: absolute;
  top: 3px;
  left: -3px;
}

.tabulator-print-table .tabulator-data-tree-control .tabulator-data-tree-control-expand {
  height: 7px;
  width: 1px;
  background: #363636;
  display: inline-block;
  position: relative;
}

.tabulator-print-table .tabulator-data-tree-control .tabulator-data-tree-control-expand:after {
  content: "";
  height: 1px;
  width: 7px;
  background: #363636;
  position: absolute;
  top: 3px;
  left: -3px;
}

.Toastify__toast-container {
  z-index: 9999;
  width: 320px;
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
  -webkit-transform: translate3d(0, 0, 9999px);
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

.Toastify__toast {
  min-height: 64px;
  box-sizing: border-box;
  max-height: 800px;
  cursor: pointer;
  direction: ltr;
  border-radius: 1px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  font-family: sans-serif;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast--dark {
  color: #fff;
  background: #121212;
}

.Toastify__toast--default {
  color: #aaa;
  background: #fff;
}

.Toastify__toast--info {
  background: #3498db;
}

.Toastify__toast--success {
  background: #07bc0c;
}

.Toastify__toast--warning {
  background: #f1c40f;
}

.Toastify__toast--error {
  background: #e74c3c;
}

.Toastify__toast-body {
  flex: auto;
  margin: auto 0;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    margin-bottom: 0;
  }
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--default {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: .7;
  transform-origin: 0;
  background-color: #ffffffb3;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear forwards;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
}

.Toastify__progress-bar--default {
  background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
}

.Toastify__progress-bar--dark {
  background: #bb86fc;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box {
  color: #fff;
  background-color: #333;
  border-radius: 4px;
  outline: 0;
  font-size: 14px;
  line-height: 1.4;
  transition-property: transform, visibility, opacity;
  position: relative;
}

.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}

.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: top;
  bottom: -7px;
  left: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: bottom;
  top: -7px;
  left: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  transform-origin: 0;
  right: -7px;
}

.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: 100%;
  left: -7px;
}

.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11);
}

.tippy-arrow {
  width: 16px;
  height: 16px;
  color: #333;
}

.tippy-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.tippy-content {
  z-index: 1;
  padding: 5px 9px;
  position: relative;
}

#loading {
  width: 100%;
  height: 100%;
  color: #fff;
  opacity: 1;
  background: #e9e9e9;
  transition: opacity .3s;
  position: absolute;
}

#loading.fade {
  opacity: 0;
}

#loading-label {
  text-align: center;
  color: #464646;
}

#loading-center {
  width: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#loading.fade {
  opacity: 0;
}

.aside {
  padding: 1.5em;
}

.content-loader {
  width: 25px;
  height: 25px;
  vertical-align: middle;
  border: 5px solid #f3f3f3;
  border-top-color: #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  display: inline-block;
}

.content-loader-alt {
  width: 15px;
  height: 15px;
  vertical-align: middle;
  border: 2px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  display: inline-block;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.control.has-icons-left .icon, .control.has-icons-right .icon {
  z-index: 0;
}

.tabulator-edit-select-list {
  background-color: #fff;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item:hover {
  cursor: pointer;
  color: #000;
  background: #efefef;
}

.no-user-select {
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.tabulator .tabulator-footer {
  -webkit-user-select: text !important;
  user-select: text !important;
  -khtml-user-select: text !important;
  -o-user-select: text !important;
}

.pointer {
  cursor: pointer;
}

.red {
  color: red;
}

.flatpickr-calendar {
  opacity: 0;
  text-align: center;
  visibility: hidden;
  direction: ltr;
  width: 307.875px;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: none;
  border: 0;
  border-radius: 5px;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  animation: none;
  display: none;
  position: absolute;
  box-shadow: 0 3px 13px #00000014;
}

.flatpickr-calendar.open, .flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  z-index: 99999;
  display: inline-block;
}

.flatpickr-calendar.animate.open {
  animation: fpFadeInDown .3s cubic-bezier(.23, 1, .32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  box-shadow: -2px 0 #e6e6e6, 5px 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer, .flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #48484833;
}

.flatpickr-calendar.hasTime .flatpickr-innerContainer {
  border-bottom: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  border: 1px solid #48484833;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before, .flatpickr-calendar:after {
  pointer-events: none;
  content: "";
  height: 0;
  width: 0;
  border: solid #0000;
  display: block;
  position: absolute;
  left: 22px;
}

.flatpickr-calendar.rightMost:before, .flatpickr-calendar.arrowRight:before, .flatpickr-calendar.rightMost:after, .flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar.arrowCenter:before, .flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #48484833;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #42a5f5;
}

.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #48484833;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #42a5f5;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  display: inline-block;
  position: relative;
}

.flatpickr-months {
  display: flex;
}

.flatpickr-months .flatpickr-month {
  color: #fff;
  fill: #fff;
  height: 34px;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  background: #42a5f5;
  border-radius: 5px 5px 0 0;
  flex: 1;
  line-height: 1;
  position: relative;
  overflow: hidden;
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  height: 34px;
  z-index: 3;
  color: #fff;
  fill: #fff;
  padding: 10px;
  text-decoration: none;
  position: absolute;
  top: 0;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled, .flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i, .flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month, .flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  left: 0;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month, .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  right: 0;
}

.flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover {
  color: #bbb;
}

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path, .flatpickr-months .flatpickr-next-month svg path {
  fill: inherit;
  transition: fill .1s;
}

.numInputWrapper {
  height: auto;
  position: relative;
}

.numInputWrapper input, .numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button, .numInputWrapper input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numInputWrapper span {
  width: 14px;
  height: 50%;
  opacity: 0;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #48484826;
  padding: 0 4px 0 2px;
  line-height: 50%;
  position: absolute;
  right: 0;
}

.numInputWrapper span:hover {
  background: #0000001a;
}

.numInputWrapper span:active {
  background: #0003;
}

.numInputWrapper span:after {
  content: "";
  display: block;
  position: absolute;
}

.numInputWrapper span.arrowUp {
  border-bottom: 0;
  top: 0;
}

.numInputWrapper span.arrowUp:after {
  border-bottom: 4px solid #48484899;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-top: 4px solid #48484899;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: #ffffff80;
}

.numInputWrapper:hover {
  background: #0000000d;
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  color: inherit;
  width: 75%;
  height: 34px;
  text-align: center;
  padding: 7.48px 0 0;
  font-weight: 300;
  line-height: 1;
  display: inline-block;
  position: absolute;
  left: 12.5%;
  transform: translate3d(0, 0, 0);
}

.flatpickr-current-month span.cur-month {
  color: inherit;
  margin-left: .5ch;
  padding: 0;
  font-family: inherit;
  font-weight: 700;
  display: inline-block;
}

.flatpickr-current-month span.cur-month:hover {
  background: #0000000d;
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch�;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #fff;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #fff;
}

.flatpickr-current-month input.cur-year {
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  vertical-align: initial;
  -webkit-appearance: textfield;
  appearance: textfield;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0 0 0 .5ch;
  display: inline-block;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
  color: #ffffff80;
  pointer-events: none;
  background: none;
  font-size: 100%;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  -webkit-appearance: menulist;
  appearance: menulist;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  height: auto;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  vertical-align: initial;
  width: auto;
  background: #42a5f5;
  border: none;
  border-radius: 0;
  outline: none;
  margin: -1px 0 0;
  padding: 0 0 0 .5ch;
  position: relative;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus, .flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: #0000000d;
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: #42a5f5;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  text-align: center;
  width: 100%;
  height: 28px;
  background: #42a5f5;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  flex: 1;
  display: flex;
}

span.flatpickr-weekday {
  cursor: default;
  color: #0000008a;
  text-align: center;
  background: #42a5f5;
  flex: 1;
  margin: 0;
  font-size: 90%;
  font-weight: bolder;
  line-height: 1;
  display: block;
}

.dayContainer, .flatpickr-weeks {
  padding: 1px 0 0;
}

.flatpickr-days {
  width: 307.875px;
  border-left: 1px solid #48484833;
  border-right: 1px solid #48484833;
  align-items: flex-start;
  display: flex;
  position: relative;
  overflow: hidden;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  box-sizing: border-box;
  opacity: 1;
  outline: 0;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0;
  display: flex;
  transform: translate3d(0, 0, 0);
}

.dayContainer + .dayContainer {
  box-shadow: -1px 0 #48484833;
}

.flatpickr-day {
  box-sizing: border-box;
  color: #484848;
  cursor: pointer;
  width: 14.2857%;
  max-width: 39px;
  height: 39px;
  text-align: center;
  background: none;
  border: 1px solid #0000;
  border-radius: 150px;
  flex-basis: 14.2857%;
  justify-content: center;
  margin: 0;
  font-weight: 400;
  line-height: 39px;
  display: inline-block;
  position: relative;
}

.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  background: #e2e2e2;
  border-color: #e2e2e2;
  outline: 0;
}

.flatpickr-day.today {
  border-color: #bbb;
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  color: #fff;
  background: #bbb;
  border-color: #bbb;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  background: #42a5f5;
  border-color: #42a5f5;
}

.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 #42a5f5;
}

.flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange, .flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 #e2e2e2, 5px 0 #e2e2e2;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: #4848484d;
  cursor: default;
  background: none;
  border-color: #0000;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: #4848481a;
}

.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 #42a5f5, 5px 0 #42a5f5;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  border-left: 1px solid #48484833;
  padding: 0 12px;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover {
  width: 100%;
  max-width: none;
  color: #4848484d;
  cursor: default;
  background: none;
  border: none;
  display: block;
}

.flatpickr-innerContainer {
  box-sizing: border-box;
  background: #fff;
  border-bottom: 1px solid #48484833;
  display: flex;
  overflow: hidden;
}

.flatpickr-rContainer {
  box-sizing: border-box;
  padding: 0;
  display: inline-block;
}

.flatpickr-time {
  text-align: center;
  height: 0;
  max-height: 40px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 0 0 5px 5px;
  outline: 0;
  line-height: 40px;
  display: flex;
  overflow: hidden;
}

.flatpickr-time:after {
  content: "";
  clear: both;
  display: table;
}

.flatpickr-time .numInputWrapper {
  width: 40%;
  height: 40px;
  float: left;
  flex: 1;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #484848;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #484848;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  -webkit-box-shadow: none;
  box-shadow: none;
  text-align: center;
  height: inherit;
  line-height: inherit;
  color: #484848;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  appearance: textfield;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  font-size: 14px;
  position: relative;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  border: 0;
  outline: 0;
}

.flatpickr-time .flatpickr-time-separator, .flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #484848;
  width: 2%;
  -webkit-user-select: none;
  user-select: none;
  align-self: center;
  font-weight: bold;
}

.flatpickr-time .flatpickr-am-pm {
  width: 18%;
  cursor: pointer;
  text-align: center;
  outline: 0;
  font-weight: 400;
}

.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
  background: #eaeaea;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

/*# sourceMappingURL=index.564980ec.css.map */
