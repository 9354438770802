$logged-out-bg: #e9e9e9;


#loading{
    background: $logged-out-bg;
    width: 100%;
    height: 100%;
    position: absolute;
    color: #fff;
    opacity: 1;
    transition: opacity 0.3s;
}

#loading.fade {
  opacity: 0;
}

#loading-label{
  text-align: center;
  color: #464646;
}

#loading-center {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 200px;
}

#loading.fade {
  opacity: 0;
}

.aside {
    padding: 1.5em;
}

// START - Spinning loader for elements.
.content-loader{
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 25px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

.content-loader-alt{
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #3498db;
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
// END.

// BULMA OVERRIDES
// Icon inside input boxes was showing on top of react-select windows.
.control.has-icons-left .icon, .control.has-icons-right .icon {
  z-index: 0;
}

// The table filters were missing background altogether.
.tabulator-edit-select-list{
  background-color: #fff;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item:hover {
  cursor: pointer;
  color: black ;
  background: #efefef;
}

.no-user-select{
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select:none;
  user-select:none;
  -o-user-select:none;
}

// Make tabulator footer text selectable.
.tabulator .tabulator-footer {
    -ms-user-select: text !important;
    user-select: text !important;
    -moz-user-select: text !important;
    -khtml-user-select: text !important;
    -webkit-user-select: text !important;
    -o-user-select: text !important;
}

.pointer{
  cursor:pointer;
}

.red{
  color: red;
}